const regexp = /^[a-zA-Z0-9.]{4,32}$/;

export const validateUsername = (username: string) => {
    return regexp.test(username);
};

// export const checkUsernameInUse = (username, usedUsernameList) => {
//     let usernameInUse = false;
//     for(let usedUsername of usedUsernameList){
//         //console.log("usedUsername: " + usedUsername);
//         if(usedUsername === username){
//             usernameInUse = true;
//             break;
//         }
//     }
//     //console.log("usernameInUse: " + usernameInUse);
//     return usernameInUse;
// };

export const usernameRules = "Username can contain only letters and digits, and must be from 4 to 32 characters";

export const usernameExist = "Username is already in use";