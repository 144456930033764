import React from "react";

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Container,
    FormControlLabel,
    InputAdornment,
    Alert,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {LockOutlined, PersonOutline} from "@mui/icons-material";
import {login} from "../api/AuthenticationApi";
import {PasswordTextField} from "../components/PasswordTextField";
import {StatusTextField} from "../components/StatusTextField";
import {useAsyncError} from "../hooks/useAsyncError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
    },
    card: {
        flexGrow: 1,
    },
    formRow: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    inputAdornment: {
        color: theme.palette.grey["900"],
    },
    header: {
        textAlign: "center",
        fontWeight: 900,
        fontSize: theme.typography.h5.fontSize,
    },
}));

export const Login = ({authenticate}) => {
    const classes = useStyles();

    const cast = useAsyncError();

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [remember, setRemember] = React.useState(true);
    const handleUsernameChange = React.useCallback(e => setUsername(e.target.value), [setUsername]);
    const handlePasswordChange = React.useCallback(e => setPassword(e.target.value), [setPassword]);
    const handleRememberMeChange = React.useCallback(e => setRemember(e.target.checked), [setRemember]);

    const [pending, setPending] = React.useState(false);
    const [wrongCredentials, setWrongCredentials] = React.useState(false);

    const handleFormSubmit = e => {
        e.preventDefault();

        setPending(true);
        setWrongCredentials(false);

        login(username, password, remember)
            .then(response => {
                authenticate(response.data)
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setWrongCredentials(true);
                    setPending(false);
                } else {
                    throw error;
                }
            })
            .catch(e => cast(e));
    };

    return (
        <div className={classes.root}>
        <Container
            className={classes.root}
            fixed
            maxWidth="xs">
            <Card
                className={classes.card}
                elevation={24}>
                <CardHeader title={
                    <Typography
                        variant="h2"
                        className={classes.header}>
                        Login
                    </Typography>
                }/>
                <CardContent>
                    <form autoComplete="on" onSubmit={handleFormSubmit}>
                        <div className={classes.formRow}>
                            <StatusTextField
                                status={pending ? "pending" : wrongCredentials ? "error" : "default"}
                                label="Username"
                                type="text"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            className={classes.inputAdornment}
                                            position="start">
                                            <PersonOutline fontSize="small"/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={username}
                                onChange={handleUsernameChange}
                                autoComplete="username"
                                fullWidth/>
                        </div>
                        <div className={classes.formRow}>
                            <PasswordTextField
                                status={pending ? "pending" : wrongCredentials ? "error" : "default"}
                                label="Password"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            className={classes.inputAdornment}
                                            position="start">
                                            <LockOutlined fontSize="small"/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={password}
                                onChange={handlePasswordChange}
                                autoComplete="current-password"
                                fullWidth/>
                        </div>
                        <div className={classes.formRow}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={pending}
                                        color="primary"
                                        checked={remember}
                                        onChange={handleRememberMeChange}/>
                                }
                                label="Remember Me"/>
                        </div>
                        {
                            wrongCredentials &&
                            <Alert
                                severity="error">
                                Incorrect username or password.
                            </Alert>
                        }
                        <div className={classes.formRow}>
                            <Button
                                disabled={pending}
                                fullWidth
                                type="submit"
                                size="large"
                                variant="contained"
                                color="primary">
                                Login
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </Container>
        </div>
    );
};