import React from 'react'
import {makeStyles} from "@mui/styles";
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {getDevShort} from "../api/EtdApi";
import {
    changePassUser,
    ChangePassUserInfo,
    ClientInfo, delCient, DelClientInfo, delUser, DelUserInfo, editClient, EditClientInfo, editUser, EditUserInfo,
    getClients,
    getUsers, RegClientInfo, registerClient,
    registerUser,
    RegUserInfo,
    Response,
    UserInfo
} from "../api/AdminApi";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem, Stack,
    TextField
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import MenuIcon from "@mui/icons-material/Menu";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import {usernameRules} from "../utils/validateUsername";
import {password2Rules, passwordRules, validatePassword} from "../utils/validatePassword";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Button from "@mui/material/Button";


export type AdminDashboardProps = {
    titleUpdateHandler: (title: string) => void;
};

const Item = styled(Paper)(({ theme } ) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AddButton = styled(IconButton)(({theme}) =>({
    boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    textTransform: 'none',
    fontSize: 16,
    border: 'none',
    //backgroundColor: '#0063cc',
    position: 'fixed',
    bottom: 30,
    right: 30,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    '&:hover': {
        backgroundColor: '#0049a9',
        boxShadow: '0px 2px 15px rgba(0,0,0,0.6)',
    },
    '&:active': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
    '&:focus': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
}));

const EditType = {
    none: 0,
    add: 1,
    edit: 2,
    remove: 3,
}

export const AdminClients = ( {titleUpdateHandler}: AdminDashboardProps ) : JSX.Element => {

    const classes = useStyles();

    const theme = useTheme();

    const [refresh, setRefresh] = React.useState (0);

    const [clients, setClients] = React.useState <ClientInfo[] | undefined>(undefined);

    const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
    const [menuClient, setMenuClient] = React.useState<ClientInfo | undefined>(undefined);

    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openResultDialog, setOpenResultDialog] = React.useState(false);
    const [openDelConfirmDialog, setOpenDelConfirmDialog] = React.useState(false);

    const [reqResponse, setReqResponse] = React.useState <Response | undefined> (undefined);

    const [editType, setEditType] = React.useState(0);

    const [clientName, setClientName] = React.useState("");
    const [clientID, setClientID] = React.useState (0);

    const handleClientNameChange = React.useCallback((e: any) => setClientName(e.target.value), [setClientName]);

    const validClientName = (clientName !== "");

    const open = Boolean(anchorEl);

    React.useEffect(() => {
        titleUpdateHandler("Manage Clients");
    }, [titleUpdateHandler]);

    React.useEffect(() => {
        getClients()
            .then((clients) => {
                setClients(clients);
            });
    }, [refresh]);

    const handleMenuOpen = (event:  React.MouseEvent<SVGSVGElement>, client: ClientInfo) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setMenuClient(client);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditClient = () => {
        handleMenuClose();
        //console.log(menuClient);
        if(menuClient) {
            setClientID(menuClient.id);
            setClientName(menuClient.client_name);
        }
        setEditType(EditType.edit);
        setOpenEditDialog(true);
    }

    const handleDeleteClient = () => {
        handleMenuClose();
        if(menuClient) {
            setEditType(EditType.remove);
            setOpenDelConfirmDialog(true);
        }
    }

    const handleAddClient = () => {
        setClientName("");
        setEditType(EditType.add);
        setOpenEditDialog(true);
    }

    const handleEditDialogClose = () => {
        setOpenEditDialog(false);
    }

    const handleResultDialogClose = () => {
        setOpenResultDialog(false);
        setRefresh(refresh + 1);
    }

    const handleEditDialogSubmit = () => {

        setOpenEditDialog(false);

        if(editType === EditType.add){
            let regClientInfo: RegClientInfo = {
                client_name: clientName,
            }

            registerClient(regClientInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                });
        }else if(editType === EditType.edit){
            let editClientInfo: EditClientInfo = {
                id: clientID,
                client_name: clientName,
            }
            editClient(editClientInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }
    }

    const handleDelConfirmDialogClose = () => {
        setOpenDelConfirmDialog(false);
    }

    const handleDelConfirmDialogSubmit = () => {
        setOpenDelConfirmDialog(false);

        if(menuClient) {
            let delClientInfo: DelClientInfo = {
                id: menuClient.id,
            }

            delCient(delClientInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }
    }

    return(
        <Box sx={{ flexGrow: 1 }} >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">ID</StyledTableCell >
                            <StyledTableCell align="left">Client Name</StyledTableCell >
                            <StyledTableCell align="right">Actions</StyledTableCell >
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {clients && clients.map((client) => {
                            if (client) {

                                return (
                                    <>
                                        <StyledTableRow key={client.id} className={classes.tableRow}>
                                            <StyledTableCell align="left" component="th" scope="row">
                                                {client.id}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{client.client_name}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <MenuIcon style={{ color: theme.palette.primary.main, cursor: 'pointer'}} onClick={(event)=>{handleMenuOpen(event, client)}}/>
                                            </StyledTableCell>

                                        </StyledTableRow>

                                    </>
                                )
                            }else{
                                return null;
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleEditClient}>Edit Client</MenuItem>
                <MenuItem onClick={handleDeleteClient}>Delete Client</MenuItem>
            </Menu>
            <AddButton
                size="large"
                onClick={handleAddClient}
            >
                <SpeedDialIcon />
            </AddButton>
            <Dialog
                open={openEditDialog}
                onClose={handleEditDialogClose}
            >
                <DialogTitle>{editType === EditType.add ? "Add Client" : "Edit Client: " + clientName}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ width: 500 }}>

                        <TextField
                            error={!validClientName}
                            autoFocus
                            margin="dense"
                            id="username"
                            label={validClientName ? "Client Name" : "Error"}
                            helperText={validClientName ? undefined : "Client name can not be empty"}
                            type="text"
                            variant="outlined"
                            value={clientName}
                            onChange={handleClientNameChange}
                        />


                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose}>Cancel</Button>
                    <Button
                        onClick={handleEditDialogSubmit}
                        disabled={!validClientName}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openResultDialog}
                onClose={handleResultDialogClose}
            >
                <DialogTitle>{reqResponse?.success ? "Success" : "Error" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {(reqResponse && !reqResponse.success) ? reqResponse.error :
                            editType === EditType.add ? "New Client has been added successfully" :
                                editType === EditType.remove ? "Client successfully deleted" :
                                    editType === EditType.edit ? "Client successfully updated" :
                                        ""}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResultDialogClose}>OK</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelConfirmDialog}
                onClose={handleDelConfirmDialogClose}
            >
                <DialogTitle>{"Confirm Client Deletion" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {"Please confirm deletion of client '" + menuClient?.client_name + "'"}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelConfirmDialogClose}>Cancel</Button>
                    <Button onClick={handleDelConfirmDialogSubmit}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    tableBody: {
        height: '200px',
        overflowX: 'auto',
    },
    tableRow: {
        //cursor: 'pointer',
    },
    dialog_content: {
        display: "flex",
        flexDirection: "column",
        width: "500px",
    },

}));