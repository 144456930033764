import React from 'react'
import {makeStyles} from "@mui/styles";
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, FormGroup,
    IconButton, InputAdornment,
    Menu,
    MenuItem,
    Stack,
    TextField
} from "@mui/material";
import {
    delDevice, DelDeviceInfo,
    DeviceInfo, editDevice, EditDeviceInfo,
    getDevices,
    getSitesShort, RegDeviceInfo, registerDevice,
    Response,
    SiteInfoShort
} from "../api/AdminApi";


import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import MenuIcon from "@mui/icons-material/Menu";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Button from "@mui/material/Button";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {DeviceServSettings, getDevServSettings, setDevServSettings} from "../api/EtdApi";


export type AdminDashboardProps = {
    titleUpdateHandler: (title: string) => void;
};

const Item = styled(Paper)(({ theme } ) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AddButton = styled(IconButton)(({theme}) =>({
    boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    textTransform: 'none',
    fontSize: 16,
    border: 'none',
    //backgroundColor: '#0063cc',
    position: 'fixed',
    bottom: 30,
    right: 30,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    '&:hover': {
        backgroundColor: '#0049a9',
        boxShadow: '0px 2px 15px rgba(0,0,0,0.6)',
    },
    '&:active': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
    '&:focus': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
}));

const EditType = {
    none: 0,
    add: 1,
    edit: 2,
    remove: 3,
}

export const AdminDevices = ( {titleUpdateHandler}: AdminDashboardProps ) : JSX.Element => {

    const classes = useStyles();

    const theme = useTheme();

    const [refresh, setRefresh] = React.useState (0);

    const [devices, setDevices] = React.useState <DeviceInfo[] | undefined>(undefined);

    const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
    const [menuDevice, setMenuDevice] = React.useState<DeviceInfo | undefined>(undefined);

    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openResultDialog, setOpenResultDialog] = React.useState(false);
    const [openDelConfirmDialog, setOpenDelConfirmDialog] = React.useState(false);
    const [openSettingsDialog, setOpenSettingsDialog] = React.useState(false);

    const [reqResponse, setReqResponse] = React.useState <Response | undefined> (undefined);

    const [editType, setEditType] = React.useState(0);

    const [showPassword, setShowPassword] = React.useState(false);

    const [deviceID, setDeviceID] = React.useState (0);
    const [deviceSerialNumber, setDeviceSerialNumber] = React.useState("");
    const [deviceSiteID, setDeviceSiteID] = React.useState (0);
    const [deviceDescription, setDeviceDescription] = React.useState ("");
    const [deviceToken, setDeviceToken] = React.useState ("");
    const [devicePassword, setDevicePassword] = React.useState ("");

    const [deviceServSettings, setDeviceServSettings] = React.useState <DeviceServSettings | undefined> (undefined);

    const handleDeviceSerialNumberChange = React.useCallback((e: any) => setDeviceSerialNumber(e.target.value), [setDeviceSerialNumber]);
    const handleDeviceSiteIDChange = React.useCallback((e: any) => setDeviceSiteID(e.target.value), [setDeviceSiteID]);
    const handleDeviceDescriptionChange = React.useCallback((e: any) => setDeviceDescription(e.target.value), [setDeviceDescription]);
    const handleDeviceTokenChange = React.useCallback((e: any) => setDeviceToken(e.target.value), [setDeviceToken]);
    const handleDevicePasswordChange = React.useCallback((e: any) => setDevicePassword(e.target.value), [setDevicePassword]);

    // const handleEnableShowGraph = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => {
    //     console.log('handleEnableShowGraph(): deviceServSettings: ' + JSON.stringify(deviceServSettings));
    //     setDeviceServSettings((oldDevSettings) => {
    //         console.log('oldDevSettings: ' + JSON.stringify(oldDevSettings));
    //         let newDevSettings = oldDevSettings;
    //         if(newDevSettings !== undefined) {
    //             newDevSettings.show_graph = event.target.checked ? 1 : 0;
    //             console.log('newDevSettings: ' + JSON.stringify(newDevSettings));
    //         }
    //         return newDevSettings;
    //     });
    // },[deviceServSettings]) ;

    const [enableShowGraph, setEnableShowGraph] = React.useState(false);
    const handleEnableShowGraph = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnableShowGraph(event.target.checked);
        setDeviceServSettings((oldDevSettings) => {
            //console.log('oldDevSettings: ' + JSON.stringify(oldDevSettings));
            let newDevSettings = oldDevSettings;
            if(newDevSettings !== undefined) {
                newDevSettings.show_graph = event.target.checked ? 1 : 0;
                //console.log('newDevSettings: ' + JSON.stringify(newDevSettings));
            }
            return newDevSettings;
        });
    },[setEnableShowGraph]) ;

    const validDeviceSerialNumber = (deviceSerialNumber !== "");
    const validDeviceSiteID = (deviceSiteID !== 0);
    const validDeviceDescription = (deviceDescription !== "" || true);
    const validDeviceToken = (deviceToken !== "" || true);
    const validDevicePassword = (devicePassword !== "" || true);

    const open = Boolean(anchorEl);

    const [sites, setSites] = React.useState <SiteInfoShort[] >([]);

    React.useEffect(() => {
        titleUpdateHandler("Manage Devices");
    }, [titleUpdateHandler]);

    React.useEffect(() => {
        getSitesShort()
            .then((sites) => {
                if(sites) {
                    setSites(sites);
                }
                //console.log(JSON.stringify(sites));
            });
    }, [refresh]);

    React.useEffect(() => {
        getDevices()
            .then((devices) => {
                setDevices(devices);
                //console.log(JSON.stringify(sites));
            });
    }, [refresh]);

    const handleMenuOpen = (event:  React.MouseEvent<SVGSVGElement>, device: DeviceInfo) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setMenuDevice(device);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditDevice = () => {
        handleMenuClose();
        //console.log(menuDevice);
        if(menuDevice) {
            setDeviceID(menuDevice.id);
            setDeviceSerialNumber(menuDevice.serialNumber);
            setDeviceSiteID(menuDevice.site_id);
            setDeviceDescription(menuDevice.serialNumber);
            setDeviceToken(menuDevice.id_token);
            setDevicePassword(menuDevice.password);
        }
        setEditType(EditType.edit);
        setOpenEditDialog(true);
    }

    const handleDeleteDevice = () => {
        handleMenuClose();
        if(menuDevice) {
            setEditType(EditType.remove);
            setOpenDelConfirmDialog(true);
        }
    }

    const handleDeviceSettings = () => {
        handleMenuClose();
        if(menuDevice) {
            setDeviceSerialNumber(menuDevice.serialNumber);
            setDeviceID(menuDevice.id);
            setDeviceServSettings(undefined);
            setOpenSettingsDialog(true);
            getDevServSettings(menuDevice.id)
                .then((deviceSettings) => {
                    //console.log(JSON.stringify(deviceSettings));
                    setDeviceServSettings(deviceSettings);
                    setEnableShowGraph(deviceSettings?.show_graph !== 0);
                })
                .catch((err) => {
                    console.log(err);
                })
        }

    }

    const handleAddDevice = () => {
        setDeviceSerialNumber("");
        setDeviceSiteID(0);
        setDeviceDescription("");
        setDeviceToken("");
        setDevicePassword("");

        setEditType(EditType.add);
        setOpenEditDialog(true);
    }

    const handleEditDialogClose = () => {
        setOpenEditDialog(false);
    }

    const handleSettingsDialogClose = () => {
        setOpenSettingsDialog(false);
    }

    const handleSettingsDialogSubmit  = () => {
        if(deviceServSettings !== undefined) {
            setDevServSettings(deviceServSettings)
                .then((response) => {
                    setOpenSettingsDialog(false);
                    //setReqResponse(response);
                    //setOpenResultDialog(true);
                });
        }
    }

    const handleResultDialogClose = () => {
        setOpenResultDialog(false);
        setRefresh(refresh + 1);
    }

    const handleEditDialogSubmit = () => {

        setOpenEditDialog(false);

        if(editType === EditType.add){
            let regDeviceInfo: RegDeviceInfo = {
                serialNumber: deviceSerialNumber,
                site_id: deviceSiteID,
                description: deviceDescription,
                id_token: deviceToken,
                password: devicePassword,
            }

            registerDevice(regDeviceInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                });
        }else if(editType === EditType.edit){
            let editDeviceInfo: EditDeviceInfo = {
                id: deviceID,
                serialNumber: deviceSerialNumber,
                site_id: deviceSiteID,
                description: deviceDescription,
                id_token: deviceToken,
                password: devicePassword,
            }
            editDevice(editDeviceInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }
    }

    const handleDelConfirmDialogClose = () => {
        setOpenDelConfirmDialog(false);
    }

    const handleDelConfirmDialogSubmit = () => {
        setOpenDelConfirmDialog(false);

        if(menuDevice) {
            let delDeviceInfo: DelDeviceInfo = {
                id: menuDevice.id,
            }

            delDevice(delDeviceInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }

    return (
        <Box sx={{ flexGrow: 1 }} >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">ID</StyledTableCell >
                            <StyledTableCell align="left">Serial Number</StyledTableCell >
                            <StyledTableCell align="left">Site</StyledTableCell >
                            <StyledTableCell align="left">Description</StyledTableCell >
                            <StyledTableCell align="right">Actions</StyledTableCell >
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {devices && devices.map((device) => {
                            if (device) {

                                return (
                                    <>
                                        <StyledTableRow key={device.id} className={classes.tableRow}>
                                            <StyledTableCell align="left" component="th" scope="row">
                                                {device.id}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{device.serialNumber}</StyledTableCell>
                                            <StyledTableCell align="left">{device.site_name}</StyledTableCell>
                                            <StyledTableCell align="left">{device.description}</StyledTableCell>

                                            <StyledTableCell align="right">
                                                <MenuIcon style={{ color: theme.palette.primary.main, cursor: 'pointer'}} onClick={(event)=>{handleMenuOpen(event, device)}}/>
                                            </StyledTableCell>

                                        </StyledTableRow>

                                    </>
                                )
                            }else{
                                return null;
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleEditDevice}>Edit Device</MenuItem>
                <MenuItem onClick={handleDeleteDevice}>Delete Device</MenuItem>
                <MenuItem onClick={handleDeviceSettings}>Settings</MenuItem>
            </Menu>
            <AddButton
                size="large"
                onClick={handleAddDevice}
            >
                <SpeedDialIcon />
            </AddButton>
            <Dialog
                open={openEditDialog}
                onClose={handleEditDialogClose}
            >
                <DialogTitle>{editType === EditType.add ? "Add Device" : "Edit Device: " + deviceSerialNumber}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ width: 500 }}>

                        <TextField
                            error={!validDeviceSerialNumber}
                            autoFocus
                            margin="dense"
                            id="serialNumber"
                            label={validDeviceSerialNumber ? "Device Serial Number" : "Error"}
                            helperText={validDeviceSerialNumber ? undefined : "Device Serial Number can not be empty"}
                            type="text"
                            variant="outlined"
                            value={deviceSerialNumber}
                            onChange={handleDeviceSerialNumberChange}
                        />
                        <TextField
                            margin="dense"
                            id="site_name"
                            select
                            label="Site"
                            variant="outlined"
                            value={deviceSiteID}
                            onChange={handleDeviceSiteIDChange}
                        >
                            {sites.map((site) => {
                                return (
                                    <MenuItem key={site.id} value={site.id} selected={site.id === deviceSiteID}>
                                        {site.site_name}
                                    </MenuItem>
                                );
                            })}

                        </TextField>

                        <TextField
                            error={!validDeviceDescription}
                            margin="dense"
                            id="description"
                            label={validDeviceDescription ? "Device Description" : "Error"}
                            helperText={validDeviceDescription ? undefined : "Device Description can not be empty"}
                            type="text"
                            variant="outlined"
                            value={deviceDescription}
                            onChange={handleDeviceDescriptionChange}
                        />

                        <TextField
                            error={!validDeviceToken}
                            margin="dense"
                            id="dev_token"
                            label={validDeviceToken ? "Device ID Token" : "Error"}
                            helperText={validDeviceToken ? undefined : "Device ID Token can not be empty"}
                            type="text"
                            variant="outlined"
                            value={deviceToken}
                            onChange={handleDeviceTokenChange}
                        />

                        <TextField
                            error={!validDevicePassword}
                            margin="dense"
                            id="dev_password"
                            label={validDevicePassword ? "Device Password" : "Error"}
                            helperText={validDevicePassword ? undefined : "Device Password can not be empty"}
                            type={showPassword ? 'text' : 'password'}
                            variant="outlined"
                            value={devicePassword}
                            autoComplete="off"
                            onChange={handleDevicePasswordChange}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                        />



                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose}>Cancel</Button>
                    <Button
                        onClick={handleEditDialogSubmit}
                        disabled={!validDeviceSerialNumber || !validDeviceSiteID || !validDeviceDescription
                        || !validDeviceToken || !validDevicePassword}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openSettingsDialog}
                onClose={handleSettingsDialogClose}
            >
                <DialogTitle>{"Settings of the Device: " + deviceSerialNumber}</DialogTitle>
                <DialogContent>
                    {deviceServSettings === undefined ?
                        <Box sx={{display: 'flex', justifyContent: "center", marginTop: "10px"}}>
                            <CircularProgress/>
                        </Box>
                        :
                        <Stack spacing={2} sx={{width: 500}}>
                            <FormGroup sx={{
                                width: 350,
                                border: '1px solid',
                                borderColor: theme.palette.primary.main,
                                padding: '20px',
                                margin: '10px',
                                borderRadius: 2
                            }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enableShowGraph}
                                            onChange={handleEnableShowGraph}
                                            inputProps={{'aria-label': 'controlled'}}
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                        />}
                                    label="Enable Show Graph"
                                />
                            </FormGroup>
                        </Stack>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSettingsDialogClose}>Cancel</Button>
                    <Button onClick={handleSettingsDialogSubmit} disabled={deviceServSettings === undefined }>
                        Submit
                    </Button>

                </DialogActions>
            </Dialog>
            <Dialog
                open={openResultDialog}
                onClose={handleResultDialogClose}
            >
                <DialogTitle>{reqResponse?.success ? "Success" : "Error" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {(reqResponse && !reqResponse.success) ? reqResponse.error :
                            editType === EditType.add ? "New Device has been added successfully" :
                                editType === EditType.remove ? "Device successfully deleted" :
                                    editType === EditType.edit ? "Device successfully updated" :
                                        ""}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResultDialogClose}>OK</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelConfirmDialog}
                onClose={handleDelConfirmDialogClose}
            >
                <DialogTitle>{"Confirm Device Deletion" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {"Please confirm deletion of device '" + menuDevice?.serialNumber + "'"}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelConfirmDialogClose}>Cancel</Button>
                    <Button onClick={handleDelConfirmDialogSubmit}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    tableBody: {
        height: '200px',
        overflowX: 'auto',
    },
    tableRow: {
        //cursor: 'pointer',
    },
    dialog_content: {
        display: "flex",
        flexDirection: "column",
        width: "500px",
    },

}));