import React, {useContext, useEffect} from 'react'
import {makeStyles} from "@mui/styles";
import { useMatch} from "react-router-dom";
import {styled, useTheme, createTheme, ThemeProvider, keyframes} from "@mui/material/styles";
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup, LinearProgress,
} from "@mui/material";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import {DateTimeRange, DateTimeRangePicker} from "../components/DateTimeRangePicker";
import {ETDContext} from "../context/ETDContext";
import {delDevUser, DeviceType, getEtdType, getScanHist, getSWHist, swUpdate, swUpdateStatus} from "../api/MQTTApi";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {getSWList, SWListItem} from "../api/SWUpdateApi";
import Typography from "@mui/material/Typography";


export type DeviceSWUpdateProps = {
    titleUpdateHandler: (title: string) => void;
};


type SWHistoryRec = {
    Date: string;
    SW_BuildDate: string;
    SW_Version: string;
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 10,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
    [`&.${tableCellClasses.body}`]: {
        //fontSize: 14,
        fontSize: 9,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const DeviceSWUpdate = ({titleUpdateHandler} : DeviceSWUpdateProps) : JSX.Element => {

    const classes = useStyles();

    let theme = useTheme();

    theme = createTheme(theme,{
        typography: {
            h5: {
                fontSize: 18,
                color: theme.palette.primary.main,
                marginBottom: 10,
            },
            h6: {
                fontSize: 16,
                color: '#222',
            },
            body1: {
                fontSize: 16,
            },
            body2: {
                fontSize: 16,
                marginBottom: 15,
            },

        },
    });

    const pageMatch = useMatch("/device/:etd_id");
    const etd_id_str = (pageMatch && pageMatch.params.etd_id) || "";

    const etd_id = Number(etd_id_str);

    const start = dayjs().startOf("day");
    const end = dayjs().endOf("day");
    const [range, setRange] = React.useState <DateTimeRange> ({from: start, to: end});

    const [swHistory, setSWHistory] = React.useState <SWHistoryRec[] | undefined> (undefined);

    const [swList, setSWList] = React.useState <SWListItem[] | undefined> (undefined);

    const [openInstallConfirmDialog, setOpenInstallConfirmDialog] = React.useState(false);

    const [openCompleteDialog, setOpenCompleteDialog] = React.useState(false);

    const [uploadingSuccess, setUploadingSuccess] = React.useState(false);

    const [selectedInstallName, setSelectedInstallName] = React.useState<string | undefined>(undefined);

    const [waitingForStart, setWaitingForStart] = React.useState(false);

    const [waitingForComplete, setWaitingForComplete] = React.useState(false);

    const [waitingForGetHist, setWaitingForGetHist] = React.useState(false);
    const [waitingForGetSWList, setWaitingForGetSWList] = React.useState(false);

    const {etdName} = useContext(ETDContext);

    React.useEffect(() => {
        titleUpdateHandler(etdName);
    },[etdName, titleUpdateHandler]);


    React.useEffect(() => {
        if (etd_id !== undefined) {
            setWaitingForGetSWList(true);
            let devType: DeviceType | undefined = undefined;
            getEtdType(etd_id)
                .then((deviceType) => {
                    //console.log(deviceType);
                    devType = deviceType;
                    let filter = 'all';
                    if(devType.CPUArchitecture === 'iMX6'){
                        filter = 'imx6';
                    }else if(devType.CPUArchitecture === 'iMX8'){
                        filter = 'imx8';
                    }
                    if(filter !== 'all') {
                        return getSWList(etd_id, filter);
                    }else{
                        setWaitingForGetSWList(false);
                    }
                })
                .then((SWList) => {
                    //console.log(SWList);
                    setWaitingForGetSWList(false);
                    if(SWList !== undefined){
                        setSWList(SWList);
                    }
                })
                .catch((err) => {
                    setWaitingForGetSWList(false);
                    console.log(err);
                });

        }
    },[etd_id]);


    const rangeChangeHandler = (newRange: DateTimeRange) => {
        setRange(newRange);
    };

    const onGetSWHist = () => {

        if (etd_id !== undefined) {
            //getScanHist(etd_id, Math.round(range.from.toDate().getTime() / 1000), Math.round(range.to.toDate().getTime() / 1000))

            setWaitingForGetHist(true);
            getSWHist(etd_id, range.from.toDate().getTime(), range.to.toDate().getTime())
                .then((respHist) => {

                    setWaitingForGetHist(false);
                    const resList: SWHistoryRec[] = [];

                    if (respHist !== undefined) {

                        respHist.SWHistory.sort((a, b) => {
                            return b.Date - a.Date;
                        });

                        respHist.SWHistory.forEach((item) => {

                            const date = new Date(item.Date * 1000);
                            //const sw_build_date = new Date(parseInt(item.SW_BuildDate) * 1000);
                            resList.push({
                                Date: date.toLocaleString("en-GB"),
                                //SW_BuildDate: sw_build_date.toLocaleString("en-GB"),
                                SW_BuildDate: item.SW_BuildDate,
                                SW_Version: item.SW_Version,
                            });
                        });

                        setSWHistory(resList);
                    }
                })
                .catch((err) => {
                    console.log('onGetSWHist(): Error: ' + err);
                    setWaitingForGetHist(false);
                })
        }
    }

    const onInstall = (filename: string) => {
        //console.log('onInstall(): filename: ' + filename);
        setSelectedInstallName(filename);
        setOpenInstallConfirmDialog(true);
    }

    const handleInstallConfirmDialogClose = () => {
        setOpenInstallConfirmDialog(false);
    }

    const handleInstallConfirmDialogSubmit = () => {
        setOpenInstallConfirmDialog(false);

        if(selectedInstallName) {
            setWaitingForStart(true);

            swUpdate(etd_id, selectedInstallName)
                .then((responseMqtt) => {
                    //console.log(JSON.stringify(responseMqtt));
                    //setReqResponse(response);
                    //setOpenResultDialog(true);
                    setWaitingForStart(false);
                    setWaitingForComplete(true);

                    swUpdateStatus(etd_id, responseMqtt.publishID)
                        .then((response) => {
                            //console.log(JSON.stringify(response));
                            setWaitingForComplete(false);
                            if(response.code === 200){
                                //console.log("SwUpdateDwComplete");
                                setUploadingSuccess(true);
                            }else{
                                //console.log("SwUpdateDwFailed");
                                setUploadingSuccess(false);
                            }
                            setOpenCompleteDialog(true);
                        })
                        .catch((err) => {
                            console.log(err);
                            setWaitingForComplete(false);
                            setOpenCompleteDialog(true);
                            setUploadingSuccess(false);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    setWaitingForStart(false);
                    setWaitingForComplete(false);
                });
        }
    }

    const handleCompleteDialogClose = () => {
        setOpenCompleteDialog(false);
    }



    return(
        <ThemeProvider theme={theme}>

            <FormGroup sx={{ width: 900, border: '1px solid', borderColor: theme.palette.primary.main, padding: '20px', margin: '10px', borderRadius: 2 }}>

                <div className={classes.controlGroup}>
                    <DateTimeRangePicker
                        defaultRange={range}
                        onChange={rangeChangeHandler}
                        minDate={undefined}
                        maxDate={undefined}
                        disabled={false}/>

                    <div className={classes.getResultsBtn}>
                        <Button variant="outlined" size="large" onClick={onGetSWHist}>Get SW Update History</Button>
                    </div>
                </div>

                {waitingForGetHist ?
                    <>
                        {/*<Typography variant="h5" component="div" className={classes.dev_field_title2}>*/}
                        {/*    Waiting for getting history*/}
                        {/*</Typography>*/}
                        <Box sx={{display: 'flex', justifyContent: "center", marginTop: "10px"}}>
                            <CircularProgress/>
                        </Box>
                    </>
                    :
                    <div className={classes.table}>
                        <TableContainer component={Paper}>
                            {swHistory ?
                                <Table sx={{minWidth: 650}} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Update Date</StyledTableCell>
                                            <StyledTableCell align="left">SW Build Date</StyledTableCell>
                                            <StyledTableCell align="left">SW Version</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {swHistory && swHistory.map((item, index) => {
                                            if (item) {
                                                return (
                                                    <StyledTableRow key={index} className={classes.tableRow}>
                                                        <StyledTableCell align="left" component="th" scope="row">
                                                            {item.Date}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="left">{item.SW_BuildDate}</StyledTableCell>
                                                        <StyledTableCell
                                                            align="left">{item.SW_Version}</StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                                :
                                <></>
                            }
                        </TableContainer>
                    </div>
                }
            </FormGroup>

            <FormGroup sx={{ width: 900, border: '1px solid', borderColor: theme.palette.primary.main, padding: '20px', margin: '10px', borderRadius: 2 }}>
                {(!waitingForStart && !waitingForComplete) ?
                    <>
                        <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                            Available SW packages
                        </Typography>
                        {waitingForGetSWList ?
                            <>
                                {/*<Typography variant="h5" component="div" className={classes.dev_field_title2}>*/}
                                {/*    Getting the List of available SW packages*/}
                                {/*</Typography>*/}
                                <Box sx={{display: 'flex', justifyContent: "center", marginTop: "10px"}}>
                                    <CircularProgress/>
                                </Box>
                            </>
                            :
                            <div className={classes.table}>
                                <TableContainer component={Paper}>
                                    {swList ?
                                        <Table sx={{minWidth: 650}} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="left">SW Filename</StyledTableCell>
                                                    <StyledTableCell align="left">Action</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {swList && swList.map((item, index) => {
                                                    if (item) {
                                                        return (
                                                            <StyledTableRow key={index} className={classes.tableRow}>
                                                                <StyledTableCell
                                                                    align="left">{item.swFile}</StyledTableCell>
                                                                <StyledTableCell align="left" component="th"
                                                                                 scope="row">
                                                                    <div className={classes.getResultsBtn}>
                                                                        <Button variant="outlined" size="small"
                                                                                onClick={() => {
                                                                                    onInstall(item.swFile)
                                                                                }}>Install</Button>
                                                                    </div>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </TableBody>
                                        </Table>
                                        :
                                        <></>
                                    }
                                </TableContainer>
                            </div>
                        }
                    </>
                    :
                    <>
                    {waitingForStart &&
                    <>
                        <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                            Waiting for start uploading
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    </>
                    }
                    {waitingForComplete &&
                    <>
                        <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                            {"Uploading file '" + selectedInstallName + "'"}
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    </>
                    }
                    </>
                }
            </FormGroup>

            <Dialog
                open={openInstallConfirmDialog}
                onClose={handleInstallConfirmDialogClose}
            >
                <DialogTitle>{"Confirm the installation of the new software" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {"Please Confirm the installation of the new software '" + selectedInstallName + "'"}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInstallConfirmDialogClose}>Cancel</Button>
                    <Button onClick={handleInstallConfirmDialogSubmit}>Install</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openCompleteDialog}
                onClose={handleCompleteDialogClose}
            >
                <DialogTitle>{uploadingSuccess ? "Success!" : "Failure!" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {uploadingSuccess ? "The software update file '" + selectedInstallName
                            + "' has been successfully uploaded. The device software will be updated "
                            + "after the user accepts the update request or at the next reboot."
                            : "Failed to download the software update file '" + selectedInstallName + "'"}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCompleteDialogClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 5,
        marginRight: 5,
    },
    updateSettingsBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
    },
    controlGroup: {
        display: "flex",
            justifyContent: "left",
            alignItems: "center",
    },
    getResultsBtn: {
        marginLeft: 20,
    },
    table: {
        marginTop: 20,
    },
    tableBody: {
        height: '200px',
            overflowX: 'auto',
    },
    tableRow: {

    },
    dialog_content: {
        display: "flex",
        flexDirection: "column",
        width: "500px",
    },
    dev_field_title2: {
        marginTop: 40,
        marginLeft: 20,
        display: "flex",
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },

}));

