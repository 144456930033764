import React from "react";

import {host} from "./api";

import axios from "axios";

// export interface clientRec {
//     client: string,
// }
//
// export interface siteRec {
//     site: string,
// }
//
// export interface allRec {
//     all: boolean,
// }

export interface deviceBrief {
    etd_id: string,
    serial_number: string,
    latitude: number,
    longitude: number,
    site_name: string,
    client_name: string,
    online: number,
    Status: number,
    description: string,
    site_id: number,
    client_id: number,
    clear: number,
    explosives: number,
    narcotics: number,
}

export interface deviceTotal {
    sum_clear: number,
    sum_evk: number,
    sum_explosives: number,
    sum_nvk: number,
    sum_narcotics: number,
}

export interface SiteSummary {
    site_id: number,
    site_name: string,
    tot_devs: number,
    tot_online_devs: number,
    tot_explosives: number,
    tot_narcotics: number,
    tot_clear: number,
}

export interface SiteAll {
    client: string,
    client_id: number,
    sites: [
        {
            site: string,
            site_id: number,
        }
    ]

}

export const getAllSites = async (): Promise<SiteAll[]> => {

    try {
        const response = await axios.get(`${host}/devices/sites`);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getAllSites() error: ' + e);
        return [];
    }
};

export const getAllSitesSummary = async () : Promise<SiteSummary[]> => {

    try {
        const response = await axios.get(`${host}/devices/allsitesum`);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getAllSitesSummary() error: ' + e);
        return [];
    }
};

export const getSitesSummaryByClients = async (clients : any) : Promise<SiteSummary[]> => {

    try {
        const response = await axios.post(`${host}/devices/sitesumcl`, clients);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getSitesSummaryByClients() error: ' + e);
        return [];
    }
};

export const getAllDevsBrief = async () : Promise<deviceBrief[]> => {

    try {
        const response = await axios.get(`${host}/devices/briefall`);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getAllSites() error: ' + e);
        return [];
    }
};

export const getDevsBriefByClients = async (clients : any) : Promise<deviceBrief[]> => {

    try {
        const response = await axios.post(`${host}/devices/briefcl`, clients);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getDevsBriefByClients() error: ' + e);
        return [];
    }
};

export const getDevsBriefBySites = async (sites : any) : Promise<deviceBrief[]> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.post(`${host}/devices/briefst`, sites);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getDevsBriefBySites() error: ' + e);
        return [];
    }
};

export const getAllDevsTotal = async () : Promise<deviceTotal[]> => {

    try {
        const response = await axios.get(`${host}/devices/totall`);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getAllDevsTotal() error: ' + e);
        return [];
    }
};

export const getDevsTotalByClients = async (clients : any) : Promise<deviceTotal[]> => {

    try {
        const response = await axios.post(`${host}/devices/totcl`, clients);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getDevsTotalByClients() error: ' + e);
        return [];
    }
};

export const getDevsTotalBySites = async (sites : any) : Promise<deviceTotal[]> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.post(`${host}/devices/totst`, sites);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getDevsTotalBySites() error: ' + e);
        return [];
    }
};

export const useAllSites = (): SiteAll[] => {
    const [sites, setSites] = React.useState <SiteAll[]> ([]);

    React.useEffect(() => {

        let shouldUpdate = true;
        getAllSites()
            .then((respSites) => {
                if (shouldUpdate) {
                    setSites(respSites);
                }
            })

        return () => {
            shouldUpdate = false;
        };
    }, []);

    return sites ? sites : [];
};

export const useAllSitesLive = (interval: number): SiteAll[] => {
    const [sites, setSites] = React.useState <SiteAll[]> ([]);

    React.useEffect(() => {

        let shouldUpdate = true;

        function fetchSites() {
            getAllSites()
                .then((respSites) => {
                    if (shouldUpdate) {
                        setSites(respSites);
                        //console.log(respSites);
                        setTimeout(fetchSites, interval);
                    }
                })
                .catch(() => {
                    setTimeout(fetchSites, interval);
                });
        }
        fetchSites();

        return () => {
            shouldUpdate = false;
        };
    }, [interval]);

    return sites ? sites : [];
};

export const useDevsBrief = (clients : any) => {
    const [devices, setDevices] = React.useState<deviceBrief[]>([]);

    React.useEffect(() => {

        let shouldUpdate = true;
        if(clients && clients.length > 0){
            //console.log(clients[0].site_id);
            if(clients[0].all){
                getAllDevsBrief()
                    .then((respDevices) => {
                        if (shouldUpdate) {
                            setDevices(respDevices);
                        }
                    })
            }else if(clients[0].client_id){
                getDevsBriefByClients(clients)
                    .then((respDevices) => {
                        if (shouldUpdate) {
                            setDevices(respDevices);
                        }
                    })
            }else if(clients[0].site_id){
                getDevsBriefBySites(clients)
                    .then((respDevices) => {
                        if (shouldUpdate) {
                            setDevices(respDevices);
                        }
                    })
            }else{
                if (shouldUpdate) {
                    setDevices([]);
                }
            }
        }else{
            if (shouldUpdate) {
                setDevices([]);
            }
        }

        return () => {
            shouldUpdate = false;
        };
    }, [clients]);

    return devices ? devices : null;
};

export const useDevsBriefLive = (clients : any, interval: number) => {
    const [devices, setDevices] = React.useState<deviceBrief[]>([]);

    React.useEffect(() => {

        let shouldUpdate = true;
        function fetchDevsBrief() {
            if (clients && clients.length > 0) {
                //console.log(clients[0].site_id);
                if (clients[0].all) {
                    getAllDevsBrief()
                        .then((respDevices) => {
                            if (shouldUpdate) {
                                setDevices(respDevices);
                                setTimeout(fetchDevsBrief, interval);
                            }
                        })
                    // if (shouldUpdate) {
                    //     setDevices([]);
                    // }
                } else if (clients[0].client_id) {
                    getDevsBriefByClients(clients)
                        .then((respDevices) => {
                            if (shouldUpdate) {
                                setDevices(respDevices);
                                setTimeout(fetchDevsBrief, interval);
                            }
                        })
                    // if (shouldUpdate) {
                    //     setDevices([]);
                    // }
                } else if (clients[0].site_id) {
                    getDevsBriefBySites(clients)
                        .then((respDevices) => {
                            if (shouldUpdate) {
                                setDevices(respDevices);
                                setTimeout(fetchDevsBrief, interval);
                            }
                        })
                } else {
                    if (shouldUpdate) {
                        setDevices([]);
                    }
                }
            } else {
                if (shouldUpdate) {
                    setDevices([]);
                }
            }
        }
        fetchDevsBrief();
        return () => {
            shouldUpdate = false;
        };
    }, [clients, interval]);

    return devices ? devices : null;
};

export const useDevsTotalLive = (clients : any, interval: number) => {
    const [devices, setDevices] = React.useState<deviceTotal[]>([]);

    React.useEffect(() => {

        let shouldUpdate = true;
        function fetchDevsTotal() {
            if (clients && clients.length > 0) {
                //console.log(clients[0].site_id);
                if (clients[0].all) {
                    getAllDevsTotal()
                        .then((respDevices) => {
                            if (shouldUpdate) {
                                setDevices(respDevices);
                                setTimeout(fetchDevsTotal, interval);
                            }
                        })
                } else if (clients[0].client_id) {
                    getDevsTotalByClients(clients)
                        .then((respDevices) => {
                            if (shouldUpdate) {
                                setDevices(respDevices);
                                setTimeout(fetchDevsTotal, interval);
                            }
                        })
                } else if (clients[0].site_id) {
                    getDevsTotalBySites(clients)
                        .then((respDevices) => {
                            if (shouldUpdate) {
                                setDevices(respDevices);
                                setTimeout(fetchDevsTotal, interval);
                            }
                        })
                } else {
                    if (shouldUpdate) {
                        setDevices([]);
                    }
                }
            } else {
                if (shouldUpdate) {
                    setDevices([]);
                }
            }
        }
        fetchDevsTotal();
        return () => {
            shouldUpdate = false;
        };
    }, [clients, interval]);

    return devices ? devices : null;
};

export const useSitesSumLive = (clients : any, interval: number) => {
    const [sites, setSites] = React.useState<SiteSummary[]>([]);

    React.useEffect(() => {

        let shouldUpdate = true;
        function fetchSitesSum() {
            if (clients && clients.length > 0) {
                //console.log(clients[0].site_id);
                if (clients[0].all) {
                    getAllSitesSummary()
                        .then((respSites) => {
                            if (shouldUpdate) {
                                setSites(respSites);
                                setTimeout(fetchSitesSum, interval);
                            }
                        })
                } else if (clients[0].client_id) {
                    getSitesSummaryByClients(clients)
                        .then((respSites) => {
                            if (shouldUpdate) {
                                setSites(respSites);
                                setTimeout(fetchSitesSum, interval);
                            }
                        })

                } else {
                    if (shouldUpdate) {
                        setSites([]);
                    }
                }
            } else {
                if (shouldUpdate) {
                    setSites([]);
                }
            }
        }
        fetchSitesSum();
        return () => {
            shouldUpdate = false;
        };
    }, [clients, interval]);

    return sites ? sites : null;
};