import React from "react";

import {IconButton, InputAdornment} from "@mui/material";
import {StatusTextField} from "./StatusTextField";
import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";


export const PasswordTextField = (
    {status, InputProps, ...props}
) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleShowPasswordChange = React.useCallback(() => {
        setShowPassword(v => !v)
    }, [setShowPassword]);

    return (
        <StatusTextField
            type={showPassword ? "text" : "password"}
            status={status}
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            disabled={status === "pending"}
                            edge="end"
                            onClick={handleShowPasswordChange}>
                            {showPassword ? <VisibilityOutlined/> : <VisibilityOffOutlined/>}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}/>
    );
}