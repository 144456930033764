import {createContext} from "react";


export interface IETDContext {
    etdName: string;

    updateETDName: (name: string) => void;
}

export const appDefaultETDContext = {
    etdName: '',

    updateETDName: () => {
    },
}

export const ETDContext = createContext <IETDContext> (appDefaultETDContext);