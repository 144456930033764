import {number} from "prop-types";
import {AnimationOnIconChange} from "./AnimationOnIconChange";
import React from "react";
import CloudQueue from "@mui/icons-material/CloudQueue";
import CloudOff from "@mui/icons-material/CloudOff";


export type CloudIconProps = {
    online: number;
    status: number;
};

export const CloudIcon = ( {online, status} : CloudIconProps ): JSX.Element => {

    // const [online, setOnline] = React.useState <number>(1);
    //
    // let timer: NodeJS.Timeout;
    //
    // useEffect(() => {
    //     //console.log('setOnline(1)');
    //     setOnline(1);
    //
    //     clearTimeout(timer);
    //
    //     timer = setTimeout(
    //         () => setOnline(0), 30000);
    //     return () => clearTimeout(timer);
    //
    // }, [timestamp]);

    //console.log(online);

    let cloudComponent : JSX.Element;
    if(online === 1){
        if(status === 1){
            cloudComponent = (<CloudQueue style={{ color: 'orange' }}/>);
        }else{
            cloudComponent = (<CloudQueue style={{ color: 'green' }}/>);
        }
    }else{
        cloudComponent = (<CloudOff style={{ color: 'red' }}/>);
    }

    return (<AnimationOnIconChange value={online * 10 + status}>
        {cloudComponent}
    </AnimationOnIconChange> );
}