import React, {useContext, useEffect} from 'react'
import {makeStyles} from "@mui/styles";
import { useMatch} from "react-router-dom";
import {styled, useTheme, createTheme, ThemeProvider, keyframes} from "@mui/material/styles";
import {
    Box, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogTitle,
    FormGroup, IconButton, InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, MenuItem,
    Paper, Stack,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow, TextField
} from "@mui/material";
import {
    addDevUser,
    delDevUser,
    editDevUser,
    EditDevUserInfo,
    getDevUser,
    getDevUserList,
    UserList,
    UserRole
} from "../api/MQTTApi";
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from "dayjs";
import {
    changePassUser,
    ChangePassUserInfo,
    delUser,
    DelUserInfo, editUser, EditUserInfo,
    registerUser,
    RegUserInfo,
    Response,
    UserInfo
} from "../api/AdminApi";
import Button from "@mui/material/Button";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import {usernameRules, validateUsername} from "../utils/validateUsername";
import {password2Rules, passwordRules, validatePassword} from "../utils/validatePassword";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {validateEmail} from "../utils/validateEmail";
import {validatePhone} from "../utils/validatePhone";
import {tableCellClasses} from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 10,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
    [`&.${tableCellClasses.body}`]: {
        //fontSize: 14,
        fontSize: 9,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export type DeviceUsersProps = {
    titleUpdateHandler: (title: string) => void;
};

const AddButton = styled(IconButton)(({theme}) =>({
    boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    textTransform: 'none',
    fontSize: 16,
    border: 'none',
    //backgroundColor: '#0063cc',
    position: 'fixed',
    bottom: 30,
    right: 30,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    '&:hover': {
        backgroundColor: '#0049a9',
        boxShadow: '0px 2px 15px rgba(0,0,0,0.6)',
    },
    '&:active': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
    '&:focus': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
}));

const EditType = {
    none: 0,
    add: 1,
    edit: 2,
    remove: 3,
}

const userRoles: UserRole[] = [
    //{roleID: 0, roleDescription: "none"},
    {roleID: 1, roleDescription: "MS Detection Factory"},
    {roleID: 2, roleDescription: "MS Detection Lab"},
    {roleID: 3, roleDescription: "MS Detection Technician"},
    {roleID: 4, roleDescription: "Level 1 - Director"},
    {roleID: 5, roleDescription: "Level 2 - Manager"},
    {roleID: 6, roleDescription: "Level 3 - Supervisor"},
    {roleID: 7, roleDescription: "Level 4 - Operator"},
]

export const DeviceUsers = ({titleUpdateHandler} : DeviceUsersProps) : JSX.Element => {

    const classes = useStyles();

    let theme = useTheme();

    theme = createTheme(theme,{
        typography: {
            h5: {
                fontSize: 18,
                color: theme.palette.primary.main,
                marginBottom: 10,
            },
            h6: {
                fontSize: 16,
                color: '#222',
            },
            body1: {
                fontSize: 16,
            },
            body2: {
                fontSize: 16,
                marginBottom: 15,
            },

        },
    });

    const pageMatch = useMatch("/device/:etd_id");
    const etd_id_str = (pageMatch && pageMatch.params.etd_id) || "";

    const etd_id = Number(etd_id_str);

    const [showPassword, setShowPassword] = React.useState(false);

    const [username, setUsername] = React.useState("");
    const [fullName, setFullName] = React.useState("");
    const [role, setRole] = React.useState<number>(0);
    const [password, setPassword] = React.useState("");

    const [userList, setUserList] = React.useState <UserList | undefined> (undefined);

    const [refresh, setRefresh] = React.useState (0);
    const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
    const [selectedUserName, setSelectedUserName] = React.useState<string | undefined>(undefined);
    const [openDelConfirmDialog, setOpenDelConfirmDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openResultDialog, setOpenResultDialog] = React.useState(false);
    const [reqResponse, setReqResponse] = React.useState <Response | undefined> (undefined);
    const [editType, setEditType] = React.useState (0);


    const handleUsernameChange = React.useCallback((e: any) => setUsername(e.target.value), [setUsername]);
    const handleFullNameChange = React.useCallback((e: any) => setFullName(e.target.value), [setFullName]);
    const handleRoleChange = React.useCallback((e: any) => {setRole(e.target.value); console.log('handleRoleChange(): e.target.value: ' + e.target.value);
    }, [setRole]);
    const handlePasswordChange = React.useCallback((e: any)  => setPassword(e.target.value), [setPassword]);

    const [waitingForGetUsers, setWaitingForGetUsers] = React.useState(false);


    const regexpUserName = /^[a-zA-Z0-9.]{1,16}$/;

    const validateDevUsername = (username: string) => {
        return regexpUserName.test(username);
    };

    //const regexpPass = /^(?=.*[A-Za-z])(?=.*\d).{1,32}$/;
    const regexpPass = /^[a-zA-Z0-9.]{1,16}$/;

    const validateDevPassword = (password: string) => {
        return regexpPass.test(password);
    };

    const passwordRules = "Password can contain only letters and digits, and must be from 1 to 16 characters";

    const usernameRules = "Username can contain only letters and digits, and must be from 1 to 16 characters";

    const usernameExist = "Username is already in use";

    const validPassword = (password === undefined && false)
        || validateDevPassword(password);

    const validUsername = ((username === "" && false)
        || validateDevUsername(username));


    React.useEffect(() => {
        setWaitingForGetUsers(true);
        getDevUserList(etd_id)
            .then((devUserList) => {
                setWaitingForGetUsers(false);
                console.log(devUserList);
                //if(userList === undefined){
                    setUserList(devUserList);
                //}
            })
            .catch((err) => {
                setWaitingForGetUsers(false);
                console.log('getUserList(): error: ' + err);
            })
    }, [etd_id, refresh]);

    const deleteUser = (name: string) => {
        console.log("deleteUser(): " + name);
        setEditType(EditType.remove);
        setSelectedUserName(name);
        setOpenDelConfirmDialog(true);
    }

    const editUser = (name: string) => {
        console.log("editUser(): " + name);
        setSelectedUserName(name);

        setUsername(name);
        setFullName("");
        setRole(0);
        setPassword("");
        setEditType(EditType.edit);
        setOpenEditDialog(true);
        getDevUser(etd_id, name)
            .then((userInfo) => {
                setFullName(userInfo.full_name);
                setRole(parseInt(userInfo.role));
                //setPassword(userInfo.password);
            })

    }

    const handleDelConfirmDialogClose = () => {
        setOpenDelConfirmDialog(false);
    }

    const handleDelConfirmDialogSubmit = () => {
        setOpenDelConfirmDialog(false);

        if(selectedUserName) {

            delDevUser(etd_id, selectedUserName)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }
    }

    const handleEditDialogClose = () => {
        setOpenEditDialog(false);
    }

    const handleEditDialogSubmit = () => {

        setOpenEditDialog(false);

        if(editType === EditType.add){
            let editDevUserInfo: EditDevUserInfo = {
                username: username,
                role: role.toString(),
                full_name: fullName,
                password: password,
            }

            addDevUser(etd_id, editDevUserInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }else if(editType === EditType.edit){

            let editDevUserInfo: EditDevUserInfo = {
                username: username,
                role: role.toString(),
                full_name: fullName,
                password: password,
            }

            editDevUser(etd_id, editDevUserInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }
    }

    const handleResultDialogClose = () => {
        setOpenResultDialog(false);
        setRefresh(refresh + 1);
    }

    const handleAddUser = () => {
        setUsername("");
        setFullName("");
        setRole(0);
        setPassword("");

        //setNewUser(true);
        //setChangePass(false);
        setEditType(EditType.add);
        setOpenEditDialog(true);
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }

    const fillUserList = (devUserList: UserList | undefined) => {
        if(devUserList !== undefined){
            return devUserList.list.map((listItem, index) => {
                return (
                    <StyledTableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <StyledTableCell align="center" padding="none"><PersonIcon /></StyledTableCell>
                        <StyledTableCell component="th" scope="row">{listItem["full name"]}</StyledTableCell>
                        <StyledTableCell align="right">{listItem.name}</StyledTableCell>
                        <StyledTableCell align="right">
                            <IconButton aria-label="delete" onClick={() => {deleteUser (listItem.name)}}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label="edit" onClick={() => {editUser (listItem.name)}}>
                                <EditIcon />
                            </IconButton>
                        </StyledTableCell>
                    </StyledTableRow>
                )
            });
        }else{
            return <></>
        }
    };

    const fillUserRoles = (editDevUserInfo: EditDevUserInfo | undefined = undefined) => {
        return userRoles.map((userRole, index) => {
            const selected = (editDevUserInfo ? (parseInt(editDevUserInfo.role) === userRole.roleID) : false);
            return (
                <MenuItem key={index} value={userRole.roleID} selected={selected}>
                    {userRole.roleDescription }
                </MenuItem>
            )
            });
    }

    return(
        <ThemeProvider theme={theme}>

            <FormGroup sx={{ width: 800, border: '1px solid', borderColor: theme.palette.primary.main, padding: '20px', margin: '10px', borderRadius: 2 }}>

                {waitingForGetUsers ?
                    <>
                        {/*<Typography variant="h5" component="div" className={classes.dev_field_title2}>*/}
                        {/*    Waiting for getting history*/}
                        {/*</Typography>*/}
                        <Box sx={{display: 'flex', justifyContent: "center", marginTop: "10px"}}>
                            <CircularProgress/>
                        </Box>
                    </>
                    :
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" padding="none"></StyledTableCell>
                                    <StyledTableCell>Full Name</StyledTableCell>
                                    <StyledTableCell align="right">Name</StyledTableCell>
                                    <StyledTableCell align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fillUserList(userList)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </FormGroup>

            <AddButton
                size="large"
                onClick={handleAddUser}
            >
                <SpeedDialIcon />
            </AddButton>


            <Dialog
                open={openEditDialog}
                onClose={handleEditDialogClose}
            >
                <DialogTitle>{editType === EditType.add ? "Add User" : "Edit User: " + username}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ width: 500 }}>
                        <>
                            {editType === EditType.add &&
                            <TextField
                                error={!validUsername}
                                autoFocus
                                margin="dense"
                                id="username"
                                label={validUsername ? "Username" : "Error"}
                                helperText={validUsername ? undefined : usernameRules}
                                type="text"
                                variant="outlined"
                                value={username}
                                onChange={handleUsernameChange}
                            />
                            }
                            <TextField
                                margin="dense"
                                id="full_name"
                                label="Full Name"
                                type="text"
                                variant="outlined"
                                value={fullName}
                                onChange={handleFullNameChange}
                            />
                            <TextField
                                margin="dense"
                                id="role"
                                select
                                label="Role"
                                variant="outlined"
                                value={role}
                                onChange={handleRoleChange}
                            >
                                {fillUserRoles()}

                            </TextField>

                        </>
                        {/*{(editType === EditType.add) &&*/}
                        <>
                            <TextField
                                error={!validPassword}
                                margin="dense"
                                id="password"
                                label={validPassword ? "Password" : "Error"}
                                helperText={validPassword ? undefined : passwordRules}
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                value={password}
                                onChange={handlePasswordChange}
                                autoComplete="new-password"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                            />

                        </>
                        {/*}*/}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose}>Cancel</Button>
                    <Button
                        onClick={handleEditDialogSubmit}
                        disabled={((editType === EditType.add ) && (!validPassword || !validUsername)) }
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openResultDialog}
                onClose={handleResultDialogClose}
            >
                <DialogTitle>{reqResponse?.success ? "Success" : "Error" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {(reqResponse && !reqResponse.success) ? reqResponse.error :
                            editType === EditType.add ? "New User has been added successfully" :
                            editType === EditType.remove ? "User deletion request successfully sent" :
                            editType === EditType.edit ? "User successfully updated" :
                            ""}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResultDialogClose}>OK</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelConfirmDialog}
                onClose={handleDelConfirmDialogClose}
            >
                <DialogTitle>{"Confirm User Deletion" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {"Please confirm deletion of user '" + selectedUserName + "'"}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelConfirmDialogClose}>Cancel</Button>
                    <Button onClick={handleDelConfirmDialogSubmit}>Confirm</Button>
                </DialogActions>
            </Dialog>

        </ThemeProvider>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 5,
        marginRight: 5,
    },
    updateSettingsBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
    },
    dialog_content: {
        display: "flex",
        flexDirection: "column",
        width: "500px",
    },

}));

