import React from 'react';

//import {Paper} from "@mui/material";

import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js';

import {CardChart, CHART_COLORS, unitLabelCallback} from "./CardChart";

//import zoomPlugin from 'chartjs-plugin-zoom';
import {number} from "prop-types";
import {ScanResult} from "../api/EtdApi";
import {Buffer} from "buffer";
import {calculateTicks} from "../utils/NiceLabelAlgorithm";

const NUM_Y_TICKS = 13;

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);

export type GraphData = {
    time: number,
    F3: number,
    F4: number,
    F5: number,
    F6: number,
    T1: number,
    T2: number,
}

type GraphMinMax = {
    Fmin: number,
    Fmax: number,
    Tmin: number,
    Tmax: number,
}

type GraphTics = {
    tickCount: number,
    niceMin: number,
    niceMax: number,
    tickSpacing: number,
}

export type ScanResultCardChartProps = {
    scanResult: ScanResult,
};

export const ScanResultCardChart = ({scanResult}: ScanResultCardChartProps): JSX.Element => {

    const [graphData, setGraphData] = React.useState <GraphData[] | undefined>(undefined);

    const [fTicks, setFTics] = React.useState <GraphTics | undefined>(undefined);

    const [tTicks, setTTics] = React.useState <GraphTics | undefined>(undefined);

    const chartData: any = React.useMemo(() => ({
        //labels: graphData && graphData.map(row => row.time),

        datasets: [
            {
                label: "F3",
                unit: " Hz",
                parsing: {
                    xAxisKey: "time",
                    yAxisKey: "F3",
                },
                yAxisID: "f",
                fill: false,
                data: graphData,
                pointRadius: 2,
                borderColor: CHART_COLORS.f3,
                backgroundColor: CHART_COLORS.f3,
                cubicInterpolationMode: "monotone",
            },
            {
                label: "F4",
                unit: " Hz",
                parsing: {
                    xAxisKey: "time",
                    yAxisKey: "F4",
                },
                yAxisID: "f",
                fill: false,
                data: graphData,
                pointRadius: 2,
                borderColor: CHART_COLORS.f4,
                backgroundColor: CHART_COLORS.f4,
                cubicInterpolationMode: "monotone",
            },
            {
                label: "F5",
                unit: " Hz",
                parsing: {
                    xAxisKey: "time",
                    yAxisKey: "F5",
                },
                yAxisID: "f",
                fill: false,
                data: graphData,
                pointRadius: 2,
                borderColor: CHART_COLORS.f5,
                backgroundColor: CHART_COLORS.f5,
                cubicInterpolationMode: "monotone",
            },
            {
                label: "F6",
                unit: " Hz",
                parsing: {
                    xAxisKey: "time",
                    yAxisKey: "F6",
                },
                yAxisID: "f",
                fill: false,
                data: graphData,
                pointRadius: 2,
                borderColor: CHART_COLORS.f6,
                backgroundColor: CHART_COLORS.f6,
                cubicInterpolationMode: "monotone",
            },
            {
                label: "T1",
                unit: " °C",
                parsing: {
                    xAxisKey: "time",
                    yAxisKey: "T1",
                },
                yAxisID: "t",
                fill: false,
                data: graphData,
                pointRadius: 2,
                borderColor: CHART_COLORS.t1,
                backgroundColor: CHART_COLORS.t1,
                cubicInterpolationMode: "monotone",
            },
        ],
    }), [graphData]);

    const options: any = React.useMemo(() => ({
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'linear',
                ticks: {
                    callback: function (value: number) {
                        return value + " sec";
                    }
                }
            },
            f: {
                beginAtZero: false,
                axis: "y",
                //grace: '5%',
                ticks: {
                    callback: function (value: number, index: number, values: any) {
                        return value + " Hz";
                    },
                    stepSize: fTicks?.tickSpacing,
                    autoSkip: false,
                },
                min: fTicks?.niceMin,
                max: fTicks?.niceMax,
            },
            t: {
                beginAtZero: false,
                axis: "y",
                //grace: '5%',
                position: "right",
                ticks: {
                    callback: function (value: number, index: number, values: any) {
                        return value + " °C";
                    },
                    stepSize: tTicks?.tickSpacing,
                    autoSkip: false,
                },
                min: tTicks?.niceMin,
                max: tTicks?.niceMax,
            },
        },
        animation: true,
         plugins: {
        //     tooltip: {
        //         usePointStyle: true,
        //         callbacks: {
        //             label: unitLabelCallback,
        //         },
        //     },
             legend:{
                 labels: {
                     boxWidth: 20,
                     boxHeight: 5,
                     //usePointStyle: true,
                 }
             },
         },
    }), [tTicks, fTicks]);

    React.useEffect(() => {
        if(scanResult && scanResult.resdata) {
            setGraphData(getGraph(scanResult.resdata));
        }
    }, [scanResult]);

    const getGraph = (resdata: string) : GraphData[] => {

        let minmax: GraphMinMax = {Fmin: 0, Fmax: 0, Tmin: 1000, Tmax: 0};

        //console.log(resdata);
        const buff = Buffer.from(resdata, "base64");
        //const outData: GraphData = {} as GraphData;
        const outData: GraphData[] = [];
        //console.log(outData);
        //console.log(JSON.stringify(outData));
        if(buff.length >= 728) {
            for (let i = 0; i < 26; i++) {
                const time = buff.readFloatLE(i * 4);
                const F3 = i === 0 ? 0 : buff.readFloatLE(i * 4 + 26 * 4);
                const F4 = i === 0 ? 0 : buff.readFloatLE(i * 4 + (26 * 4) * 2);
                const F5 = i === 0 ? 0 : buff.readFloatLE(i * 4 + (26 * 4) * 3);
                const F6 = i === 0 ? 0 : buff.readFloatLE(i * 4 + (26 * 4) * 4);
                const T1 = buff.readFloatLE(i * 4 + (26 * 4) * 5);
                const T2 = buff.readFloatLE(i * 4 + (26 * 4) * 6);
                outData.push({
                    time: time,
                    F3: F3,
                    F4: F4,
                    F5: F5,
                    F6: F6,
                    T1: T1,
                    T2: T2,
                });
                if(F3 > minmax.Fmax){ minmax.Fmax = F3; }
                if(F4 > minmax.Fmax){ minmax.Fmax = F4; }
                if(F5 > minmax.Fmax){ minmax.Fmax = F5; }
                if(F6 > minmax.Fmax){ minmax.Fmax = F6; }
                if(F3 < minmax.Fmin){ minmax.Fmin = F3; }
                if(F4 < minmax.Fmin){ minmax.Fmin = F4; }
                if(F5 < minmax.Fmin){ minmax.Fmin = F5; }
                if(F6 < minmax.Fmin){ minmax.Fmin = F6; }
                if(T1 > minmax.Tmax){ minmax.Tmax = T1; }
                if(T1 < minmax.Tmin){ minmax.Tmin = T1; }
            }

            let [tickCount, niceMin, niceMax, tickSpacing] = calculateTicks(NUM_Y_TICKS, minmax.Fmin, minmax.Fmax);
            //console.log("tickCount: " + tickCount + ", niceMin: " + niceMin + ", niceMax: " + niceMax + ", tickSpacing: " + tickSpacing);
            if(tickCount < NUM_Y_TICKS){
                const addTopTics = Math.ceil((NUM_Y_TICKS - tickCount) / 2);
                niceMax = niceMax + tickSpacing * addTopTics;
                niceMin = niceMin - tickSpacing * (NUM_Y_TICKS - tickCount - addTopTics);
                tickCount = NUM_Y_TICKS;
                //console.log("tickCount: " + tickCount + ", niceMin: " + niceMin + ", niceMax: " + niceMax + ", tickSpacing: " + tickSpacing);
            }
            setFTics({tickCount: tickCount, niceMin: niceMin, niceMax: niceMax, tickSpacing: tickSpacing});

            tickSpacing = 5;
            niceMax = Math.ceil(minmax.Tmax / tickSpacing) * tickSpacing;
            niceMin = Math.floor(minmax.Tmin / tickSpacing) * tickSpacing;
            tickCount = (niceMax - niceMin) / tickSpacing + 1;
            //console.log("tickCount: " + tickCount + ", niceMin: " + niceMin + ", niceMax: " + niceMax + ", tickSpacing: " + tickSpacing);
            if(tickCount < NUM_Y_TICKS){
                const addTopTics = Math.ceil((NUM_Y_TICKS - tickCount) / 2);
                niceMax = niceMax + tickSpacing * addTopTics;
                niceMin = niceMin - tickSpacing * (NUM_Y_TICKS - tickCount - addTopTics);
                tickCount = NUM_Y_TICKS;
                //console.log("tickCount: " + tickCount + ", niceMin: " + niceMin + ", niceMax: " + niceMax + ", tickSpacing: " + tickSpacing);
            }
            setTTics({tickCount: tickCount, niceMin: niceMin, niceMax: niceMax, tickSpacing: tickSpacing});

        }
        //console.log(JSON.stringify(outData));
        //console.log("getGraph(): " + buff.readFloatLE(0).toFixed(2));

        return outData;
    }

    return (
        <CardChart title={scanResult.Status} materialDetected={scanResult.MaterialDetected}>
            <Line data={chartData} options={options}/>
        </CardChart>
    );
};