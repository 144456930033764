import React, {useEffect} from "react";

import { Chart } from "react-google-charts";
import {uptimeListRec} from "../api/EtdApi";


// type dataRec = {
//     room: string;
//     name: string;
//     start: Date;
//     end: Date;
// };

export type UptimeLiveChartProps = {
    uptimeList: uptimeListRec[] | undefined;
};

export const UptimeLiveChart = ({uptimeList}: UptimeLiveChartProps): JSX.Element => {

    const [data, setData] = React.useState <any> ([]);

    const [options, setOptions] = React.useState <any> (
        {
                    timeline: { showRowLabels: false },
                    avoidOverlappingGridLines: false,
                    colors: ["#55cc55", "#cc5555"],
                });

    useEffect(() => {
        if(uptimeList && uptimeList.length > 0){

            //console.log(uptimeList);

            setData(() => {
                const _data = [];
                _data.push([
                        { type: "string", id: "Room" },
                        { type: "string", id: "Name" },
                        { type: "date", id: "Start" },
                        { type: "date", id: "End" },
                    ]);

                // [{"state":0,"start_time":1700650173,"duration":604800}]

                uptimeList.map((listItem, index) => {
                        _data.push(
                            [
                                "",
                                (listItem.state === 1) ? "on" : "off",
                                new Date(listItem.start_time * 1000),
                                new Date(listItem.start_time * 1000 + listItem.duration * 1000),
                            ],
                        );
                        if(index === 0){
                            setOptions(
                                {
                                    timeline: { showRowLabels: false },
                                    avoidOverlappingGridLines: false,
                                    colors: [(listItem.state === 1) ? "#55cc55" : "#cc5555", (listItem.state === 0) ? "#55cc55" : "#cc5555"],
                                }
                            );
                        }
                    });

                return _data;
            });
        }
    }, [uptimeList])

    return (
        <Chart
            chartType="Timeline"
            data={data}
            options={options}
            height={100}
        />
    );
}