import React from 'react'
import {makeStyles} from "@mui/styles";
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {deviceShort, getDevShort} from "../api/EtdApi";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {AnimationOnTextChange} from "../components/AnimationOnTextChange";
import TableContainer from "@mui/material/TableContainer";
import {
    changePassUser,
    ChangePassUserInfo, delUser, DelUserInfo, editUser, EditUserInfo,
    getUsers,
    registerUser,
    RegUserInfo,
    UserInfo,
    Response
} from "../api/AdminApi";
import {getAllDevsBrief} from "../api/DevicesApi";

import MenuIcon from '@mui/icons-material/Menu';
import {
    Backdrop,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton, InputAdornment,
    Menu,
    MenuItem, Stack,
    TextField
} from "@mui/material";

import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDial from '@mui/material/SpeedDial';
import Button from "@mui/material/Button";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {validateEmail} from "../utils/validateEmail";
import {usernameRules, validateUsername} from "../utils/validateUsername";
import {password2Rules, passwordRules, validatePassword} from "../utils/validatePassword";
import {validatePhone} from "../utils/validatePhone";


export type AdminDashboardProps = {
    titleUpdateHandler: (title: string) => void;
};

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AddButton = styled(IconButton)(({theme}) =>({
    boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    textTransform: 'none',
    fontSize: 16,
    border: 'none',
    //backgroundColor: '#0063cc',
    position: 'fixed',
    bottom: 30,
    right: 30,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    '&:hover': {
        backgroundColor: '#0049a9',
        boxShadow: '0px 2px 15px rgba(0,0,0,0.6)',
    },
    '&:active': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
    '&:focus': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
}));

const EditType = {
    none: 0,
    add: 1,
    edit: 2,
    remove: 3,
    changePass: 4,
}


export const AdminUsers = ( {titleUpdateHandler}: AdminDashboardProps ) : JSX.Element => {

    const classes = useStyles();

    const theme = useTheme();

    const [refresh, setRefresh] = React.useState (0);

    const [users, setUsers] = React.useState <UserInfo[] | undefined>(undefined);

    const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
    const [menuUser, setMenuUser] = React.useState<UserInfo | undefined>(undefined);

    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openResultDialog, setOpenResultDialog] = React.useState(false);
    const [openDelConfirmDialog, setOpenDelConfirmDialog] = React.useState(false);

    const [reqResponse, setReqResponse] = React.useState <Response | undefined> (undefined);

    // const [newUser, setNewUser] = React.useState(false);
    // const [changePass, setChangePass] = React.useState(false);

    const [editType, setEditType] = React.useState(0);

    //const [editUserInfo, setEditUserInfo] = React.useState <EditUserInfo | undefined> (undefined)

    const [showPassword, setShowPassword] = React.useState(false);

    const [username, setUsername] = React.useState("");
    const [fullName, setFullName] = React.useState("");
    const [role, setRole] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");

    const handleUsernameChange = React.useCallback((e: any) => setUsername(e.target.value), [setUsername]);
    const handleFullNameChange = React.useCallback((e: any) => setFullName(e.target.value), [setFullName]);
    const handleRoleChange = React.useCallback((e: any) => setRole(e.target.value), [setRole]);
    const handleEmailChange = React.useCallback((e: any) => setEmail(e.target.value), [setEmail]);
    const handlePhoneChange = React.useCallback((e: any) => setPhone(e.target.value), [setPhone]);
    const handlePasswordChange = React.useCallback((e: any)  => setPassword(e.target.value), [setPassword]);
    const handlePassword2Change = React.useCallback((e: any)  => setPassword2(e.target.value), [setPassword2]);

    const validPassword = (password === undefined && false)
        || validatePassword(password);
    const validPassword2 = (password === password2);

    const validEmail = ((email === "" && false)
        || validateEmail(email));
    const validUsername = ((username === "" && false)
        || validateUsername(username));
    const validPhone = ((phone === "" && false)
        || validatePhone(phone));

    //console.log('validPassword: ' + validPassword + ', validPassword2: ' + validPassword2 + ', validEmail: ' + validEmail + ', validUsername: ' + validUsername + ', validPhone: ' + validPhone);

    const open = Boolean(anchorEl);

    React.useEffect(() => {
        titleUpdateHandler("Manage Users");
    }, [titleUpdateHandler]);

    React.useEffect(() => {
        getUsers()
            .then((users) => {
                setUsers(users);
            });
    }, [refresh]);

    const handleMenuOpen = (event:  React.MouseEvent<SVGSVGElement>, user: UserInfo) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setMenuUser(user);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditUser = () => {
        handleMenuClose();
        //console.log(menuUser);
        if(menuUser) {
            setUsername(menuUser.username);
            setFullName(menuUser.full_name);
            setRole(menuUser.role);
            setEmail(menuUser.email);
            setPhone(menuUser.phone);
        }
        //setNewUser(false);
        //setChangePass(false);
        setEditType(EditType.edit);
        setOpenEditDialog(true);
    }

    const handleDeleteUser = () => {
        handleMenuClose();
        if(menuUser) {
            setUsername(menuUser.username);
            setEditType(EditType.remove);
            setOpenDelConfirmDialog(true);
        }
    }

    const handleChangePassword = () => {
        handleMenuClose();
        if(menuUser) {
            setUsername(menuUser.username);

            setPassword("");
            setPassword2("");
            //setChangePass(true);
            //setNewUser(false);
            setEditType(EditType.changePass);
            setOpenEditDialog(true);
        }
    }

    const handleAddUser = () => {
        setUsername("");
        setFullName("");
        setRole("basic");
        setEmail("");
        setPhone("");
        setPassword("");
        setPassword2("");

        //setNewUser(true);
        //setChangePass(false);
        setEditType(EditType.add);
        setOpenEditDialog(true);
    }

    const handleEditDialogClose = () => {
        setOpenEditDialog(false);
    }

    const handleResultDialogClose = () => {
        setOpenResultDialog(false);
        setRefresh(refresh + 1);
    }

    const handleEditDialogSubmit = () => {

        setOpenEditDialog(false);

        if(editType === EditType.add){
            let regUserInfo: RegUserInfo = {
                username: username,
                role: role,
                full_name: fullName,
                email: email,
                phone: phone,
                password: password,
            }

            registerUser(regUserInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                });
        }else if(editType === EditType.changePass){
            let changePassUserInfo: ChangePassUserInfo = {
                username: username,
                new_password: password,
            }

            changePassUser(changePassUserInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }else if(editType === EditType.edit){
            let editUserInfo: EditUserInfo = {
                username: username,
                role: role,
                full_name: fullName,
                email: email,
                phone: phone,
            }

            //console.log(JSON.stringify(editUserInfo));

            editUser(editUserInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }


    }

    const handleDelConfirmDialogClose = () => {
        setOpenDelConfirmDialog(false);
    }

    const handleDelConfirmDialogSubmit = () => {
        setOpenDelConfirmDialog(false);

        if(menuUser) {
            let delUserInfo: DelUserInfo = {
                username: menuUser.username,
            }

            delUser(delUserInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }

    return(
        <Box sx={{ flexGrow: 1 }} >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">ID</StyledTableCell >
                            <StyledTableCell align="left">Username</StyledTableCell >
                            <StyledTableCell align="left">Full Name</StyledTableCell >
                            <StyledTableCell align="left">Role</StyledTableCell >
                            <StyledTableCell align="left">Email</StyledTableCell >
                            <StyledTableCell align="left">Phone</StyledTableCell >
                            <StyledTableCell align="right">Actions</StyledTableCell >
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {users && users.map((user) => {
                            if (user) {

                                return (
                                    <>
                                        <StyledTableRow key={user.id} className={classes.tableRow}>
                                            <StyledTableCell align="left" component="th" scope="row">
                                                {user.id}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{user.username}</StyledTableCell>
                                            <StyledTableCell align="left">{user.full_name}</StyledTableCell>
                                            <StyledTableCell align="left">{user.role}</StyledTableCell>
                                            <StyledTableCell align="left">{user.email}</StyledTableCell>
                                            <StyledTableCell align="left">{user.phone}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <MenuIcon style={{ color: theme.palette.primary.main, cursor: 'pointer'}} onClick={(event)=>{handleMenuOpen(event, user)}}/>
                                            </StyledTableCell>

                                        </StyledTableRow>

                                    </>
                                )
                            }else{
                                return null;
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleEditUser}>Edit User</MenuItem>
                <MenuItem onClick={handleDeleteUser}>Delete User</MenuItem>
                <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
            </Menu>
            <AddButton
                size="large"
                onClick={handleAddUser}
            >
                <SpeedDialIcon />
            </AddButton>
            <Dialog
                open={openEditDialog}
                onClose={handleEditDialogClose}
            >
                <DialogTitle>{editType === EditType.add ? "Add User" : (editType === EditType.changePass ? "Change Password for user: " + username : "Edit User: " + username)}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ width: 500 }}>
                        {editType !== EditType.changePass &&
                        <>
                            {editType === EditType.add &&
                            <TextField
                                error={!validUsername}
                                autoFocus
                                margin="dense"
                                id="username"
                                label={validUsername ? "Username" : "Error"}
                                helperText={validUsername ? undefined : usernameRules}
                                type="text"
                                variant="outlined"
                                value={username}
                                onChange={handleUsernameChange}
                            />
                            }
                            <TextField
                                margin="dense"
                                id="full_name"
                                label="Full Name"
                                type="text"
                                variant="outlined"
                                value={fullName}
                                onChange={handleFullNameChange}
                            />
                            <TextField
                                margin="dense"
                                id="role"
                                select
                                label="Role"
                                variant="outlined"
                                defaultValue="basic"
                                value={role}
                                onChange={handleRoleChange}

                            >
                                <MenuItem key='basic' value='basic' selected>
                                    basic
                                </MenuItem>
                                <MenuItem key='admin' value='admin'>
                                    admin
                                </MenuItem>


                            </TextField>
                            <TextField
                                error={!validEmail}
                                margin="dense"
                                id="email"
                                label={validEmail ? "Email Address" : "Error"}
                                helperText={validEmail ? undefined : "Incorrect email"}
                                type="email"
                                variant="outlined"
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <TextField
                                error={!validPhone}
                                margin="dense"
                                id="phone"
                                label={validPhone ? "Phone Number" : "Error"}
                                helperText={validPhone ? undefined : "Incorrect phone number"}
                                type="tel"
                                variant="outlined"
                                value={phone}
                                onChange={handlePhoneChange}
                            />
                        </>
                        }
                        {(editType === EditType.add || editType === EditType.changePass) &&
                        <>
                            <TextField
                                error={!validPassword}
                                margin="dense"
                                id="password"
                                label={validPassword ? "Password" : "Error"}
                                helperText={validPassword ? undefined : passwordRules}
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                value={password}
                                onChange={handlePasswordChange}
                                autoComplete="new-password"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                            />

                            <TextField
                                error={!validPassword2}
                                margin="dense"
                                id="password2"
                                label={validPassword2 ? "Retype Password" : "Error"}
                                helperText={validPassword2 ? undefined : password2Rules}
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                value={password2}
                                onChange={handlePassword2Change}
                                autoComplete="new-password"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                            />
                        </>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose}>Cancel</Button>
                    <Button
                        onClick={handleEditDialogSubmit}
                        disabled={
                            ((editType === EditType.add) && (!validPassword || !validPassword2 || !validUsername || !validEmail || !validPhone))
                            || ((editType === EditType.edit) && (!validEmail || !validPhone))
                            || ((editType === EditType.changePass) && (!validPassword || !validPassword2))
                        }
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openResultDialog}
                onClose={handleResultDialogClose}
            >
                <DialogTitle>{reqResponse?.success ? "Success" : "Error" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {(reqResponse && !reqResponse.success) ? reqResponse.error :
                            editType === EditType.add ? "New User has been added successfully" :
                                editType === EditType.changePass ? "Password successfully changed" :
                                    editType === EditType.remove ? "User successfully deleted" :
                                        editType === EditType.edit ? "User successfully updated" :
                                            ""}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResultDialogClose}>OK</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelConfirmDialog}
                onClose={handleDelConfirmDialogClose}
            >
                <DialogTitle>{"Confirm User Deletion" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {"Please confirm deletion of user '" + username + "'"}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelConfirmDialogClose}>Cancel</Button>
                    <Button onClick={handleDelConfirmDialogSubmit}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    tableBody: {
        height: '200px',
        overflowX: 'auto',
    },
    tableRow: {
        //cursor: 'pointer',
    },
    dialog_content: {
        display: "flex",
        flexDirection: "column",
        width: "500px",
    },

}));