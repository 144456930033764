import React, {useContext} from 'react'
import {makeStyles} from "@mui/styles";
import {GoogleMap} from "../components/GoogleMap";

import {DashboardProps} from "./Dashboard";
import Box from '@mui/material/Box';

import {Navigate, useMatch, useNavigate} from "react-router-dom";
import {
    deviceGenInfo,
    deviceShort, dynamicInfo,
    getDevShort,
    healthChecksInfo, resultsSummary, ScanResult,
    useDevGenInfoLive, useDynInfoLive,
    useHealthCheckInfoLive, useResultsSummaryLive, useScanResultsLive, useUptimeListLive, useUptimeStatLive
} from "../api/EtdApi";
import {deviceBrief, useDevsTotalLive} from "../api/DevicesApi";
import Grid from "@mui/material/Grid";
import {styled, useTheme, createTheme, ThemeProvider, keyframes} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import useWindowDimensions from "../hooks/useWindowDimensions";
import Typography from "@mui/material/Typography";
import CloudQueue from "@mui/icons-material/CloudQueue";
import CloudOff from "@mui/icons-material/CloudOff";
import Divider from "@mui/material/Divider";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {number} from "prop-types";
//import AnimateOnChange from 'react-animate-on-change'
import clsx from "clsx";
import {AnimationOnIconChange} from "../components/AnimationOnIconChange";
import {AnimationOnTextChange} from "../components/AnimationOnTextChange";
import {CloudIcon} from "../components/CloudIcon";
import {UptimeGraph} from "../components/UptimeGraph";
import {ETDContext} from "../context/ETDContext";
import {ScanResultCardChart} from "../components/ScanResultCardChart";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

//const { Buffer } = require('node:buffer');
import { Buffer } from 'buffer';

const GEN_INFO_UPDATE_INTERVAL = 5000;      // 5 sec

const HEALTH_UPDATE_INTERVAL = 3000;        // 3 sec

const RESSUM_UPDATE_INTERVAL = 10000;       // 10 sec

const DYNINFO_UPDATE_INTERVAL = 10000;      // 10 sec

const SCANRES_UPDATE_INTERVAL = 10000;      // 10 sec

enum ToggleGraph {
    Map = 1,
    Graph,
};

export type DevicesDashboardProps = {
    titleUpdateHandler: (title: string) => void;
};

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    '@media (min-width:1024px)': {
        padding: theme.spacing(2),
    },
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        //fontSize: 16,
        fontSize: 8,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 16,
        },
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const DeviceDashboard = ({titleUpdateHandler}: DevicesDashboardProps): JSX.Element => {

    const classes = useStyles();

    let theme = useTheme();

    theme = createTheme(theme, {
        typography: {
            h5: {
                //fontSize: 18,
                color: theme.palette.primary.main,

                marginBottom: 5,
                fontSize: 10,
                '@media (min-width:1024px)': {
                    fontSize: 14,
                    marginBottom: 8,
                },
                //[theme.breakpoints.up('md')]: {
                '@media (min-width:1920px)': {
                    fontSize: 18,
                    marginBottom: 10,
                },
            },
            h6: {
                //fontSize: 16,
                color: '#222',

                fontSize: 8,
                '@media (min-width:1024px)': {
                    fontSize: 12,
                },
                '@media (min-width:1920px)': {
                    fontSize: 16,
                },
            },
            body1: {
                //fontSize: 16,
                fontSize: 8,
                '@media (min-width:1024px)': {
                    fontSize: 12,
                },
                '@media (min-width:1920px)': {
                    fontSize: 16,
                },
            },
            body2: {
                //fontSize: 16,

                fontSize: 8,
                marginBottom: 5,
                '@media (min-width:1024px)': {
                    fontSize: 12,
                    marginBottom: 10,
                },
                '@media (min-width:1920px)': {
                    fontSize: 16,
                    marginBottom: 15,
                },
            },
            button: {
                //fontSize: 16,

                fontSize: 8,
                marginBottom: 5,
                marginTop: 5,
                '@media (min-width:1024px)': {
                    fontSize: 12,
                    marginBottom: 10,
                    marginTop: 10,
                },
                '@media (min-width:1920px)': {
                    fontSize: 16,
                    marginBottom: 15,
                    marginTop: 15,
                },
            },

        },
    });

    //const navigate = useNavigate();

    const pageMatch = useMatch("/device/:etd_id");
    const etd_id_str = (pageMatch && pageMatch.params.etd_id) || "";

    const [devShort, setDevShort] = React.useState <deviceShort | undefined>(undefined);

    const {width, height} = useWindowDimensions();

    const {etdName} = useContext(ETDContext);

    //console.log('etd_id: ' + etd_id);

    const etd_id = Number(etd_id_str);

    //const etd = useDevShort(etd_id);

    let devGenInfo = useDevGenInfoLive(etd_id, GEN_INFO_UPDATE_INTERVAL);

    let healthInfo = useHealthCheckInfoLive(etd_id, HEALTH_UPDATE_INTERVAL);

    let resultsSummary = useResultsSummaryLive(etd_id, RESSUM_UPDATE_INTERVAL);

    //console.log(JSON.stringify(resultsSummary));

    let dynInfo = useDynInfoLive(etd_id, DYNINFO_UPDATE_INTERVAL);

    let scanResults = useScanResultsLive(etd_id, SCANRES_UPDATE_INTERVAL);

    //console.log('scanResults: ' + JSON.stringify(scanResults));

    //const [graphData, setGraphData] = React.useState <GraphData | undefined>(undefined);

    const [selectedScanResult, setSelectedScanResult] = React.useState <ScanResult | undefined>(undefined);

    const [toggleState, setToggleState] = React.useState<ToggleGraph>(ToggleGraph.Map);

    let mapDevs: deviceBrief[] = [];

    if (devGenInfo !== undefined) {
        mapDevs.push({
            etd_id: devGenInfo.etd_id,
            serial_number: devGenInfo.SerialNumber,
            latitude: devGenInfo.latitude,
            longitude: devGenInfo.longitude,
            site_name: '',
            client_name: '',
            online: devGenInfo.online,
            Status: devGenInfo.Status,
            description: (devShort !== undefined) ? devShort.description : '',
            site_id: 0,
            client_id: 0,
            clear: 0,
            explosives: 0,
            narcotics: 0,
        });
    }

    React.useEffect(() => {
        titleUpdateHandler(etdName);
    },[etdName]);

    React.useEffect(() => {
        if (etd_id !== undefined) {

            const graphState = localStorage.getItem('graphState');
            console.log(graphState);
            if(graphState){
                if(graphState === 'Map'){
                    setToggleState(ToggleGraph.Map);
                }else{
                    setToggleState(ToggleGraph.Graph);
                }
            }

            getDevShort(etd_id)
                .then((respEtd) => {
                    //console.log(respEtd);
                    if (respEtd !== undefined) {
                        //console.log(respEtd.SerialNumber);
                        //titleUpdateHandler(respEtd.SerialNumber);
                        setDevShort(respEtd);
                    }
                })
        }
    }, [etd_id/*, titleUpdateHandler*/]);

    React.useEffect(() => {
        if(scanResults !== undefined) {
            let dateTime = 0;
            let lastResult = undefined;
            for (const scanResult of scanResults) {
                if(scanResult && scanResult.DateTime > dateTime) {
                    dateTime = scanResult.DateTime;
                    lastResult = scanResult;
                }
            }
            if(dateTime > 0) {
                // setGraphData(getGraph(dateTime));
                //console.log("lastResult: " + JSON.stringify(lastResult) );
                //console.log("lastResult.resdata: " + JSON.stringify(lastResult?.resdata) );
                setSelectedScanResult(lastResult);
            }
        }
    }, [scanResults]);

    const cloudIcon = (online: number, status: number) => {
        //console.log(status);

        // let cloudComponent : JSX.Element;
        // if(online === 1){
        //     if(status === 1){
        //         cloudComponent = (<CloudQueue style={{ color: 'orange' }}/>);
        //     }else{
        //         cloudComponent = (<CloudQueue style={{ color: 'green' }}/>);
        //     }
        // }else{
        //     cloudComponent = (<CloudOff style={{ color: 'red' }}/>);
        // }

        // return (<AnimationOnIconChange value={online * 10 + status}>
        //     {cloudComponent}
        // </AnimationOnIconChange> );

        return (<CloudIcon online={online} status={status}/>);
    }

    const uptimeFormat = (uptime: number): string => {

        const date = new Date(uptime);

        // const hours = Math.trunc(uptime / 3600);
        // const minutes = Math.trunc((uptime / 60) % 60);
        // const seconds = Math.trunc(uptime % 60);

        //return ("000" + hours.toString()).slice(0, 4) + ":" + ("0" + minutes.toString()).slice(0, 2) + ":" + ("0" + seconds.toString()).slice(0, 2);

        //return hours.toString() + " hours, " + minutes.toString() + " minutes";

        const options: Intl.DateTimeFormatOptions = {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);

        return formatter.format(date); // date.toLocaleString();
    }

    const timeUsageFormat = (usageTime: number): string => {

        const hours = Math.trunc(usageTime / 3600);
        const minutes = Math.trunc((usageTime / 60) % 60);
        const seconds = Math.trunc(usageTime % 60);

        //return ("000" + hours.toString()).slice(0, 4) + ":" + ("0" + minutes.toString()).slice(0, 2) + ":" + ("0" + seconds.toString()).slice(0, 2);

        return hours.toString() + " hours, " + minutes.toString() + " minutes";
    }

    const dynInfoComponent = (dynInfo: dynamicInfo): JSX.Element => {

        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                        Dynamic Information
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Sensor 1
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.Sensor1.toString()}>
                            {dynInfo.Sensor1 + ' Hz'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Sensor 5
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.Sensor5.toString()}>
                            {dynInfo.Sensor5 + ' Hz'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        T1
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.T1Temperature.toString()}>
                            {dynInfo.T1Temperature + ' °C'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Sensor 2
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.Sensor2.toString()}>
                            {dynInfo.Sensor2 + ' Hz'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Sensor 6
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.Sensor6.toString()}>
                            {dynInfo.Sensor6 + ' Hz'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        T2
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.T2Temperature.toString()}>
                            {dynInfo.T2Temperature + ' °C'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Sensor 3
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.Sensor3.toString()}>
                            {dynInfo.Sensor3 + ' Hz'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Sensor 7
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.Sensor7.toString()}>
                            {dynInfo.Sensor7 + ' Hz'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Battery
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.BatteryPercentage.toString()}>
                            {dynInfo.BatteryPercentage + ' %'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Sensor 4
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.Sensor4.toString()}>
                            {dynInfo.Sensor4 + ' Hz'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Sensor 8
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.Sensor8.toString()}>
                            {dynInfo.Sensor8 + ' Hz'}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>

                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>

                    </Typography>
                </Grid>

            </Grid>
        );
    }

    const uptimeComponent = (dynInfo: dynamicInfo, genInfo: deviceGenInfo): JSX.Element => {

        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                        Samples
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Sample Count
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.NumOfSensorTests.toString()}>
                            {dynInfo.NumOfSensorTests}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Today Samples
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={dynInfo.TodaySwabNum.toString()}>
                            {dynInfo.TodaySwabNum}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Switch On Time
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={genInfo.online ? uptimeFormat(genInfo.UpTime) : "-"}>
                            {genInfo.online ? uptimeFormat(genInfo.UpTime) : "-"}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Time Usage of Device
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        <AnimationOnTextChange value={timeUsageFormat(dynInfo.TimeUsageOfSensor)}>
                            {timeUsageFormat(dynInfo.TimeUsageOfSensor)}
                        </AnimationOnTextChange>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

            </Grid>
        );
    }

    const fillHealthChecks = (healthChecks: healthChecksInfo[]) => {
        // if (healthChecks.length < 5) {
        //     const healthInfo = healthChecks[healthChecks.length - 1];
        //     for (let i = 0; i < (5 - healthChecks.length); i++) {
        //         healthChecks.push(healthInfo);
        //     }
        // }

        return healthChecks && healthChecks.map((health, index) => {
            if (health) {
                const date = new Date(health.date_time);
                const cntr = String(health.cntr);
                if (index === 0) {
                    return (
                        <Grid key={index + 3} item xs={12}>
                            <AnimationOnTextChange value={date.toLocaleString()}>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: -5,
                                    marginBottom: -5,
                                }}>
                                    <Typography variant="button" component="div"  >
                                        {date.toLocaleTimeString("en-GB")}
                                    </Typography>
                                    <Typography variant="button" component="div"  >
                                        {cntr}
                                    </Typography>
                                </div>
                            </AnimationOnTextChange>
                            {/*<Divider component="li"/>*/}
                                <Divider/>
                        </Grid>
                    )
                } else {
                    return (
                        <Grid key={index + 3} item xs={12}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: -5,
                                    marginBottom: -5,
                                }}>
                                <Typography variant="button" component="div"  >
                                    {date.toLocaleTimeString("en-GB")}
                                </Typography>
                                <Typography variant="button" component="div"  >
                                    {cntr}
                                </Typography>
                                </div>
                            {/*<Divider component="li"/>*/}
                                <Divider/>
                        </Grid>
                    )
                }
            } else {
                return null;
            }
        });
    };

    const fillScanResults = (scanResults: ScanResult[]) => {

        return scanResults && scanResults.map((scanResult, index) => {
            if (scanResult) {

                // const graphData = getGraph(scanResult.DateTime);
                // console.log(JSON.stringify(graphData));

                const date = new Date(scanResult.DateTime);
                const alarm = scanResult.Status !== "Clear";

                const options: Intl.DateTimeFormatOptions = {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                };
                const formatter = new Intl.DateTimeFormat('en-US', options);

                if (index === 0) {
                    //console.log('alarm: ' + alarm);
                    return (
                        <Grid key={index + 3} item xs={12}>
                                <AnimationOnTextChange value={date.toLocaleString()}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            marginTop: alarm? 0 : ((width > 1024) ? 10 : 5),
                                            marginBottom: alarm? 0 : ((width > 1024) ? 10 : 5),
                                        }}>
                                            <Typography variant="h6" component="div" color={alarm? "red" : "green"} >
                                                {formatter.format(date)}
                                            </Typography>

                                            <Typography variant="h6" component="div" color={alarm? "red" : "green"} >
                                                {scanResult.Status}
                                            </Typography>

                                        </div>
                                        {alarm ?
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-end",
                                                marginTop: 0,
                                                marginBottom: 0,
                                            }}>
                                                <Typography variant="body1" component="div">
                                                    {scanResult.MaterialDetected}
                                                </Typography>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                 </AnimationOnTextChange>
                                <Divider/>
                        </Grid>
                    )
                } else {
                    return (
                        <Grid key={index + 3} item xs={12}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginTop: alarm? 0 : ((width > 1024) ? 10 : 5),
                                        marginBottom: alarm? 0 : ((width > 1024) ? 10 : 5),
                                    }}>
                                        <Typography variant="h6" component="div" color={alarm? "red" : "green"} >
                                            {formatter.format(date)}
                                        </Typography>

                                        <Typography variant="h6" component="div" color={alarm? "red" : "green"} >
                                            {scanResult.Status}
                                        </Typography>

                                    </div>
                                    {alarm ?
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}>
                                            <Typography variant="body1" component="div">
                                                {scanResult.MaterialDetected}
                                            </Typography>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <Divider/>
                        </Grid>
                    )
                }
            } else {
                return null;
            }
        });
    };

    const healthChecksComponent = (healthChecks: healthChecksInfo[]): JSX.Element => {

        //console.log('healthChecksComponent(): ' + JSON.stringify(healthChecks));

        let dateStr = "";
        if(healthChecks && healthChecks[0]) {
            const date = new Date(healthChecks[0].date_time);
            dateStr = date.toLocaleDateString("en-GB");
        }

        return (
            <Grid container spacing={0}>
                <Grid key={1} item xs={12}>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                        Health Checks
                    </Typography>
                        {healthChecks && healthChecks[0] ?
                            <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                                {dateStr}
                            </Typography>
                            :
                            <></>
                        }
                    </div>
                </Grid>
                <Grid key={2} item xs={12}>
                    <Divider/>
                </Grid>

                {/*<List dense={false}>*/}
                    {fillHealthChecks(healthChecks)}
                {/*</List>*/}
            </Grid>
        )
    }

    const scanResultComponent = (scanResults: ScanResult[]): JSX.Element => {

        //console.log('scanResultComponent(): ' + JSON.stringify(healthChecks));

        return (
            <Grid container spacing={0}>
                <Grid key={1} item xs={12}>
                    <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                        Scan Results
                    </Typography>
                </Grid>
                <Grid key={2} item xs={12}>
                    <Divider/>
                </Grid>
                {fillScanResults(scanResults)}
            </Grid>
        )
    }

    const resultsSummaryComponent = (resSum: resultsSummary): JSX.Element => {

        //console.log('resultsSummaryComponent(): ' + JSON.stringify(resSum));

        return (
            <Grid container spacing={0}>
                <Grid key={1} item xs={12}>
                    <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                        Results Summary
                    </Typography>
                </Grid>
                <Grid key={2} item xs={12}>
                    <Divider/>
                </Grid>
                <Grid key={3} item xs={12}>
                    <AnimationOnTextChange value={resSum && (resSum.ClearResults
                        + resSum.ExplosivesResults + resSum.EVKResults + resSum.NarcoticsResults
                        + resSum.NVKResults).toString()}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: (width > 1024) ? 10 : 5,
                                marginBottom: (width > 1024) ? 10 : 5,
                            }}>

                                <Typography variant="h6" component="div"  >
                                    Total
                                </Typography>
                                <Typography variant="body1" component="div"  >
                                    {resSum && (resSum.ClearResults
                                        + resSum.ExplosivesResults + resSum.EVKResults + resSum.NarcoticsResults
                                        + resSum.NVKResults)}
                                </Typography>

                            </div>
                        </div>
                    </AnimationOnTextChange>
                    <Divider/>
                </Grid>

                <Grid key={4} item xs={12}>
                    <AnimationOnTextChange value={resSum && resSum.ClearResults.toString()}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: (width > 1024) ? 10 : 5,
                                marginBottom: (width > 1024) ? 10 : 5,
                            }}>

                                <Typography variant="h6" component="div"  >
                                    Clear
                                </Typography>
                                <Typography variant="body1" component="div"  >
                                    {resSum && resSum.ClearResults}
                                </Typography>

                            </div>
                        </div>
                    </AnimationOnTextChange>
                    <Divider/>
                </Grid>

                <Grid key={5} item xs={12}>
                    <AnimationOnTextChange value={resSum && resSum.ExplosivesResults.toString()}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: (width > 1024) ? 10 : 5,
                                marginBottom: (width > 1024) ? 10 : 5,
                            }}>

                                <Typography variant="h6" component="div"  >
                                    Explosives
                                </Typography>
                                <Typography variant="body1" component="div"  >
                                    {resSum && resSum.ExplosivesResults}
                                </Typography>

                            </div>
                        </div>
                    </AnimationOnTextChange>
                    <Divider/>
                </Grid>

                <Grid key={6} item xs={12}>
                    <AnimationOnTextChange value={resSum && resSum.EVKResults.toString()}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: (width > 1024) ? 10 : 5,
                                marginBottom: (width > 1024) ? 10 : 5,
                            }}>

                                <Typography variant="h6" component="div"  >
                                    EVK
                                </Typography>
                                <Typography variant="body1" component="div"  >
                                    {resSum && resSum.EVKResults}
                                </Typography>

                            </div>
                        </div>
                    </AnimationOnTextChange>
                    <Divider/>
                </Grid>

                <Grid key={7} item xs={12}>
                    <AnimationOnTextChange value={resSum && resSum.NarcoticsResults.toString()}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: (width > 1024) ? 10 : 5,
                                marginBottom: (width > 1024) ? 10 : 5,
                            }}>

                                <Typography variant="h6" component="div"  >
                                    Narcotics
                                </Typography>
                                <Typography variant="body1" component="div"  >
                                    {resSum && resSum.NarcoticsResults}
                                </Typography>

                            </div>
                        </div>
                    </AnimationOnTextChange>
                    <Divider/>
                </Grid>

                <Grid key={8} item xs={12}>
                    <AnimationOnTextChange value={resSum && resSum.NVKResults.toString()}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: (width > 1024) ? 10 : 5,
                                marginBottom: (width > 1024) ? 10 : 5,
                            }}>

                                <Typography variant="h6" component="div"  >
                                    NVK
                                </Typography>
                                <Typography variant="body1" component="div"  >
                                    {resSum && resSum.NVKResults}
                                </Typography>

                            </div>
                        </div>
                    </AnimationOnTextChange>
                    <Divider/>
                </Grid>
            </Grid>
        )
    }

    const genInfoComponent = (devGenInfo: deviceGenInfo): JSX.Element => {

        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                        General Information
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Detector S/N
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        {devGenInfo.SerialNumber}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Current Mode
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        {devGenInfo.CurrentModeId}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Internet IP
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        {devGenInfo.InternetIP}
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Software Version
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        {devGenInfo.SoftwareVersion}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Firmware Version
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        {devGenInfo.FirmwareVersion}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Explosive Algorithm Version
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        {devGenInfo.ExplosiveAlgorithmVersion}
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Narcotic Algorithm Version
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        {devGenInfo.NarcoticAlgorithmVersion}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Telit Modem Version
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        {devGenInfo.TelitModemVersion}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="div" className={classes.dev_field_title2}>
                        Operator ID
                    </Typography>
                    <Typography variant="body2" component="div" className={classes.dev_field_value}>
                        {devGenInfo.OperatorID}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    const ToggleComponent = (): JSX.Element => {

        let icon = <></>;

        if((selectedScanResult === undefined) || !selectedScanResult?.resdata) {
            //setToggleState(ToggleGraph.Map);
        }else{
            if(toggleState === ToggleGraph.Map){
                icon = <AutoGraphIcon className={classes.toggleIcon} onClick={onToggleGraph} />
            }else{
                icon = <LocationOnIcon className={classes.toggleIcon} onClick={onToggleGraph} />
            }
        }

        return (icon);
    }

    const onToggleGraph = () => {
        setToggleState((prevState) => {
            if(prevState === ToggleGraph.Map) {
                localStorage.setItem('graphState', 'Graph');
                //console.log("setItem: Graph");
                return ToggleGraph.Graph;
            }else {
                localStorage.setItem('graphState', 'Map');
                //console.log("setItem: Map");
                return ToggleGraph.Map;
            }
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={1} >
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems='stretch'>
                            <Grid item xs={7} >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Grid container spacing={0}>
                                                <Grid item xs={11}>
                                                    <Typography variant="h5" component="div"
                                                                className={classes.dev_field_title2}>
                                                        {devShort && devShort.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {cloudIcon(devGenInfo ? devGenInfo.online : 0, devGenInfo ? devGenInfo.Status : 0)}
                                                </Grid>
                                            </Grid>

                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} columns={21}>
                                            <Grid item xs={6}>
                                                <Item style={{height: '100%'}}>
                                                    {scanResults && scanResultComponent(scanResults)}
                                                </Item>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Item style={{height: '100%'}}>
                                                    {dynInfo && devGenInfo && uptimeComponent(dynInfo, devGenInfo)}
                                                </Item>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Item style={{height: '100%'}}>
                                                    {resultsSummary && resultsSummaryComponent(resultsSummary)}
                                                </Item>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Item style={{height: '100%'}}>
                                                    {healthInfo && healthChecksComponent(healthInfo)}
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Item style={{height: '100%'}}>
                                    <ToggleComponent/>
                                    { (selectedScanResult === undefined) || !selectedScanResult?.resdata || (toggleState === ToggleGraph.Map) ?
                                        <GoogleMap devices={mapDevs} height='100%'/>
                                        :
                                        (selectedScanResult !== undefined) ?
                                            <ScanResultCardChart scanResult={selectedScanResult}/>
                                            :
                                            <></>

                                    }
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems='stretch'>
                            <Grid item xs={5}>
                                <Item style={{height: '100%'}}>
                                    {dynInfo && dynInfoComponent(dynInfo)}
                                </Item>
                            </Grid>

                            <Grid item xs={5}>
                                <Item style={{height: '100%'}}>
                                    {devGenInfo && genInfoComponent(devGenInfo)}
                                </Item>
                            </Grid>
                            <Grid item xs={2}>
                                <Item style={{height: '100%'}}>
                                    <UptimeGraph etd_id={etd_id}/>
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 5,
        marginRight: 5,
    },
    toggleIcon: {
        // position: 'absolute',
        // top: 60,
        // right: 60,
        position: 'relative',
        float: 'right',
        marginTop: -10,
        marginRight: -10,
        cursor: 'pointer',
        // color: theme.palette.primary.main,
    },
    dev_field_title2: {
        marginTop: 40,
        marginLeft: 20,
        display: "flex",
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'left',
    },
    scan_res: {
        marginTop: 20,
        marginLeft: 10,
        marginRight: 10,
    },

    dev_field_value: {
        marginLeft: 20,
        marginTop: 10,
        display: "flex",
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'left',
    },
    // tableBody: {
    //     height: '200px',
    //     overflowX: 'auto',
    // },
    tableRow: {},

}));

