import React, {ReactElement, useEffect, useRef} from "react";
import {makeStyles, useTheme} from "@mui/styles";
import {renderToStaticMarkup} from "react-dom/server";
import {deviceShort} from "../api/EtdApi";

interface AuxProps {
    value: string;
    children: React.ReactNode;
}

export const AnimationOnTextChange = ({value, children}: AuxProps): JSX.Element => {

    const classes = useStyles();

    const theme = useTheme();

    const [animate, setAnimate] = React.useState <boolean | undefined>(undefined);

    //console.log(id);

    //let timer: React.MutableRefObject<number> = useRef(0);

    useEffect(() => {
        if (animate !== undefined) {
            setAnimate(true);
        }
        //clearTimeout(timer.current);
        const timer = setTimeout(() => setAnimate(false), 1000);
        return () => clearTimeout(timer);

    }, [value]);

    // useEffect(() => {
    //     setInterval(() => setAnimate(true), 3000);
    // }, []);

    //console.log(children && renderToStaticMarkup(children as React.ReactElement<any, string | React.JSXElementConstructor<any>>));

    //console.log(animate);

    if (animate) {
        return (<div className={classes.animatedItem}>
            {children}
        </div>);
    } else {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                {children}
            </div>
        );
    }
}

const useStyles = makeStyles(theme => ({
    animatedItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        animation: `$myEffect 1500ms cubic-bezier(0.4, 0, 0.2, 1)`
    },

    "@keyframes myEffect": {
        // "0%": {
        //     backgroundColor: "#ffffff",
        //     transform: "scale(1.0) ",
        // },
        // "8%": {
        //     backgroundColor: "#ffdddd",
        //     transform: "scale(1.1)",
        // },
        // "16%": {
        //     backgroundColor: "#ffdddd",
        //     transform: "scale(1.0)",
        // },
        // "24%": {
        //     backgroundColor: "#ffdddd",
        //     transform: "scale(1.1)",
        // },
        // "32%": {
        //     backgroundColor: "#ffdddd",
        //     transform: "scale(1.0)",
        // },
        // "100%": {
        //     backgroundColor: "#ffffff",
        //     transform: "scale(1.0)",
        // },

        "0%": {
            backgroundColor: 'none',
            color: 'none',
            fontWeight: 'none',
        },
        "20%": {
            backgroundColor: "#ffdddd",
            color: 'red',
            fontWeight: 'bold',
            // transform: 'rotate(180deg)',
        },
        "100%": {
            backgroundColor: 'none',
            color: 'none',
            fontWeight: 'none',
        },
    },
}));

