import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {CssBaseline} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Loading} from "./components/Loading";
import {mainThemeLight} from "./utils/mainTheme";

const root = ReactDOM.createRoot(document.getElementById('root'));


const theme = createTheme({
    palette: {
        background: {
            default: "#FAFAFC",
        },
    },
    shadows: [
        "0 0 1px rgb(0 40 117 / 8%)",
        "0 0 2px rgb(0 40 117 / 8%)",
        "0 0 3px rgb(0 40 117 / 8%)",
        "0 0 4px rgb(0 40 117 / 8%)",
        "0 0 5px rgb(0 40 117 / 8%)",
        "0 0 6px rgb(0 40 117 / 8%)",
        "0 0 7px rgb(0 40 117 / 8%)",
        "0 0 8px rgb(0 40 117 / 8%)",
        "0 0 9px rgb(0 40 117 / 8%)",
        "0 0 10px rgb(0 40 117 / 8%)",
        "0 0 11px rgb(0 40 117 / 8%)",
        "0 0 12px rgb(0 40 117 / 8%)",
        "0 0 13px rgb(0 40 117 / 8%)",
        "0 0 14px rgb(0 40 117 / 8%)",
        "0 0 15px rgb(0 40 117 / 8%)",
        "0 0 16px rgb(0 40 117 / 8%)",
        "0 0 17px rgb(0 40 117 / 8%)",
        "0 0 18px rgb(0 40 117 / 8%)",
        "0 0 19px rgb(0 40 117 / 8%)",
        "0 0 20px rgb(0 40 117 / 8%)",
        "0 0 21px rgb(0 40 117 / 8%)",
        "0 0 22px rgb(0 40 117 / 8%)",
        "0 0 23px rgb(0 40 117 / 8%)",
        "0 0 24px rgb(0 40 117 / 8%)",
        "0 0 25px rgb(0 40 117 / 8%)",
    ],
    overrides: {
        MuiInputBase: {
            input: {
                '&:-webkit-autofill': {
                    transitionDelay: '99999s',
                    transitionProperty: 'background-color, color',
                },
            },
        },
    },
});

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <React.Suspense fallback={<Loading/>}>
                <App />
            </React.Suspense>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
