import axios from "axios";
import {host} from "./api";
import React from "react";
import {getAllSites} from "./DevicesApi";

export interface devAlarm {
    id: number,
    etd_id: number,
    SerialNumber: string,
    DateTime: number,
    Type: string,
    Identification: string,
    AlarmResolution: string,
    AlarmCount: number,
    Coordinates: string,
    OperatorID: string,
    ItemPhoto: string,
    ItemVideo: string,
}

export interface devAlarmsCount {
    alarm_count: number,
}

export const getDevAlarmsCount = async (etd_id: number): Promise<devAlarmsCount | undefined> => {

    try {
        const response = await axios.get(`${host}/alarms/etd_alarms_cnt/` + etd_id);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getDevAlarmsCount() error: ' + e);
        return undefined;
    }
};

export const getDevAlarms = async (etd_id: number, page: number, limit: number): Promise<devAlarm[] | undefined> => {
    try {
        const response = await axios.get(`${host}/alarms/etd_alarms/` + etd_id + '?page=' + page + '&limit=' + limit);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getDevAlarms() error: ' + e);
        return undefined;
    }
};

// export const getDevAlarmPhoto = async (filename: string): Promise<any> => {
//     try {
//         // const response = await axios.get(`${host}/alarms/etd_alarm_photo/` + filename);
//         // //console.log(response.data);
//         // const blob = await response.blob();
//         // return response.data;
//
//         //console.log(`${host}/alarms/etd_alarm_photo/` + filename);
//
//         //console.log(Cookies.get("access"));
//
//         // const response = await fetch(`${host}/alarms/etd_alarm_photo/` + filename, {
//         //     method: 'GET', // *GET, POST, PUT, DELETE, etc.
//         //     mode: 'cors', // no-cors, *cors, same-origin
//         //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//         //     headers: {
//         //         'Content-Type': 'image/jpeg',
//         //     },
//         //
//         // })
//
//         const response = await axios.get(`${host}/alarms/etd_alarm_photo/` + filename);
//
//         //console.log(response);
//         //const blob = await response.blob();
//         const blob = new Blob([response.data]);
//         console.log(blob);
//         //const file = new File( response.data, filename );
//         return [URL.createObjectURL(blob)];
//     } catch (e) {
//         console.log('getDevAlarmPhoto() error: ' + e);
//         return [null, e];
//     }
// };

export const useDevAlarmsCountLive = (etd_id: number | undefined, interval: number): number | undefined => {
    const [etdAlarmsCount, setEtdAlarmsCount] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {

        let shouldUpdate = true;

        function fetchAlarmsCount() {
            if(etd_id) {
                getDevAlarmsCount(etd_id)
                    .then((respAlarmsCount) => {
                        if (shouldUpdate) {
                            setEtdAlarmsCount(respAlarmsCount?.alarm_count);
                            //console.log(respSites);
                            setTimeout(fetchAlarmsCount, interval);
                        }
                    })
                    .catch(() => {
                        if (shouldUpdate) {
                            setTimeout(fetchAlarmsCount, interval);
                        }
                    });
            }else if (shouldUpdate) {
                setTimeout(fetchAlarmsCount, interval);
            }
        }

        fetchAlarmsCount();

        return () => {
            shouldUpdate = false;
        };
    }, [etd_id, interval]);

    return etdAlarmsCount ? etdAlarmsCount : undefined;
};

export const useDevAlarmsLive = (etd_id: number | undefined, page: number, limit: number, interval: number): devAlarm[] | undefined => {

    const [etdAlarms, setEtdAlarms] = React.useState<devAlarm[] | undefined>(undefined);

    const [etdAlarmsCnt, setEtdAlarmsCnt] = React.useState<number>(0);

    React.useEffect(() => {

        let shouldUpdate = true;

        function fetchEtdAlarms() {
            if(etd_id) {
                getDevAlarms(etd_id, page, limit)
                    .then((respEtdAlarms) => {
                        if (shouldUpdate) {
                            if(respEtdAlarms && respEtdAlarms.length != etdAlarmsCnt){
                                setEtdAlarms(respEtdAlarms);
                                setEtdAlarmsCnt(respEtdAlarms.length);
                            }

                            //console.log(respSites);
                            setTimeout(fetchEtdAlarms, interval);
                        }
                    })
                    .catch(() => {
                        if (shouldUpdate) {
                            setTimeout(fetchEtdAlarms, interval);
                        }
                    });
            }else if (shouldUpdate) {
                setTimeout(fetchEtdAlarms, interval);
            }
        }

        fetchEtdAlarms();

        return () => {
            shouldUpdate = false;
        };
    }, [etd_id, interval, etdAlarmsCnt]);

    return etdAlarms ? etdAlarms : undefined;
};
