import React from 'react'
import {makeStyles} from "@mui/styles";
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {getDevShort} from "../api/EtdApi";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem, Stack,
    TextField
} from "@mui/material";
import {
    ClientInfo,
    delSite, DelSiteInfo, editClient, editSite,
    EditSiteInfo, getClients,
    getSites, getUsers,
    registerSite, RegSiteInfo,
    Response,
    SiteInfo, SiteUserInfo
} from "../api/AdminApi";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import MenuIcon from "@mui/icons-material/Menu";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Button from "@mui/material/Button";


export type AdminDashboardProps = {
    titleUpdateHandler: (title: string) => void;
};

const Item = styled(Paper)(({ theme } ) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AddButton = styled(IconButton)(({theme}) =>({
    boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    textTransform: 'none',
    fontSize: 16,
    border: 'none',
    //backgroundColor: '#0063cc',
    position: 'fixed',
    bottom: 30,
    right: 30,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    '&:hover': {
        backgroundColor: '#0049a9',
        boxShadow: '0px 2px 15px rgba(0,0,0,0.6)',
    },
    '&:active': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
    '&:focus': {
        boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
    },
}));

const EditType = {
    none: 0,
    add: 1,
    edit: 2,
    remove: 3,
}

export const AdminSites = ( {titleUpdateHandler}: AdminDashboardProps ) : JSX.Element => {

    const classes = useStyles();

    const theme = useTheme();

    const [refresh, setRefresh] = React.useState (0);

    const [sites, setSites] = React.useState <SiteInfo[] | undefined>(undefined);

    const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
    const [menuSite, setMenuSite] = React.useState<SiteInfo | undefined>(undefined);

    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openResultDialog, setOpenResultDialog] = React.useState(false);
    const [openDelConfirmDialog, setOpenDelConfirmDialog] = React.useState(false);

    const [reqResponse, setReqResponse] = React.useState <Response | undefined> (undefined);

    const [editType, setEditType] = React.useState(0);

    const [siteName, setSiteName] = React.useState("");
    const [siteID, setSiteID] = React.useState (0);
    const [siteClientID, setSiteClientID] = React.useState (0);
    const [siteUsers, setSiteUsers] = React.useState <SiteUserInfo[]> ([]);
    const [siteUsersDef, setSiteUsersDef] = React.useState <SiteUserInfo[]> ([]);

    const handleSiteNameChange = React.useCallback((e: any) => setSiteName(e.target.value), [setSiteName]);
    const handleClientChange = React.useCallback((e: any) => setSiteClientID(e.target.value), [setSiteClientID]);

    const validSiteName = (siteName !== "");

    const open = Boolean(anchorEl);

    const [clients, setClients] = React.useState <ClientInfo[] >([]);

    const [users, setUsers] = React.useState <SiteUserInfo[] >([]);

    //console.log('users: ' + JSON.stringify(users));

    React.useEffect(() => {
        titleUpdateHandler("Manage Sites");
    }, [titleUpdateHandler]);

    React.useEffect(() => {
        getSites()
            .then((sites) => {
                setSites(sites);
                //console.log(JSON.stringify(sites));
            });
    }, [refresh]);

    React.useEffect(() => {
        getClients()
            .then((clients) => {
                setClients(clients);
            });
    }, [refresh]);

    React.useEffect(() => {
        getUsers()
            .then((users) => {
                if(users){
                    let allUsers: SiteUserInfo[] = [];
                    users.map((user) => {
                        allUsers.push({user_id: user.id, user_name: user.username});
                    });
                    setUsers(allUsers);
                }
            });
    }, [refresh]);

    const handleMenuOpen = (event:  React.MouseEvent<SVGSVGElement>, site: SiteInfo) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setMenuSite(site);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditSite = () => {
        handleMenuClose();
        //console.log(menuSite);
        if(menuSite) {
            setSiteID(menuSite.id);
            setSiteName(menuSite.site_name);
            setSiteClientID(menuSite.client_id);
            setSiteUsersDef(menuSite.users);
            setSiteUsers(menuSite.users);
        }

        setEditType(EditType.edit);
        setOpenEditDialog(true);
    }

    const handleDeleteSite = () => {
        handleMenuClose();
        if(menuSite) {
            setEditType(EditType.remove);
            setOpenDelConfirmDialog(true);
        }
    }

    const handleAddSite = () => {
        setSiteName("");
        setSiteUsersDef([]);
        setSiteUsers([]);
        setEditType(EditType.add);
        setOpenEditDialog(true);
    }

    const handleEditDialogClose = () => {
        setOpenEditDialog(false);
    }

    const handleResultDialogClose = () => {
        setOpenResultDialog(false);
        setRefresh(refresh + 1);
    }

    const handleEditDialogSubmit = () => {

        setOpenEditDialog(false);

        if(editType === EditType.add){
            let regSiteInfo: RegSiteInfo = {
                site_name: siteName,
                client_id: siteClientID,
                users: siteUsers,
            }

            registerSite(regSiteInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                });
        }else if(editType === EditType.edit){
            let editSiteInfo: EditSiteInfo = {
                id: siteID,
                site_name: siteName,
                client_id: siteClientID,
                users: siteUsers,
            }
            editSite(editSiteInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }
    }

    const handleDelConfirmDialogClose = () => {
        setOpenDelConfirmDialog(false);
    }

    const handleDelConfirmDialogSubmit = () => {
        setOpenDelConfirmDialog(false);

        if(menuSite) {
            let delSiteInfo: DelSiteInfo = {
                id: menuSite.id,
            }

            delSite(delSiteInfo)
                .then((response) => {
                    setReqResponse(response);
                    setOpenResultDialog(true);
                })
        }
    }

    const fillUsers = (site: SiteInfo): string => {
        let outStr = "";
        site.users.map((user) => {
            if(user.user_name) {
                if (outStr !== "") {
                    outStr = outStr + ", ";
                }
                outStr = outStr + user.user_name;
            }
        });
        return outStr;
    }

    const handleSiteUsersChange = (event: React.SyntheticEvent, value: SiteUserInfo[]) => {
        //console.log('handleSiteUsersChange(): value: ' + JSON.stringify(value));
        setSiteUsers(value);
    }


    return(
        <Box sx={{ flexGrow: 1 }} >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">ID</StyledTableCell >
                            <StyledTableCell align="left">Site Name</StyledTableCell >
                            <StyledTableCell align="left">Client Name</StyledTableCell >
                            <StyledTableCell align="left">Users</StyledTableCell >
                            <StyledTableCell align="right">Actions</StyledTableCell >
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {sites && sites.map((site) => {
                            if (site) {

                                return (
                                    <>
                                        <StyledTableRow key={site.id} className={classes.tableRow}>
                                            <StyledTableCell align="left" component="th" scope="row">
                                                {site.id}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{site.site_name}</StyledTableCell>
                                            <StyledTableCell align="left">{site.client_name}</StyledTableCell>
                                            <StyledTableCell align="left">{fillUsers(site)}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <MenuIcon style={{ color: theme.palette.primary.main, cursor: 'pointer'}} onClick={(event)=>{handleMenuOpen(event, site)}}/>
                                            </StyledTableCell>

                                        </StyledTableRow>

                                    </>
                                )
                            }else{
                                return null;
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleEditSite}>Edit Site</MenuItem>
                <MenuItem onClick={handleDeleteSite}>Delete Site</MenuItem>
            </Menu>
            <AddButton
                size="large"
                onClick={handleAddSite}
            >
                <SpeedDialIcon />
            </AddButton>
            <Dialog
                open={openEditDialog}
                onClose={handleEditDialogClose}
            >
                <DialogTitle>{editType === EditType.add ? "Add Site" : "Edit Site: " + siteName}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ width: 500 }}>

                        <TextField
                            error={!validSiteName}
                            autoFocus
                            margin="dense"
                            id="username"
                            label={validSiteName ? "Site Name" : "Error"}
                            helperText={validSiteName ? undefined : "Site name can not be empty"}
                            type="text"
                            variant="outlined"
                            value={siteName}
                            onChange={handleSiteNameChange}
                        />

                        <TextField
                            margin="dense"
                            id="client_name"
                            select
                            label="Client"
                            variant="outlined"
                            value={siteClientID}
                            onChange={handleClientChange}

                        >
                            {clients.map((client) => {
                                return (
                                    <MenuItem key={client.id} value={client.id} selected={client.id === siteClientID}>
                                        {client.client_name}
                                    </MenuItem>
                                );
                            })}

                        </TextField>

                        <Autocomplete
                            multiple
                            id="users-on-site"
                            options={users}
                            getOptionLabel={(option) => option.user_name}
                            isOptionEqualToValue={(option: SiteUserInfo, value: SiteUserInfo) => option.user_name === value.user_name}
                            defaultValue={siteUsersDef}
                            filterSelectedOptions
                            ChipProps={{color: "primary"}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Users on Site"
                                    placeholder="Add User"
                                />
                            )}
                            onChange={handleSiteUsersChange}
                        />

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose}>Cancel</Button>
                    <Button
                        onClick={handleEditDialogSubmit}
                        disabled={!validSiteName}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openResultDialog}
                onClose={handleResultDialogClose}
            >
                <DialogTitle>{reqResponse?.success ? "Success" : "Error" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {(reqResponse && !reqResponse.success) ? reqResponse.error :
                            editType === EditType.add ? "New Site has been added successfully" :
                                editType === EditType.remove ? "Site successfully deleted" :
                                    editType === EditType.edit ? "Site successfully updated" :
                                        ""}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResultDialogClose}>OK</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelConfirmDialog}
                onClose={handleDelConfirmDialogClose}
            >
                <DialogTitle>{"Confirm Site Deletion" }</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog_content}>
                        {"Please confirm deletion of site '" + menuSite?.site_name + "'"}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelConfirmDialogClose}>Cancel</Button>
                    <Button onClick={handleDelConfirmDialogSubmit}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    tableBody: {
        height: '200px',
        overflowX: 'auto',
    },
    tableRow: {
        //cursor: 'pointer',
    },
    dialog_content: {
        display: "flex",
        flexDirection: "column",
        width: "500px",
    },

}));