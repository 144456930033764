import React from "react";

import {FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import {DevicesDashboardProps} from "../pages/DeviceDashboard";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import Grid from "@mui/material/Grid";
import {useUptimeListLive, useUptimeStatLive} from "../api/EtdApi";
import Divider from "@mui/material/Divider";

import {UptimeLiveChart} from "./UptimeLiveChart";
import {UptimeStatChart} from "./UptimeStatChart";

const UPTIME_UPDATE_INTERVAL = 30000;       // 30 sec

export type UptimeGraphProps = {
    etd_id: number;
};


export const UptimeGraph = ( {etd_id}: UptimeGraphProps): JSX.Element => {

    const classes = useStyles();

    //const uptimePeriod = 86400 * 20;         // 1 day

    const [uptimePeriod, setUptimePeriod] = React.useState (86400);
    const [chartType, setChartType] = React.useState ("Summary");

    let uptimeList = useUptimeListLive(etd_id, uptimePeriod, UPTIME_UPDATE_INTERVAL);
    //console.log('uptimeList: ' + JSON.stringify(uptimeList));

    let uptimeStat = useUptimeStatLive(etd_id, uptimePeriod, UPTIME_UPDATE_INTERVAL);
    //console.log('uptimeStat: ' + JSON.stringify(uptimeStat));


    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography variant="h5" component="div" className={classes.dev_field_title2}>
                    Uptime Graph
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>


            {chartType === "Timeline" ?
                <>
                    <Grid item xs={12} >
                        <div className={classes.chart} style={{marginTop: 40,}}>
                            <UptimeLiveChart uptimeList={uptimeList}/>
                        </div>
                    </Grid>
                </>
                :
                <>
                    {/*<Grid item xs={1}/>*/}
                    <Grid item xs={12}>
                        <div className={classes.chart}>
                            <UptimeStatChart uptimeStat={uptimeStat}/>
                        </div>
                    </Grid>
                    {/*<Grid item xs={1}/>*/}
                </>
            }
            <Grid item xs={12}>
                <div className={classes.control}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label" shrink={true}>Period</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={uptimePeriod.toString()}
                            label="Period"
                            onChange={(event: SelectChangeEvent) => {setUptimePeriod(Number(event.target.value))}}
                        >
                            <MenuItem value={86400}>1 Day</MenuItem>
                            <MenuItem value={86400 * 7}>1 Week</MenuItem>
                            <MenuItem value={86400 * 30}>30 Days</MenuItem>
                            <MenuItem value={86400 * 365}>1 Year</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label" shrink={true}>Chart Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={chartType}
                            label="Chart Type"
                            onChange={(event: SelectChangeEvent) => {setChartType(event.target.value)}}
                        >
                            <MenuItem value="Summary">Summary</MenuItem>
                            <MenuItem value="Timeline">Timeline</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    control: {
        marginTop: 10,
        display: "flex",
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    chart: {
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    dev_field_title2: {
        marginTop: 40,
        marginLeft: 20,
        display: "flex",
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'left',
    },

}));