import React from 'react';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {makeStyles} from "@mui/styles";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {Dayjs} from "dayjs";

export type DateTimeRange = {
    from: Dayjs,
    to: Dayjs,
};

export type DateTimeRangePickerProps = {
    minDate: any,
    maxDate: any,
    defaultRange: DateTimeRange,
    onChange: (range: DateTimeRange) => void,
    disabled: boolean,
};

export const DateTimeRangePicker = (
    {minDate, maxDate, defaultRange, onChange, disabled} : DateTimeRangePickerProps
) => {
    const classes = useStyles();

    const [valueFrom, setValueFrom] = React.useState<Dayjs >(defaultRange.from);
    const [valueTo, setValueTo] = React.useState<Dayjs >(defaultRange.to);

    const fromChangeHandler = (dayFrom: Dayjs  | null) => {
        if(dayFrom) {
            let newTo = valueTo;
            if(dayFrom.isAfter(valueTo)){
                newTo = dayFrom;
                setValueTo(dayFrom);
            }
            setValueFrom(dayFrom);
            onChange({from: dayFrom, to: newTo});
        }
    };

    const toChangeHandler = (dayTo: Dayjs  | null) => {
        if(dayTo) {
            let newFrom = valueFrom;
            if(!dayTo.isAfter(valueFrom)){
                newFrom = dayTo;
                setValueFrom(dayTo);
            }
            setValueTo(dayTo);
            onChange({from: newFrom, to: dayTo});
        }
    };

    return (
        <div className={classes.root}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label="From"
                    disabled={disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    value={defaultRange.from}
                    onChange={fromChangeHandler}/>
                <ChevronRightRoundedIcon fontSize="large"/>
                <DateTimePicker
                    label="To"
                    //defaultValue={dayjs(value.to.toString())}
                    disabled={disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    value={defaultRange.to}
                    onChange={toChangeHandler}
                />
            </LocalizationProvider>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
}));
