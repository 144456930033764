import React from "react";

import {host} from "./api";

import axios from "axios";
import {useAsyncError} from "../hooks/useAsyncError";


export const useAuth = (authenticate, logout) => {
    const cast = useAsyncError();

    React.useEffect(() => {
        let shouldUpdate = true;

        axios.get(`${host}/auth/login`)
            .then(response => {
                if (shouldUpdate) {
                    authenticate(response.data);
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    cast(error);
                }
            });

        return () => {
            shouldUpdate = false;
        }
    }, [authenticate, logout, cast]);
};

export const logout = () => {
    return axios.get(`${host}/logout`);
};

export const login = (username, password, rememberMe) => {
    return axios.post(`${host}/auth/login`, {
            username: username,
            password: password,
            rememberMe: rememberMe,
    });
};

export const updateUser = (username, role) => {
    return axios.put(`${host}/users/update`, {username: username, role: role});
};
export const deleteUser = (username) => {
    return axios.delete(`${host}/users/remove`, {
        data: {username: username}});
};
export const registerUser = (username, password, role) => {
    return axios.post(`${host}/users/register`, {username: username, password: password, role: role});
};