import React, {useContext, useEffect} from 'react'
import {makeStyles} from "@mui/styles";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMatch} from "react-router-dom";
import {styled, useTheme, createTheme, ThemeProvider, keyframes} from "@mui/material/styles";
import {DeviceDate, DeviceInfo, getEtdDate, getEtdInfo, putEtdDate, putEtdInfo} from "../api/MQTTApi";
import {
    Box,
    Checkbox, CircularProgress,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    MenuItem,
    Stack,
    TextField
} from "@mui/material";
import {usernameRules} from "../utils/validateUsername";
import {password2Rules, passwordRules} from "../utils/validatePassword";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {ETDContext} from "../context/ETDContext";
import {DropBox, DropBoxItem} from "../components/DropBox";
import Button from "@mui/material/Button";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import dayjs, {Dayjs} from "dayjs";



//const ALARMS_COUNT_UPDATE_INTERVAL: number = 30000;      // 30 sec

//const ALARMS_UPDATE_INTERVAL: number = 5000;      // 5 sec

const turnOffDisplayTimeoutItems: DropBoxItem[] = [
    {
        text: '5 minutes',
        value: 1,
    },
    {
        text: '10 minutes',
        value: 2,
    },
    {
        text: '30 minutes',
        value: 3,
    },
    {
        text: '1 hour',
        value: 4,
    },
    {
        text: '2 hours',
        value: 5,
    },
    {
        text: '3 hours',
        value: 6,
    },
    {
        text: '5 hours',
        value: 7,
    },

];

const languageItems: DropBoxItem[] = [
    {text: 'English', value: 'en'},
    {text: 'French', value: 'fr'},
    {text: 'Italian', value: 'it'},
    {text: 'Mandarin', value: 'zc'},
    {text: 'Portuguese', value: 'pt'},
    {text: 'Russian', value: 'ru'},
    {text: 'Spanish', value: 'es'},
    {text: 'Thai', value: 'th'},
    {text: 'Bahasa (Indonesian)', value: 'id'},
    {text: 'Arabic', value: 'ar'},
    {text: 'Turkish', value: 'tr'},
    {text: 'Vietnamese', value: 'vi'},
    {text: 'Dutch', value: 'nl'},
    {text: 'Polish', value: 'pl'},
    {text: 'Taiwan', value: 'zt'},
    {text: 'Japanese', value: 'ja'},
];

const timeZoneItems: DropBoxItem[] = [

    {text: '(UTC -10:00) Pacific/Honolulu', value: '(UTC -10:00) Pacific/Honolulu'},
    {text: '(UTC -09:00) America/Anchorage', value: '(UTC -09:00) America/Anchorage'},
    {text: '(UTC -08:00) America/Los_Angeles', value: '(UTC -08:00) America/Los_Angeles'},
    {text: '(UTC -07:00) America/Denver', value: '(UTC -07:00) America/Denver'},
    {text: '(UTC -06:00) America/Chicago', value: '(UTC -06:00) America/Chicago'},
    {text: '(UTC -05:00) America/New_York', value: '(UTC -05:00) America/New_York'},
    {text: '(UTC -04:00) America/Caracas', value: '(UTC -04:00) America/Caracas'},
    {text: '(UTC -03:00) America/Sao_Paulo', value: '(UTC -03:00) America/Sao_Paulo'},
    {text: '(UTC +00:00) Europe/London', value: '(UTC +00:00) Europe/London'},
    {text: '(UTC +01:00) Europe/Paris', value: '(UTC +01:00) Europe/Paris'},
    {text: '(UTC +02:00) Africa/Cairo', value: '(UTC +02:00) Africa/Cairo'},
    {text: '(UTC +02:00) Asia/ISR', value: '(UTC +02:00) Asia/ISR'},
    {text: '(UTC +03:00) Europe/Moscow', value: '(UTC +03:00) Europe/Moscow'},
    {text: '(UTC +04:00) Asia/Dubai', value: '(UTC +04:00) Asia/Dubai'},
    {text: '(UTC +05:00) Asia/Karachi', value: '(UTC +05:00) Asia/Karachi'},
    {text: '(UTC +05:30) Asia/Kolkata', value: '(UTC +05:30) Asia/Kolkata'},
    {text: '(UTC +06:00) Asia/Dhaka', value: '(UTC +06:00) Asia/Dhaka'},
    {text: '(UTC +07:00) Asia/Bangkok', value: '(UTC +07:00) Asia/Bangkok'},
    {text: '(UTC +08:00) Asia/Hong_Kong', value: '(UTC +08:00) Asia/Hong_Kong'},
    {text: '(UTC +09:00) Asia/Tokyo', value: '(UTC +09:00) Asia/Tokyo'},
    {text: '(UTC +09:30) Australia/Adelaide', value: '(UTC +09:30) Australia/Adelaide'},
    {text: '(UTC +09:30) Australia/Darwin', value: '(UTC +09:30) Australia/Darwin'},
    {text: '(UTC +10:00) Australia/Brisbane', value: '(UTC +10:00) Australia/Brisbane'},
    {text: '(UTC +10:00) Australia/Sydney', value: '(UTC +10:00) Australia/Sydney'},
    {text: '(UTC +11:00) Pacific/Noumea', value: '(UTC +11:00) Pacific/Noumea'},
];

export type DeviceSettingsProps = {
    titleUpdateHandler: (title: string) => void;
};


export const DeviceSettings = ({titleUpdateHandler} : DeviceSettingsProps) : JSX.Element => {

    const classes = useStyles();

    let theme = useTheme();

    theme = createTheme(theme,{
        typography: {
            h5: {
                fontSize: 18,
                color: theme.palette.primary.main,
                marginBottom: 10,
            },
            h6: {
                fontSize: 16,
                color: '#222',
            },
            body1: {
                fontSize: 16,
            },
            body2: {
                fontSize: 16,
                marginBottom: 15,
            },

        },
    });

    const pageMatch = useMatch("/device/:etd_id");
    const etd_id_str = (pageMatch && pageMatch.params.etd_id) || "";

    const etd_id = Number(etd_id_str);

    const [etdInfo, setEtdInfo] = React.useState <DeviceInfo | undefined> (undefined);
    const [etdDate, setEtdDate] = React.useState <DeviceDate | undefined> (undefined);

    const [turnOffDisplayTimeout, setTurnOffDisplayTimeout] = React.useState('');
    const [enableTurnOffDisplay, setEnableTurnOffDisplay] = React.useState(false);
    const [enablePrinter, setEnablePrinter] = React.useState(false);
    const [useLabelPrinter, setUseLabelPrinter] = React.useState(false);
    const [printAlarm, setPrintAlarm] = React.useState(false);
    const [printClear, setPrintClear] = React.useState(false);
    const [timeZone, setTimeZone] = React.useState('');
    const [language, setLanguage] = React.useState('');
    const [audioAlarm, setAudioAlarm] = React.useState(false);
    const [threatIdentification, setThreatIdentification] = React.useState(false);
    const [alarmResolution, setAlarmResolution] = React.useState(false);
    const [enableLightTower, setEnableLightTower] = React.useState(false);
    const [clearLightTowerGreen, setClearLightTowerGreen] = React.useState(false);
    const [alarmLightTowerRed, setAlarmLightTowerRed] = React.useState(false);

    const [dateTime, setDateTime] = React.useState<Dayjs | null>(null);

    const {etdName} = useContext(ETDContext);

    const handleTurnOffDisplayTimeoutChange = React.useCallback((value: any) => setTurnOffDisplayTimeout(value), [setTurnOffDisplayTimeout]);
    const handleEnableTurnOffDisplay = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setEnableTurnOffDisplay(event.target.checked),[setEnableTurnOffDisplay]) ;
    const handleEnablePrinter = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setEnablePrinter(event.target.checked),[setEnablePrinter]) ;
    const handleUseLabelPrinter = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setUseLabelPrinter(event.target.checked),[setUseLabelPrinter]) ;
    const handlePrintAlarm = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setPrintAlarm(event.target.checked),[setPrintAlarm]) ;
    const handlePrintClear = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setPrintClear(event.target.checked),[setPrintClear]) ;
    const handleLanguageChange = React.useCallback((value: any) => setLanguage(value), [setLanguage]);
    const handleTimeZoneChange = React.useCallback((value: any) => setTimeZone(value), [setTimeZone]);

    const handleAudioAlarm = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setAudioAlarm(event.target.checked),[setAudioAlarm]) ;
    const handleThreatIdentification = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setThreatIdentification(event.target.checked),[setThreatIdentification]) ;
    const handleAlarmResolution = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setAlarmResolution(event.target.checked),[setAlarmResolution]) ;
    const handleEnableLightTower = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setEnableLightTower(event.target.checked),[setEnableLightTower]) ;
    const handleClearLightTowerGreen = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setClearLightTowerGreen(event.target.checked),[setClearLightTowerGreen]) ;
    const handleAlarmLightTowerRed = React.useCallback( (event: React.ChangeEvent<HTMLInputElement>) => setAlarmLightTowerRed(event.target.checked),[setAlarmLightTowerRed]) ;

    const handleDateTimeChange = React.useCallback((value: Dayjs | null) => setDateTime(value), [setDateTime]);

    const [waitingForGetSettings, setWaitingForGetSettings] = React.useState(false);
    const [waitingForGetDeviceTime, setWaitingForGetDeviceTime] = React.useState(false);

    React.useEffect(() => {
        titleUpdateHandler(etdName);
    },[etdName]);

    React.useEffect(() => {
        setWaitingForGetSettings(true);
        getEtdInfo(etd_id)
            .then((devInfo) => {
                //console.log(devInfo);
                setWaitingForGetSettings(false);
                if(etdInfo === undefined) {
                    setEtdInfo(devInfo);
                    //console.log('devInfo.TurnOffDisplayTimeout: ' + devInfo.TurnOffDisplayTimeout);
                    setEnableTurnOffDisplay(devInfo.EnableTurnOffDisplay);
                    setTurnOffDisplayTimeout(devInfo.TurnOffDisplayTimeout.toString());
                    setEnablePrinter(devInfo.EnablePrinter);
                    setUseLabelPrinter(devInfo.UseLabelPrinter);
                    setPrintAlarm(devInfo.PrintAlarm);
                    setPrintClear(devInfo.PrintClear);
                    setTimeZone(devInfo.TimeZone);
                    setLanguage(devInfo.Language);
                    setAudioAlarm(devInfo.AudioAlarm);
                    setThreatIdentification(devInfo.ThreatIdentification);
                    setAlarmResolution(devInfo.AlarmResolution);
                    setEnableLightTower(devInfo.EnableLightTower);
                    setClearLightTowerGreen(devInfo.ClearLightTowerGreen);
                    setAlarmLightTowerRed(devInfo.AlarmLightTowerRed);

                }
            })
            .catch((err) => {
                setWaitingForGetSettings(false);
                console.log('getEtdInfo(): error: ' + err);
            })
    }, [etd_id]);

    React.useEffect(() => {
        setWaitingForGetDeviceTime(true);
        getEtdDate(etd_id)
            .then((devDate) => {
                setWaitingForGetDeviceTime(false);
                //console.log(devDate);
                if(etdDate === undefined){
                    setEtdDate(devDate);
                    setDateTime(dayjs.unix(Math.round(devDate.DateTime / 1000)));
                }
            })
            .catch((err) => {
                setWaitingForGetDeviceTime(false);
                console.log('getEtdDate(): error: ' + err);
            })
    }, [etd_id]);

    const onUpdateSettings = () => {
        const devInfo: DeviceInfo = {
            Cnt: 0,
            EnableTurnOffDisplay: enableTurnOffDisplay,
            TurnOffDisplayTimeout: Number.parseInt(turnOffDisplayTimeout),
            EnablePrinter: enablePrinter,
            UseLabelPrinter: useLabelPrinter,
            PrintAlarm: printAlarm,
            PrintClear: printClear,
            TimeZone: timeZone,
            Language: language,
            AudioAlarm: audioAlarm,
            ThreatIdentification: threatIdentification,
            AlarmResolution: alarmResolution,
            EnableLightTower: enableLightTower,
            ClearLightTowerGreen: clearLightTowerGreen,
            AlarmLightTowerRed: alarmLightTowerRed,
        }
        setEtdInfo(devInfo);

        setWaitingForGetSettings(true);
        putEtdInfo(etd_id, devInfo)
            .then((success) => {
                setWaitingForGetSettings(false);
                //console.log('onUpdateSettings(): success: ' + success);
            })
            .catch((err) => {
                setWaitingForGetSettings(false);
                console.log(err);
            });

    }

    const onUpdateTime = () => {
        if(dateTime) {
            setWaitingForGetDeviceTime(true);
            putEtdDate(etd_id, dateTime.unix())
                .then((success) => {
                    setWaitingForGetDeviceTime(false);
                    //console.log('onUpdateTime(): success: ' + success);
                })
                .catch((err) => {
                    setWaitingForGetDeviceTime(false);
                    console.log(err);
                });
        }
    }

    return(
        <ThemeProvider theme={theme}>

            <FormGroup sx={{ width: 800, border: '1px solid', borderColor: theme.palette.primary.main, padding: '20px', margin: '10px', borderRadius: 2 }}>
                {waitingForGetSettings ?
                    <>
                        {/*<Typography variant="h5" component="div" className={classes.dev_field_title2}>*/}
                        {/*    Waiting for getting settings*/}
                        {/*</Typography>*/}
                        <Box sx={{display: 'flex', justifyContent: "center", marginTop: "10px"}}>
                            <CircularProgress/>
                        </Box>
                    </>
                    :
                    <>
                        <Stack direction="row" spacing={2}>
                            <Stack direction="column"
                                   justifyContent="flex-start"
                                   alignItems="center"
                                   spacing={2}>
                                <FormGroup sx={{
                                    width: 350,
                                    border: '1px solid',
                                    borderColor: theme.palette.primary.main,
                                    padding: '20px',
                                    margin: '10px',
                                    borderRadius: 2
                                }}>
                                    <DropBox
                                        id="language"
                                        caption="Language"
                                        value={language}
                                        items={languageItems}
                                        onSelect={handleLanguageChange}
                                    />
                                    <DropBox
                                        id="timezone"
                                        caption="Timezone"
                                        value={timeZone}
                                        items={timeZoneItems}
                                        onSelect={handleTimeZoneChange}
                                        sx={{marginTop: '15px'}}
                                    />
                                </FormGroup>
                                <FormGroup sx={{
                                    width: 350,
                                    border: '1px solid',
                                    borderColor: theme.palette.primary.main,
                                    padding: '20px',
                                    margin: '10px',
                                    borderRadius: 2
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={enableTurnOffDisplay}
                                                onChange={handleEnableTurnOffDisplay}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Enable Turn Off The Display"
                                    />
                                    <DropBox
                                        id="turn_off_display_timeout"
                                        caption="Turn Off Display Timeout"
                                        value={turnOffDisplayTimeout}
                                        items={turnOffDisplayTimeoutItems}
                                        onSelect={handleTurnOffDisplayTimeoutChange}
                                        disabled={!enableTurnOffDisplay}
                                        sx={{marginTop: '10px'}}
                                    />
                                </FormGroup>
                                <FormGroup sx={{
                                    width: 350,
                                    border: '1px solid',
                                    borderColor: theme.palette.primary.main,
                                    padding: '20px',
                                    margin: '10px',
                                    borderRadius: 2
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={audioAlarm}
                                                onChange={handleAudioAlarm}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Enable Audio Alarm"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={threatIdentification}
                                                onChange={handleThreatIdentification}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Enable Threat Identification"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={alarmResolution}
                                                onChange={handleAlarmResolution}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Enable Alarm Resolution"
                                    />
                                </FormGroup>
                            </Stack>
                            <Stack direction="column"
                                   justifyContent="flex-start"
                                   alignItems="center"
                                   spacing={2}>
                                <FormGroup sx={{
                                    width: 350,
                                    border: '1px solid',
                                    borderColor: theme.palette.primary.main,
                                    padding: '20px',
                                    margin: '10px',
                                    borderRadius: 2
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={enablePrinter}
                                                onChange={handleEnablePrinter}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Enable Printer"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={useLabelPrinter}
                                                onChange={handleUseLabelPrinter}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Use Label Printer"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={printAlarm}
                                                onChange={handlePrintAlarm}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Print Alarm"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={printClear}
                                                onChange={handlePrintClear}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Print Clear"
                                    />
                                </FormGroup>
                                <FormGroup sx={{
                                    width: 350,
                                    border: '1px solid',
                                    borderColor: theme.palette.primary.main,
                                    padding: '20px',
                                    margin: '10px',
                                    borderRadius: 2
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={enableLightTower}
                                                onChange={handleEnableLightTower}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Enable Light Tower"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={clearLightTowerGreen}
                                                onChange={handleClearLightTowerGreen}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Enable Light Tower Clear(Green)"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={alarmLightTowerRed}
                                                onChange={handleAlarmLightTowerRed}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />}
                                        label="Enable Light Tower Alarm(Red)"
                                    />
                                </FormGroup>
                            </Stack>
                        </Stack>

                        <div className={classes.updateSettingsBtn}>
                            <Button variant="outlined" size="large" onClick={onUpdateSettings}
                                    disabled={etdInfo === undefined}>Update</Button>
                        </div>
                    </>
                }
            </FormGroup>

            <FormGroup sx={{ width: 800, border: '1px solid', borderColor: theme.palette.primary.main, padding: '20px', margin: '10px', borderRadius: 2 }}>
                {waitingForGetDeviceTime ?
                    <>
                        {/*<Typography variant="h5" component="div" className={classes.dev_field_title2}>*/}
                        {/*    Waiting for getting device time*/}
                        {/*</Typography>*/}
                        <Box sx={{display: 'flex', justifyContent: "center", marginTop: "10px"}}>
                            <CircularProgress/>
                        </Box>
                    </>
                    :
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Device Time"
                                disabled={etdDate === undefined}
                                value={dateTime}
                                onChange={handleDateTimeChange}/>
                        </LocalizationProvider>

                        <div className={classes.updateSettingsBtn}>
                            <Button variant="outlined" size="large" onClick={onUpdateTime}
                                    disabled={etdDate === undefined}>Update</Button>
                        </div>
                    </Stack>
                }
            </FormGroup>

        </ThemeProvider>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 5,
        marginRight: 5,
    },
    updateSettingsBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
    },


}));

