import React, {forwardRef, useImperativeHandle, useRef} from "react";

import {makeStyles} from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import {Button} from "@mui/material";
import {getAllSites, SiteAll, useAllSites, useAllSitesLive} from "../api/DevicesApi";
import CloudQueue from "@mui/icons-material/CloudQueue";
import CloudOff from "@mui/icons-material/CloudOff";
import {styled} from "@mui/material/styles";
import {StaticMenuProps} from "./StaticMenu";

const UPDATE_INTERVAL = 5000;


export type SitesMenuProps = {
    width: number,
    menuOpen: boolean,
    //updateClients: ClientItem[],
    updateClients: (clients: MenuItem[]) => void,
};

type ClientItem = {
    client: string,
    client_id: number,
    sites: [
        {
            site: string,
            site_id: number,
        }
    ]
    open: boolean,
};

type MenuItem = {
    client_id: number | null,
    site_id: number | null,
    all: boolean | null,
}

const StyledList = styled(List)<{ component: React.ElementType }>({
    '& .MuiListItemText-primary': {
        fontSize: 11,
        '@media (min-width:1024px)': {
            fontSize: 16,
        },
    },
    '& .MuiListItemIcon-root': {
        fontSize: 11,
        '@media (min-width:1024px)': {
            fontSize: 16,
        },
    },
});

//export const SitesListMenu = ({menuOpen, updateClients, width}) => {
export const SitesListMenu = forwardRef(({width, menuOpen, updateClients/*, updateItemID*/}: SitesMenuProps, ref) : JSX.Element => {
    const classes = useStyles();

    const [clients, setClients] = React.useState <ClientItem[]> ([]);

    //const [menuItems, setMenuItems] = React.useState([]);

    //const menuItems = useAllSites();
    const [items, setItems] = React.useState <MenuItem[]> ([]);

    const menuItems = useAllSitesLive(UPDATE_INTERVAL);
    //console.log(menuItems);

    const firstOpenRef = useRef<boolean>(true);


    const filterClients = (): MenuItem[] => {
        let filteredClients = [];

        filteredClients = clients.filter((client) => client.open === true);

        filteredClients = filteredClients.map((client) => {
            return {client_id: client.client_id, site_id: null, all: null};
        })

        return filteredClients;
    }

    const handleClientClick = (client_id: number) => {
        //console.log(clientName);
        setClients((prevClients) => {

            return prevClients.map((client) => {
                //console.log('client.client_id: ' + client.client_id);
                if (client_id === client.client_id) {
                    client.open = !client.open;
                }
                return client;
            });
        });
        const clients = filterClients();
        setItems(clients);
        updateClients(clients);

    }

    const handleSiteClick = (/*client_id,*/ site_id: number) => {
        //console.log(clientName + ', ' + siteName);
        //updateClients(filterItems(clientName, siteName));
        const clients = [{client_id: null, site_id: site_id, all: null}];
        setItems(clients);
        updateClients(clients);
    }
    useImperativeHandle(ref, () => ({
        handleSiteClick
    }));

    const handleAllClick = () => {
        //console.log(clientName + ', ' + siteName);
        setClients((prevClients) => {
            if (prevClients) {
                return prevClients.map((client) => {
                    client.open = true;
                    return client;
                });
            } else {
                return [];
            }
        });
        //updateClients(filterItems(null, null));
        const clients = [{client_id: null, site_id: null, all: true}];
        setItems(clients);
        updateClients(clients);
    }

    React.useEffect(() => {
        setClients((prevClients) => {
            return menuItems.map((client, index) => {
                //console.log(client);
                //console.log(prevClients);
                let open = false;
                if (prevClients) {
                    prevClients.forEach((prevClient) => {
                        if (prevClient.client === client.client) {
                            open = prevClient.open;
                        }
                    });
                }

                return {...client, open: open};

            });
        });

    }, [menuItems]);

    React.useEffect(() => {
        if(clients.length > 0 && firstOpenRef.current){
            firstOpenRef.current = false;
            handleAllClick();
        }

    }, [clients]);

    // React.useEffect(() => {
    //     siteClickHandler = handleSiteClick;
    // }, []);


    const clientIcon = (client: ClientItem, items: MenuItem[]) => {
        let enabled = false;
        if (items && items.length > 0) {
            if (items[0].all) {
                enabled = true;
            } else if (items[0].client_id) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].client_id && items[i].client_id === client.client_id) {
                        enabled = true;
                        break;
                    }
                }
            }
        }
        if (enabled) {
            return (<GroupIcon color="primary"/>);
        } else {
            return (<GroupIcon/>);
        }
    }

    const siteIcon = (client_id: number, site: {site: string, site_id: number, }, items: MenuItem[]) => {
        //if (items && items.length > 0 && items[0].site_id && items[0].site_id ===  site.site_id) {

        let enabled = false;
        if (items && items.length > 0) {
            if (items[0].all) {
                enabled = true;
            } else if (items[0].client_id) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].client_id && items[i].client_id === client_id) {
                        enabled = true;
                        break;
                    }
                }
            } else if(items[0].site_id && items[0].site_id ===  site.site_id) {
                enabled = true;
            }
        }
        if (enabled) {
            return (<LocationOnIcon color="primary"/>);
        }else{
            return (<LocationOnIcon/>);
        }
    }

    const fillClients = (clients: ClientItem[]) => {
        return clients && clients.map((client, index) => {
            const clientIndex = index * 1000000;
            //console.log(clientIndex);
            return (
                <div key={index}>
                    <ListItemButton onClick={() => {
                        handleClientClick(client.client_id)
                    }}>
                        <ListItemIcon>
                            {clientIcon(client, items)}
                        </ListItemIcon>
                        <ListItemText primary={client.client}/>
                        {(client.sites && (client.sites.length > 0)) ? (client.open ? <ExpandLess/> :
                            <ExpandMore/>) : null}
                    </ListItemButton>
                    {client.sites && (client.sites.length > 0) && fillSites(client.client_id, client.sites, client.open, clientIndex)}
                </div>
            )
        });
    }

    const fillSites = (client_id: number, sites: [{site: string, site_id: number, }], open: boolean, parentIndex: number) => {
        return sites && sites.map((site, index) => {

            return (
                <Collapse in={open} timeout="auto" unmountOnExit key={parentIndex + index}>
                    <StyledList component="div" disablePadding>
                        <ListItemButton sx={{pl: 4}} onClick={() => {
                            handleSiteClick(/*client_id,*/ site.site_id)
                        }}>
                            <ListItemIcon>
                                {siteIcon(client_id, site, items)}
                            </ListItemIcon>
                            <ListItemText primary={site.site}/>

                        </ListItemButton>
                    </StyledList>
                </Collapse>
            )
        })
    }

    return (
        <Drawer
            id="SiteListMenu"
            variant="persistent"
            open={menuOpen}
            sx={{
                width: width,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: width, boxSizing: 'border-box'},
                zIndex: (theme) => theme.zIndex.appBar - 1
            }}
        >
            <Toolbar variant={(width < 250) ? 'dense' : 'regular'}/>
            <Box sx={{overflow: 'auto'}}>
                <Divider/>
                {/*fillItems(menuItems)*/}

                <StyledList
                    sx={{width: '100%', bgcolor: 'background.paper', paddingRight: 1, paddingLeft: 1}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            <ListItemButton sx={{pl: 4}} onClick={() => {
                                handleAllClick()
                            }}>
                                <ListItemText primary="All Clients"/>
                            </ListItemButton>
                        </ListSubheader>
                    }
                >
                    {clients && fillClients(clients)}
                </StyledList>

            </Box>
        </Drawer>

    );
});

const useStyles = makeStyles(theme => ({}));
