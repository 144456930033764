import React, {useContext, useEffect} from 'react'
import {makeStyles} from "@mui/styles";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMatch} from "react-router-dom";
import {styled, useTheme, createTheme, ThemeProvider, keyframes} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import { useDevAlarmsCountLive, useDevAlarmsLive} from "../api/AlarmsApi";
import Popover from '@mui/material/Popover';
import Box from "@mui/material/Box";
import {GoogleMapOneDev} from "../components/GoogleMap";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VideocamIcon from '@mui/icons-material/Videocam';
import {host} from "../api/api";
// @ts-ignore
import ReactPlayer from 'react-player';
import {ETDContext} from "../context/ETDContext";

//import buffer from "buffer";
//const buffer = require('node:buffer');

//const ALARMS_COUNT_UPDATE_INTERVAL: number = 30000;      // 30 sec

const ALARMS_UPDATE_INTERVAL: number = 5000;      // 5 sec


export type DevicesAlarmsProps = {
    titleUpdateHandler: (title: string) => void;
};

export type LocationIconProps = {
    location: MapLocation;
};

export type PhotoIconProps = {
    photoFileName: string;
};

export type VideoIconProps = {
    videoFileName: string;
};

export type MapLocation = {
    latitude: number;
    longitude: number;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 10,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
    [`&.${tableCellClasses.body}`]: {
        //fontSize: 14,
        fontSize: 9,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export const DeviceAlarms = ({titleUpdateHandler} : DevicesAlarmsProps) : JSX.Element => {

    const classes = useStyles();

    let theme = useTheme();

    theme = createTheme(theme,{
        typography: {
            h5: {
                fontSize: 18,
                color: theme.palette.primary.main,
                marginBottom: 10,
            },
            h6: {
                fontSize: 16,
                color: '#222',
            },
            body1: {
                fontSize: 16,
            },
            body2: {
                fontSize: 16,
                marginBottom: 15,
            },

        },
    });

    const pageMatch = useMatch("/device/:etd_id");
    const etd_id_str = (pageMatch && pageMatch.params.etd_id) || "";
    //setEtdId(Number(etd_id_str));
    const etd_id = Number(etd_id_str);

    const [devAlarmsCnt, setDevAlarmsCnt] = React.useState<number | undefined>(undefined);

    //let {height} = useWindowDimensions();

    //let devAlarmsCount = useDevAlarmsCountLive(etd_id, ALARMS_COUNT_UPDATE_INTERVAL);

    const {etdName} = useContext(ETDContext);

    let devAlarms = useDevAlarmsLive(etd_id, 1, 100, ALARMS_UPDATE_INTERVAL);
    const [animate, setAnimate] = React.useState <boolean | undefined>(undefined);

    React.useEffect(() => {
        titleUpdateHandler(etdName);
    },[etdName]);

    React.useEffect(() => {
        if(devAlarms){
            setDevAlarmsCnt(devAlarms.length);
        }
    }, [devAlarms]);

    useEffect(() => {
        if (animate !== undefined) {
            setAnimate(true);
        }
        //clearTimeout(timer.current);
        const timer = setTimeout(() => setAnimate(false), 1000);
        return () => clearTimeout(timer);

    }, [devAlarms]);

    const dateTimeFormat = (timestamp: number): string => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    }

    const parseCoordinates = (coordinates: string): MapLocation => {
        const lat_lng = coordinates.split(',');

        let latitude: number = 0;
        let longitude: number = 0;

        if(lat_lng && lat_lng.length === 2){
            latitude = Number(lat_lng[0]);
            longitude = Number(lat_lng[1]);
        }
        return {latitude: latitude, longitude: longitude};
    }

    const PhotoIcon = ( { photoFileName }: PhotoIconProps ): JSX.Element  => {

        const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

        const [open, setOpen] = React.useState<boolean >(false);

        //console.log(open);

        //const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        // const [photo, setPhoto] = React.useState(undefined);
        //
        const [url, setURL] = React.useState <string | undefined> (undefined);

        const photoURL: string = `${host}/alarms/etd_alarm_photo/` + photoFileName;

        // console.log(photoURL);

        useEffect(() => {
            if(photoFileName && (url === undefined)){
                setURL(photoFileName);
                // const img = new Image();
                // img.src = photoURL;
                // console.log(photoURL);
            }
        }, [url])

        // useEffect(() => {
        //     async function fetchData() {
        //         // You can await here
        //         if(url) {
        //             const [response, error] = await getDevAlarmPhoto(url)
        //             if (error)
        //                 console.log(error)
        //             else {
        //                 console.log(`got response ${response}`)
        //                 setPhoto(response)
        //             }
        //         }
        //     }
        //     fetchData();
        // }, [url])

        const handlePhotoIconClick = (event: React.MouseEvent<SVGSVGElement>/*, url: string*/) => {
            //console.log(location);

            //console.log(event.currentTarget);

            // if(url) {
            //     const [response, error] = await getDevAlarmPhoto(url)
            //     if (error)
            //         console.log(error)
            //     else {
            //         console.log(`got response ${response}`)
            //         setPhoto(response)
            //     }
            // }

            event.preventDefault();
            if (anchorEl === null) {
                setAnchorEl(event.currentTarget);
                setOpen(true);
            } else {
                setAnchorEl(null);
                setOpen(false);
            }
        }

        const handlePopoverClose = () => {
            setAnchorEl(null);
            setOpen(false);
        };

        let photoComponent : JSX.Element;

        if(url){
            photoComponent = (
                <Box>
                    <CameraAltIcon style={{ color: theme.palette.primary.main, cursor: 'pointer'}} onClick={(event)=>{handlePhotoIconClick(event /*, photoFileName*/)}} />
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <img src={photoURL} width="600" height="400" />
                    </Popover>
                </Box>
            );
        }else{
            photoComponent = (<CameraAltIcon style={{ color: 'grey' }} />);
        }

        return photoComponent;
    }

    const VideoIcon = ( { videoFileName }: VideoIconProps ): JSX.Element  => {

        const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

        const [open, setOpen] = React.useState<boolean >(false);

        const id = open ? 'simple-popover' : undefined;

        const [url, setURL] = React.useState <string | undefined> (undefined);

        const videoURL = `${host}/alarms/etd_alarm_video/` + videoFileName;

        //console.log(videoURL);

        useEffect(() => {
            if(videoFileName && (url === undefined)){
                setURL(videoFileName);
                // const player = new Video();
                // player.src = videoURL;
                // console.log(videoURL);
            }
        }, [url])

        const handleVideoIconClick = (event: React.MouseEvent<SVGSVGElement>) => {

            event.preventDefault()
            if (anchorEl === null) {
                setAnchorEl(event.currentTarget);
                setOpen(true);
            } else {
                setAnchorEl(null);
                setOpen(false);
            }
        }

        const handlePopoverClose = () => {
            setAnchorEl(null);
            setOpen(false);
        };

        let videoComponent : JSX.Element;

        if(url){
            videoComponent = (
                <Box>
                    <VideocamIcon style={{ color: theme.palette.primary.main, cursor: 'pointer'}} onClick={(event)=>{handleVideoIconClick(event /*, photoFileName*/)}} />
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >

                        {/*<div className='player-wrapper'>*/}
                        {/*    <ReactPlayer*/}
                        {/*        className='react-player fixed-bottom'*/}
                        {/*        url='http://localhost:5000/alarms/etd_alarm_video/4.mp4'*/}
                        {/*        width={600}*/}
                        {/*        height={400}*/}
                        {/*        controls = {true}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div>
                            <div>
                                <video width="600" height="400" controls>
                                    <source src={videoURL} type="video/mp4" />
                                </video>
                            </div>
                        </div>

                    </Popover>
                </Box>
            );
        }else{
            videoComponent = (<VideocamIcon style={{ color: 'grey' }} />);
        }

        return videoComponent;
    }

    const LocationIcon = ({location, /*clickHandler*/}: LocationIconProps ): JSX.Element  => {

        const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

        const [open, setOpen] = React.useState<boolean >(false);

        //console.log(open);

        //const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const handleLocationIconClick = (event: React.MouseEvent<SVGSVGElement>, location: MapLocation) => {
            //console.log(location);

            //console.log(event.currentTarget);

            event.preventDefault()
            if (anchorEl === null) {
                setAnchorEl(event.currentTarget);
                setOpen(true);
            } else {
                setAnchorEl(null);
                setOpen(false);
            }
        }

        const handlePopoverClose = () => {
            setAnchorEl(null);
            setOpen(false);
        };

        let locationComponent : JSX.Element;

        if(location.latitude !== 0 && location.longitude !== 0){
            locationComponent = (
                <Box>
                    <LocationOnIcon style={{ color: theme.palette.primary.main, cursor: 'pointer'}} onClick={(event)=>{handleLocationIconClick(event, location)}} />
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{width: 600}}>
                            <GoogleMapOneDev position={location} height={400} />
                        </div>
                    </Popover>
                </Box>
            );
        }else{
            locationComponent = (<LocationOnIcon style={{ color: 'grey' }} />);
        }

        return locationComponent;
    }

    return(
        <ThemeProvider theme={theme}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Operator</StyledTableCell >
                            <StyledTableCell align="left">Date and Time</StyledTableCell >
                            <StyledTableCell align="left">Alarm Count</StyledTableCell >
                            <StyledTableCell align="left">Threat Type</StyledTableCell >
                            <StyledTableCell align="left">Identification</StyledTableCell >
                            <StyledTableCell align="left">Location</StyledTableCell >
                            <StyledTableCell align="left">Photo</StyledTableCell >
                            <StyledTableCell align="left">Video</StyledTableCell >
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {devAlarms && devAlarms.map((devAlarm, index) => {
                            if (devAlarm) {
                                return (
                                    <StyledTableRow key={devAlarm.id}  className={((index === 0) && animate) ? classes.tableRowAnimated : classes.tableRow}>
                                        <StyledTableCell align="left" component="th" scope="row">
                                            {devAlarm.OperatorID}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{dateTimeFormat(devAlarm.DateTime)}</StyledTableCell>
                                        <StyledTableCell align="left">{devAlarm.AlarmCount}</StyledTableCell>
                                        <StyledTableCell align="left">{devAlarm.Type}</StyledTableCell>
                                        <StyledTableCell align="left">{devAlarm.Identification}</StyledTableCell>
                                        <StyledTableCell align="left">
                                            <LocationIcon key={devAlarm.id} location={parseCoordinates(devAlarm.Coordinates)} /*clickHandler={handleLocationIconClick}*/ />
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <PhotoIcon key={devAlarm.id} photoFileName={devAlarm.ItemPhoto} />
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <VideoIcon key={devAlarm.id} videoFileName={devAlarm.ItemVideo} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            }else{
                                return null;
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </ThemeProvider>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 5,
        marginRight: 5,
    },
    dev_field_title2: {
        marginTop: 40,
        marginLeft: 20,
        display: "flex",
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'left',
    },
    dev_field_value: {
        marginLeft: 20,
        marginTop: 10,
        display: "flex",
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'left',
    },
    tableBody: {
        height: '200px',
        overflowX: 'auto',
    },
    tableRow: {

    },
    tableRowAnimated: {
        animation: `$rowEffect 1500ms cubic-bezier(0.4, 0, 0.2, 1)`
    },
    "@keyframes rowEffect": {
        "0%": {
            backgroundColor: 'none',
        },
        "20%": {
            backgroundColor: "#ffdddd",
        },
        "100%": {
            backgroundColor: 'none',
        },
    },

}));

