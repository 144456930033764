import React from 'react'

import {Box, FormControl, InputLabel, MenuItem, SxProps} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {FormControlProps} from "@mui/material/FormControl/FormControl";
import {Theme} from "@emotion/react";

export type DropBoxItem = {
    text: string;
    value: any;
}

export type DropBoxProps = {
    id: string;
    caption: string;
    value: any;
    items: DropBoxItem[];
    onSelect: (value: any) => void;
    disabled?: boolean;
    sx?: SxProps<Theme>;
};

export const DropBox = ({id, caption, value, items, onSelect, disabled = false, sx = {}} : DropBoxProps) : JSX.Element => {

    const handleChange = (event: SelectChangeEvent) => {
        //console.log(event.target.value);
        onSelect(event.target.value as any);
    };

    const fillItems = (items: DropBoxItem[]) => {
        return items.map((item, index) => {
            return (<MenuItem key={index} value={item.value}>{item.text}</MenuItem>);
        });
    }

    return(
        <FormControl fullWidth disabled={disabled} sx={sx}>
            <InputLabel id={id + "-label"}>{caption}</InputLabel>
            <Select
                labelId={id + "-label"}
                id={id}
                value={value}
                label={caption}
                onChange={handleChange}
            >
                {fillItems(items)}
            </Select>
        </FormControl>
    );
}