import React from "react";

import {makeStyles} from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from "@mui/material/ListItemIcon";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";


export type StaticMenuProps = {
    width: number,
    menuOpen: boolean,
    menuItems: MenuItem[],
    updateItemID: (item_id: number) => void,
};

type MenuItem = {
    item_id: number,
    title: string,
    icon: JSX.Element,
    selectedIcon: JSX.Element,
    selected: boolean,
};

const StyledList = styled(List)<{ component: React.ElementType }>({
    '& .MuiListItemText-primary': {
        fontSize: 13,
        '@media (min-width:1024px)': {
            fontSize: 17,
        },
    },
    '& .MuiListItemIcon-root': {
        fontSize: 13,
        '@media (min-width:1024px)': {
            fontSize: 17,
        },
    },
});

export const StaticMenu = ({width, menuOpen, menuItems, updateItemID}: StaticMenuProps): JSX.Element => {

    const [items, setItems] = React.useState(menuItems);

    const navigate = useNavigate();

    const handleItemClick = (item_id: number) => {
        setItems((prevItems) => {

            return prevItems.map((item) => {
                //console.log('client.client_id: ' + client.client_id);
                if (item_id === item.item_id) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }
                return item;
            });
        });
        updateItemID(item_id);

    }

    const handleBackClick = () => {
        //this.context.router.history.goBack;
        navigate(-1);
    }

    const fillItems = (menuItems: MenuItem[]) => {
        return menuItems && menuItems.map((item, index) => {
            const clientIndex = index * 1000000;
            //console.log(clientIndex);
            return (
                <div key={index}>
                    <ListItemButton onClick={() => {
                        handleItemClick(item.item_id)
                    }}>
                        <ListItemIcon>
                            {item.selected ? item.selectedIcon : item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.title}/>
                    </ListItemButton>
                </div>
            )
        });
    }

    return (
        <Drawer
            id="EtdMainMenu"
            variant="persistent"
            open={menuOpen}
            sx={{
                width: width,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: width, boxSizing: 'border-box'},
                zIndex: (theme) => theme.zIndex.appBar - 1
            }}
        >
            <Toolbar variant={(width < 250) ? 'dense' : 'regular'}/>
            <Box sx={{overflow: 'auto'}}>
                <Divider/>
                <StyledList
                    sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        paddingRight: 1,
                        paddingLeft: 1,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            <ListItemButton sx={{pl: 4}} onClick={() => {
                                handleBackClick()
                            }}>
                                <ListItemIcon>
                                    <KeyboardBackspaceIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Back"/>
                            </ListItemButton>
                        </ListSubheader>
                    }
                >
                    {fillItems(items)}
                </StyledList>
            </Box>
        </Drawer>
    );
}

const useStyles = makeStyles(theme => ({}));
