import React from 'react';

import {
    AppBar,
    Button,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    IconButton,
    Box
} from "@mui/material";
import {makeStyles, withStyles} from "@mui/styles";
import {Link, useNavigate, useMatch} from "react-router-dom";
import {useAuthentication} from "./RequiresAuthentication";
import {logout} from "../api/AuthenticationApi";
import {Person} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import {useAsyncError} from "../hooks/useAsyncError";
import useWindowDimensions from "../hooks/useWindowDimensions";


const FullHeightTabs = withStyles(theme => ({
    scroller: {
        display: "flex",
    }
}))(Tabs);

export const Header = ({headerTitle, onMenu}) => {
    const classes = useStyles();

    const authentication = useAuthentication();

    //console.log(authentication);

    let routes = {};

    const cast = useAsyncError();

    const navigate = useNavigate();

    const {width, height} = useWindowDimensions();

    const isAdminMode:boolean = useMatch("/admin") !== null;

    //console.log('isAdmin: ' + isAdminMode);

    //const match = useMatch("/:page");

    //console.log(match);

    //let page = (match && match.params.page) || false;
    //if (!Object.keys(routes).includes(page)) {
    //    page = false;
    //}

    const tabs = [];
    for (let [path, label] of Object.entries(routes)) {
        tabs.push(
            <Tab
                key={path}
                label={label}
                value={path}
                component={Link}
                to={"/" + path}/>
        );
    }

    //const matchDevice = useMatch("/devices/:device?");
    //let device = matchDevice.params.device || false;

    //User menu
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleAdministrationClick = () => {
        handleCloseMenu();
        if (authentication.user.role === "admin") {
            //console.log("navigate(\"/admin\");");
            navigate("/admin");
        }
    };

    const handleExytAdministrationClick = () => {
        handleCloseMenu();
        navigate("/dashboard");
    };

    const handleLogoutClick = () => {
        handleCloseMenu();
        logout()
            .then(() => {
                authentication.logout();
            })
            .catch(e => cast(e));

    };

    const handleMenuClick = () => {
        if(onMenu){
            onMenu();
        }
    };

    const handleHomeClick = () => {
        //console.log('Click!!!!');
        navigate("/dashboard");
    };

    return (
        <AppBar
            variant='outlined'
            color='default'
            position='fixed'
            elevation={0}
            className={classes.root}>

            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: (width < 1024) ? 10 : 15,
                }}
            >
                <Typography
                    className={classes.title}
                    variant="h6"
                    noWrap>
                    {headerTitle}
                </Typography>
            </Box>

            <div className={classes.container}>

                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                }}
                >
                    <div className={classes.menuButton}>
                        <IconButton
                            color="inherit"
                            onClick={handleMenuClick}
                            edge="start"
                            sx={{
                                marginLeft: 1,
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>

                    <Toolbar variant={(width < 1024) ? 'dense' : 'regular'}>
                        <Typography variant='h6' noWrap>
                        </Typography>
                        <img src='/ms-tech-logo_55.png' onClick={handleHomeClick} alt='' style={(width < 1024) ?
                            {width:'70%', height:'auto', 'pointer-events': 'all', cursor: 'pointer'}
                            : {'pointer-events': 'all', cursor: 'pointer'}}/>
                    </Toolbar>
                </Box>


                <Button
                    onClick={handleOpenMenu}>
                    <Person/> {authentication.user.username}
                </Button>
                <Menu
                    keepMounted
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}>
                    {(authentication.user.role === "admin") ?
                        (!isAdminMode ? <MenuItem onClick={handleAdministrationClick}>Administration</MenuItem> :
                            <MenuItem onClick={handleExytAdministrationClick}>Exit Administration</MenuItem>) : null}
                    <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
                </Menu>
            </div>
        </AppBar>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        paddingRight: theme.spacing(3),
        borderRadius: 0,
        marginTop: 0,
    },
    menuButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "space-between",
    },
    title: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.main,
    },
}));