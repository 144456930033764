import React from "react";

import {host} from "./api";

import axios from "axios";
import {Response} from "./AdminApi";


export interface ScanHist {
    Cnt: number,
    EVKAlarm: ScanHistItem[],
    EVKClear: ScanHistItem[],
    ExplosivesAlarm: ScanHistItem[],
    ExplosivesClear: ScanHistItem[],
    NVKAlarm: ScanHistItem[],
    NVKClear: ScanHistItem[],
    NarcoticsAlarm: ScanHistItem[],
    NarcoticsClear: ScanHistItem[],
    SerialNumber: string
}
export interface ScanHistItem {
    Date: number,
    Material: string,
    "Material Group": string,
    ProfileName: string,
    ScanNumber: number,
    UserName: string
}

export interface DeviceInfo {
    Cnt: number,
    EnableTurnOffDisplay: boolean,
    TurnOffDisplayTimeout: number,
    EnablePrinter: boolean,
    UseLabelPrinter: boolean,
    PrintAlarm: boolean,
    PrintClear: boolean,
    TimeZone: string,
    Language: string,
    AudioAlarm: boolean,
    ThreatIdentification: boolean,
    AlarmResolution: boolean,
    EnableLightTower: boolean,
    ClearLightTowerGreen: boolean,
    AlarmLightTowerRed: boolean,
}

export interface DeviceDate {
    Cnt: number,
    DateTime: number,
}

export interface UserListItem {
    "full name": string,
    name: string,
}

export interface UserList {
    Cnt: number,
    SerialNumber: string,
    list: UserListItem[],
}

export type UserRole = {
    roleID: number;
    roleDescription: string;
}

export interface EditDevUserInfo {
    username: string,
    role: string,
    full_name: string,
    password: string,
}

export interface GetDevUserInfo {
    username: string,
    role: string,
    full_name: string,
    password: string,
    userid: string,
}

export interface SWHist {
    Cnt: number,
    SWHistory: SWHistoryItem[],
}
export interface SWHistoryItem {
    Date: number,
    SW_BuildDate: string,
    SW_Version: string,
}
export interface DeviceType {
    Cnt: number,
    Type: string,
    CPUArchitecture: string,
}

export interface ResponseMQTT {
    success: boolean,
    error: string,
    code: number,
    publishID: number,
}


export const getScanHist = async (etd_id: number, dateFrom:number, dateTo:number): Promise<ScanHist> => {

    try {
        const response = await axios.get(`${host}/mqtt/get_scan_hist/` + etd_id + `/period/` + dateFrom + `-` + dateTo);
        //console.log(JSON.parse(JSON.parse(response.data).data));
        return JSON.parse(JSON.parse(response.data).data);
    }catch (e) {
        console.log('getScanHist() error: ' + e);
        return {
            Cnt: 0,
            EVKAlarm: [],
            EVKClear: [],
            ExplosivesAlarm: [],
            ExplosivesClear: [],
            NVKAlarm: [],
            NVKClear: [],
            NarcoticsAlarm: [],
            NarcoticsClear: [],
            SerialNumber: ''
        };
    }
};

export const getEtdInfo = async (etd_id: number): Promise<DeviceInfo> => {

    try {
        const response = await axios.get(`${host}/mqtt/get_etd_info/` + etd_id);
        //console.log(JSON.parse(JSON.parse(response.data).data));
        const data = JSON.parse(JSON.parse(response.data).data);
        return {
            Cnt: parseInt(data.Cnt),
            EnableTurnOffDisplay: Boolean(parseInt(data.EnableTurnOffDisplay)),
            TurnOffDisplayTimeout: parseInt(data.TurnOffDisplayTimeout),
            EnablePrinter: Boolean(parseInt(data.EnablePrinter)),
            UseLabelPrinter: Boolean(parseInt(data.UseLabelPrinter)),
            PrintAlarm: Boolean(parseInt(data.PrintAlarm)),
            PrintClear: Boolean(parseInt(data.PrintClear)),
            TimeZone: data.TimeZone,
            Language: data.Language,
            AudioAlarm: Boolean(parseInt(data.AudioAlarm)),
            ThreatIdentification: Boolean(parseInt(data.ThreatIdentification)),
            AlarmResolution: Boolean(parseInt(data.AlarmResolution)),
            EnableLightTower: Boolean(parseInt(data.EnableLightTower)),
            ClearLightTowerGreen: Boolean(parseInt(data.ClearLightTowerGreen)),
            AlarmLightTowerRed: Boolean(parseInt(data.AlarmLightTowerRed)),
        };
    }catch (e) {
        console.log('getEtdInfo() error: ' + e);
        return {
            Cnt: 0,
            EnableTurnOffDisplay: false,
            TurnOffDisplayTimeout: 0,
            EnablePrinter: false,
            UseLabelPrinter: false,
            PrintAlarm: false,
            PrintClear: false,
            TimeZone: '',
            Language: '',
            AudioAlarm: false,
            ThreatIdentification: false,
            AlarmResolution: false,
            EnableLightTower: false,
            ClearLightTowerGreen: false,
            AlarmLightTowerRed: false,
        };
    }
};

export const getEtdDate = async (etd_id: number): Promise<DeviceDate> => {

    try {
        const response = await axios.get(`${host}/mqtt/get_etd_date/` + etd_id);
        //console.log(JSON.parse(JSON.parse(response.data).data));
        const data = JSON.parse(JSON.parse(response.data).data);
        return {
            Cnt: parseInt(data.Cnt),
            DateTime: parseInt(data.DateTime),
        };
    }catch (e) {
        console.log('getEtdDate() error: ' + e);
        return {
            Cnt: 0,
            DateTime: 0,
        };
    }
};

export const putEtdDate = async (etd_id: number, dateTime: number): Promise<boolean> => {

    try {
        const dateTimeUpdate = {
            etd_id : etd_id,
            date_time: (dateTime * 1000).toString()
        }
        const response = await axios.put(`${host}/mqtt/set_etd_date`, dateTimeUpdate);

        if(response.status === 200){
            return true;
        }else{
            console.log('setEtdDate(): error: ' + response.data.error);
            return false;
        }
    }catch (e) {
        console.log('setEtdDate() error: ' + e);
        return false;
    }
};

export const putEtdInfo = async (etd_id: number, deviceInfo: DeviceInfo): Promise<boolean> => {

    try {
        const etdInfoUpdate = {
            etd_id : etd_id,
            etdInfo: {
                Cnt: deviceInfo.Cnt,
                EnableTurnOffDisplay: deviceInfo.EnableTurnOffDisplay ? "1" : "0",
                TurnOffDisplayTimeout: deviceInfo.TurnOffDisplayTimeout.toString(),
                EnablePrinter: deviceInfo.EnablePrinter ? "1" : "0",
                UseLabelPrinter: deviceInfo.UseLabelPrinter ? "1" : "0",
                PrintAlarm: deviceInfo.PrintAlarm ? "1" : "0",
                PrintClear: deviceInfo.PrintClear ? "1" : "0",
                TimeZone: deviceInfo.TimeZone,
                Language: deviceInfo.Language,
                AudioAlarm: deviceInfo.AudioAlarm ? "1" : "0",
                ThreatIdentification: deviceInfo.ThreatIdentification ? "1" : "0",
                AlarmResolution: deviceInfo.AlarmResolution ? "1" : "0",
                EnableLightTower: deviceInfo.EnableLightTower ? "1" : "0",
                ClearLightTowerGreen: deviceInfo.ClearLightTowerGreen ? "1" : "0",
                AlarmLightTowerRed: deviceInfo.EnableLightTower ? "1" : "0",
            },
        }
        const response = await axios.put(`${host}/mqtt/set_etd_info`, etdInfoUpdate);

        if(response.status === 200){
            return true;
        }else{
            console.log('putEtdInfo(): error: ' + response.data.error);
            return false;
        }
    }catch (e) {
        console.log('putEtdInfo() error: ' + e);
        return false;
    }
};

export const getDevUserList = async (etd_id: number): Promise<UserList> => {

    try {
        const response = await axios.get(`${host}/mqtt/get_etd_users/` + etd_id);
        //console.log(JSON.parse(JSON.parse(response.data).data));
        const data = JSON.parse(JSON.parse(response.data).data);
        return {
            Cnt: parseInt(data.Cnt),
            SerialNumber: data.SerialNumber,
            list: data.list,
        };
    }catch (e) {
        console.log('getEtdDate() error: ' + e);
        return {
            Cnt: 0,
            SerialNumber: "",
            list: [],
        };
    }
};

export const delDevUser = async (etd_id: number, name: string): Promise<Response> => {
    try {
        const response = await axios.delete(`${host}/mqtt/del_etd_user/` + etd_id + '/' + name);
        //console.log(JSON.parse(JSON.parse(response.data).data));
        //const data = JSON.parse(JSON.parse(response.data).data);
        return { success: true, error: "", code: response.status};
    }catch (e) {
        console.log('delDevUser() error: ' + e);
        const errMsg = 'error: ' + e;
        return { success: false, error: errMsg, code: 401};
    }

};

export const addDevUser = async (etd_id: number, editDevUserInfo: EditDevUserInfo): Promise<Response> => {
    try {
        const data = {etd_id: etd_id, username: editDevUserInfo.username, password: editDevUserInfo.password, role: editDevUserInfo.role, full_name: editDevUserInfo.full_name};
        //console.log('addDevUser(): data: ', data);
        const response = await axios.post(`${host}/mqtt/add_etd_user`, data);
        //console.log('addDevUser(): response: ' + response);
        //console.log(JSON.parse(response.data));
        //const data = JSON.parse(JSON.parse(response.data).data);
        return { success: true, error: "", code: response.status};
    }catch (e) {
        console.log('addDevUser() error: ' + e);
        const errMsg = 'error: ' + e;
        return { success: false, error: errMsg, code: 401};
    }

};

export const getDevUser = async (etd_id: number, name: string): Promise<GetDevUserInfo> => {

    try {
        const response = await axios.get(`${host}/mqtt/get_etd_user/` + etd_id + '/' + name);
        console.log(JSON.parse(JSON.parse(response.data).data));
        const data = JSON.parse(JSON.parse(response.data).data);
        //const {name, userrole, 'full name', password, userid} = JSON.parse(JSON.parse(response.data).data);
        return {
            username: data.name,
            role: data.userrole,
            full_name: data['full name'],
            password: data.password,
            userid: data.userid,
        };
    }catch (e) {
        console.log('getEtdDate() error: ' + e);
        return {
            username: '',
            role: '',
            full_name: '',
            password: '',
            userid: '',
        };
    }
};

export const editDevUser = async (etd_id: number, editDevUserInfo: EditDevUserInfo): Promise<Response> => {
    try {
        const data = {etd_id: etd_id, username: editDevUserInfo.username, password: editDevUserInfo.password, role: editDevUserInfo.role, full_name: editDevUserInfo.full_name};
        //console.log('addDevUser(): data: ', data);
        const response = await axios.put(`${host}/mqtt/set_etd_user`, data);
        //console.log('addDevUser(): response: ' + response);
        //console.log(JSON.parse(response.data));
        //const data = JSON.parse(JSON.parse(response.data).data);
        return { success: true, error: "", code: response.status};
    }catch (e) {
        console.log('editDevUser() error: ' + e);
        const errMsg = 'error: ' + e;
        return { success: false, error: errMsg, code: 401};
    }

};

export const getSWHist = async (etd_id: number, dateFrom:number, dateTo:number): Promise<SWHist> => {

    try {
        const response = await axios.get(`${host}/mqtt/get_sw_hist/` + etd_id + `/period/` + dateFrom + `-` + dateTo);
        //console.log(JSON.parse(JSON.parse(response.data).data));
        return JSON.parse(JSON.parse(response.data).data);
    }catch (e) {
        console.log('getSWHist() error: ' + e);
        return {
            Cnt: 0,
            SWHistory: []
        };
    }
};

export const swUpdate = async (etd_id: number, file_name: string): Promise<ResponseMQTT> => {
    try {
        const data = {etd_id: etd_id, file_name: file_name};
        //console.log('addDevUser(): data: ', data);
        const response = await axios.post(`${host}/mqtt/sw_update`, data);
        //console.log('addDevUser(): response: ' + response);
        //console.log(JSON.parse(response.data));
        //const data = JSON.parse(JSON.parse(response.data).data);
        return { success: true, error: "", code: response.status, publishID: response.data.publishID};
    }catch (e) {
        console.log('swUpdate() error: ' + e);
        const errMsg = 'error: ' + e;
        return { success: false, error: errMsg, code: 401, publishID: 0};
    }

};

export const swUpdateStatus = async (etd_id: number, publishID: number): Promise<Response> => {
    try {
        const data = {etd_id: etd_id, publishID: publishID};
        //console.log('addDevUser(): data: ', data);
        const response = await axios.post(`${host}/mqtt/sw_update_status`, data);
        //console.log('addDevUser(): response: ' + response);
        //console.log(JSON.parse(response.data));
        //const data = JSON.parse(JSON.parse(response.data).data);
        return { success: true, error: "", code: response.status};
    }catch (e) {
        console.log('swUpdate() error: ' + e);
        const errMsg = 'error: ' + e;
        return { success: false, error: errMsg, code: 401};
    }

};

export const getEtdType = async (etd_id: number): Promise<DeviceType> => {

    try {
        const response = await axios.get(`${host}/mqtt/get_etd_type/` + etd_id);
        //console.log(JSON.parse(JSON.parse(response.data).data));
        const data = JSON.parse(JSON.parse(response.data).data);
        return {
            Cnt: parseInt(data.Cnt),
            Type: data.Type,
            CPUArchitecture: data.CPUArchitecture,
        };
    }catch (e) {
        console.log('getEtdType() error: ' + e);
        return {
            Cnt: 0,
            Type: 'undefined',
            CPUArchitecture: 'undefined',
        };
    }
};