import React from 'react';

import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";



const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
    },
}));

export const Loading = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress/>
        </div>
    );
};