import React from 'react';
import {useAuth} from "../api/AuthenticationApi";
import {Loading} from "./Loading";
import {Login} from "../pages/Login";

export const AuthenticationContext = React.createContext();

export const useAuthentication = () => React.useContext(AuthenticationContext);

export const RequiresAuthentication = ({children}) => {
    const [user, setUser] = React.useState();

    const authenticate = setUser;
    const logout = React.useCallback(() => {
        setUser(null);
    }, [setUser]);

    useAuth(authenticate, logout);

    if (user === undefined) {
        return (
            <Loading/>
        );
    } else if (user === null) {
        return (
            <Login authenticate={authenticate}/>
        );
    } else {
        return (
            <AuthenticationContext.Provider value={{
                user: user,
                authenticate: authenticate,
                logout: logout,
            }}>
                {children}
            </AuthenticationContext.Provider>
        );
    }
};