import logo from './logo.svg';
import './App.css';
import React, {useState, useRef} from 'react';
import {BrowserRouter, Routes, Route, Navigate, useRouteMatch, useMatch, matchRoutes} from "react-router-dom";
import {Header} from "./components/Header";
import {SitesListMenu} from "./components/SitesListMenu";
import {RequiresAuthentication, useAuthentication} from "./components/RequiresAuthentication";
import {ErrorBoundary} from "./components/ErrorBoundary";
import {Dashboard} from "./pages/Dashboard";
import {DeviceDashboard} from "./pages/DeviceDashboard";
import {DeviceAlarms} from "./pages/DeviceAlarms";
import {DeviceSettings} from "./pages/DeviceSettings";
import {DeviceStatistics} from "./pages/DeviceStatistics";
import {DeviceUsers} from "./pages/DeviceUsers";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {styled, useTheme} from '@mui/material/styles';
import {EtdMainMenu} from "./components/EtdMainMenu";
import {AdminDashboard} from "./pages/AdminDashboard";
import {AdminMenu} from "./components/AdminMenu";
import {AdminUsers} from "./pages/AdminUsers";
import {AdminClients} from "./pages/AdminClients";
import {AdminSites} from "./pages/AdminSites";
import {AdminDevices} from "./pages/AdminDevices";
import useWindowDimensions from "./hooks/useWindowDimensions";
import {appDefaultETDContext, ETDContext} from "./context/ETDContext";
import {DeviceSWUpdate} from "./pages/DeviceSWUpdate";


function App() {

    const theme = useTheme();

    const [headerTitle, setHeaderTitle] = React.useState("");

    const [menuOpen, setMenuOpen] = React.useState(true);

    const [clients, setClients] = React.useState(null);

    const [etdMenuItemID, setEtdMenuItemID] = React.useState(0);

    const [adminMenuItemID, setAdminMenuItemID] = React.useState(1);

    const {width, height} = useWindowDimensions();

    const leftMenuWidth = (width < 1024) ? 200 : 300;

    const [etdName, setETDName] = React.useState(appDefaultETDContext.etdName);

    const sitesListMenuRef = useRef();

    const updateETDName = (name) => {
        //console.log('updateETDName(): ' + name);
        setETDName(name);
    };


    const updateHeaderTitle = (title) => {
        setHeaderTitle(title);
    }

    const updateClients = (clients) => {
        //console.log(clients);
        setClients(clients);
    }

    const updateEtdMenuItemID = (itemID) => {
        //console.log(clients);
        setEtdMenuItemID(itemID);
    }

    const updateAdminMenuItemID = (itemID) => {
        //console.log(clients);
        setAdminMenuItemID(itemID);
    }

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);
    };

    const handleSiteClick = ( site_id: number) => {
        //console.log('handleSiteClick(): site_id: ' + site_id);
        sitesListMenuRef.current.handleSiteClick(site_id);
    }

    React.useEffect(() => {
        const storedETDName = window.localStorage.getItem('ETDName');
        if(storedETDName) {
            setETDName(storedETDName);
        }
    }, []);
    React.useEffect(() => {
        window.localStorage.setItem('ETDName', etdName);
    }, [etdName]);

    return (

        <BrowserRouter>
            <ErrorBoundary>
                <ETDContext.Provider
                    value={{
                        etdName,
                        updateETDName,
                    }}
                >
                    <RequiresAuthentication>
                        <Header
                            headerTitle={headerTitle}
                            onMenu={handleMenuClick}
                        />

                        <Box sx={{
                            display: 'flex',
                            marginLeft: `-${leftMenuWidth}px`,
                            ...(menuOpen && {
                                transition: theme.transitions.create('margin', {
                                    easing: theme.transitions.easing.easeOut,
                                    duration: theme.transitions.duration.enteringScreen,
                                }),
                                marginLeft: 0,
                            }),
                        }}

                        >

                            <Routes>
                                {/*<Route path="/login">
                                    <Devices
                                        titleUpdateHandler={updateHeaderTitle}
                                    />
                                </Route>*/}

                                <Route path="/dashboard" element={
                                    <>
                                        <SitesListMenu
                                            menuOpen={menuOpen}
                                            updateClients={updateClients}
                                            width={leftMenuWidth}
                                            ref={sitesListMenuRef}
                                        />

                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                                p: (width < 1024) ? 1 : 2,

                                            }}
                                        >
                                            <Toolbar variant={(width < 1024) ? 'dense' : 'regular'}/>
                                            <Dashboard
                                                clients={clients}
                                                titleUpdateHandler={updateHeaderTitle}
                                                siteClickHandler={handleSiteClick}
                                            />
                                        </Box>
                                    </>
                                }/>

                                <Route path="/device/:id" element={
                                    <>
                                        <EtdMainMenu
                                            menuOpen={menuOpen}
                                            updateItemID={updateEtdMenuItemID}
                                            width={leftMenuWidth}
                                        />

                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                                p: (width < 1024) ? 1 : 2,

                                            }}
                                        >
                                            <Toolbar variant={(width < 1024) ? 'dense' : 'regular'}/>
                                            {(etdMenuItemID === 0) &&
                                            <DeviceDashboard
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                            {(etdMenuItemID === 1) &&
                                            <DeviceAlarms
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                            {(etdMenuItemID === 2) &&
                                            <DeviceSettings
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                            {(etdMenuItemID === 3) &&
                                            <DeviceStatistics
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                            {(etdMenuItemID === 4) &&
                                            <DeviceUsers
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                            {(etdMenuItemID === 5) &&
                                            <DeviceSWUpdate
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                        </Box>
                                    </>

                                }/>

                                <Route path="/admin" element={
                                    <>
                                        <AdminMenu
                                            menuOpen={menuOpen}
                                            updateItemID={updateAdminMenuItemID}
                                            width={leftMenuWidth}
                                        />

                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                                p: (width < 1024) ? 1 : 2,

                                            }}
                                        >
                                            <Toolbar variant={(width < 1024) ? 'dense' : 'regular'} />
                                            {(adminMenuItemID === 0) &&
                                            <AdminDashboard
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                            {(adminMenuItemID === 1) &&
                                            <AdminUsers
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                            {(adminMenuItemID === 2) &&
                                            <AdminClients
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                            {(adminMenuItemID === 3) &&
                                            <AdminSites
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                            {(adminMenuItemID === 4) &&
                                            <AdminDevices
                                                titleUpdateHandler={updateHeaderTitle}
                                            />
                                            }
                                        </Box>
                                    </>

                                }/>

                                <Route path="/" element={<Navigate replace to="/dashboard"/>}/>

                                <Route path="/login" element={<Navigate replace to="/dashboard"/>}/>
                            </Routes>

                        </Box>
                    </RequiresAuthentication>
                </ETDContext.Provider>
            </ErrorBoundary>
        </BrowserRouter>

    );
}

export default App;
