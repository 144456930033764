import React from "react";

import {makeStyles} from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArticleIcon from '@mui/icons-material/Article';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import {useNavigate} from "react-router-dom";
import {StaticMenu} from "./StaticMenu";


export type EtdMainMenuProps = {
    width: number,
    menuOpen: boolean,
    updateItemID: (item_id: number) => void,
};

const menuItems = [
    {
        item_id: 0,
        title: "General",
        icon: <ArticleIcon/>,
        selectedIcon: <ArticleIcon color="primary"/>,
        selected: true,
    },
    {
        item_id: 1,
        title: "Alarms",
        icon: <AssessmentIcon/>,
        selectedIcon: <AssessmentIcon color="primary"/>,
        selected: false,
    },
    {
        item_id: 2,
        title: "Settings",
        icon: <SettingsIcon/>,
        selectedIcon: <SettingsIcon color="primary"/>,
        selected: false,
    },
    {
        item_id: 3,
        title: "Statistics",
        icon: <QueryStatsIcon/>,
        selectedIcon: <QueryStatsIcon color="primary"/>,
        selected: false,
    },
    {
        item_id: 4,
        title: "Users",
        icon: <PeopleAltIcon/>,
        selectedIcon: <PeopleAltIcon color="primary"/>,
        selected: false,
    },
    {
        item_id: 5,
        title: "SW Update",
        icon: <SystemUpdateAltIcon/>,
        selectedIcon: <SystemUpdateAltIcon color="primary"/>,
        selected: false,
    },
];

export const EtdMainMenu = ({width, menuOpen, updateItemID} : EtdMainMenuProps) : JSX.Element => {
    return (
        <StaticMenu
            width={width}
            menuOpen={menuOpen}
            updateItemID={updateItemID}
            menuItems={menuItems}
        />
    );
}

const useStyles = makeStyles(theme => ({}));
