import axios, {AxiosError} from "axios";
import {host} from "./api";
import React from "react";
import {getAllSites} from "./DevicesApi";

export interface UserInfo {
    id: number,
    username: string,
    role: string,
    full_name: string,
    email: string,
    phone: string,
}

export interface RegUserInfo {
    username: string,
    role: string,
    full_name: string,
    email: string,
    phone: string,
    password: string,
}

export interface EditUserInfo {
    username: string,
    role: string,
    full_name: string,
    email: string,
    phone: string,
}

export interface ChangePassUserInfo {
    username: string,
    new_password: string,
}

export interface DelUserInfo {
    username: string,
}

export interface Response {
    success: boolean,
    error: string,
    code: number,
}

export interface ClientInfo {
    id: number,
    client_name: string,
}

export interface RegClientInfo {
    client_name: string,
}

export interface EditClientInfo {
    id: number,
    client_name: string,
}

export interface DelClientInfo {
    id: number,
}

export interface SiteInfo {
    id: number,
    site_name: string,
    client_id: number,
    client_name: string,
    users: SiteUserInfo[],
}

export interface RegSiteInfo {
    site_name: string,
    client_id: number,
    users: SiteUserInfo[],
}

export interface EditSiteInfo {
    id: number,
    site_name: string,
    client_id: number,
    users: SiteUserInfo[],
}

export interface DelSiteInfo {
    id: number,
}

export interface SiteUserInfo {
    user_id: number,
    user_name: string,
}

export interface DeviceInfo {
    id: number,
    serialNumber: string,
    site_id: number,
    site_name: string,
    description: string,
    id_token: string,
    password: string,
}

export interface RegDeviceInfo {
    serialNumber: string,
    site_id: number,
    description: string,
    id_token: string,
    password: string,
}

export interface EditDeviceInfo {
    id: number,
    serialNumber: string,
    site_id: number,
    description: string,
    id_token: string,
    password: string,
}

export interface DelDeviceInfo {
    id: number,
}

export interface SiteInfoShort {
    id: number,
    site_name: string,
}



export const getUsers = async (): Promise<UserInfo[] | undefined> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.get(`${host}/users/get_users`);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getUsers() error: ' + e);
        return undefined;
    }
};

export const registerUser = async (user: RegUserInfo): Promise<Response> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.post(`${host}/users/register`, user);
        //console.log(response.status);
        if(response.status === 201){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('registerUser(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('registerUser(): catch: error: ' + error);
        if (error.response) {
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

export const changePassUser = async (user: ChangePassUserInfo): Promise<Response> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.put(`${host}/users/change_pass`, user);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('changePassUser(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('changePassUser(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

export const editUser = async (user: EditUserInfo): Promise<Response> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.put(`${host}/users/update`, user);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('editUser(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('editUser(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

export const delUser = async (user: DelUserInfo): Promise<Response> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.delete(`${host}/users/remove/`+ user.username);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('delUser(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('delUser(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};


export const getClients = async (): Promise<ClientInfo[]> => {
    try {
        const response = await axios.get(`${host}/clients/get_clients`);
        //console.log('getClients(): response.data' + response.data);
        return response.data;
    } catch (e) {
        console.log('getClients() error: ' + e);
        return [];
    }
};

export const registerClient = async (client: RegClientInfo): Promise<Response> => {
    try {
        const response = await axios.post(`${host}/clients/register`, client);
        //console.log(response.status);
        if(response.status === 201){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('registerClient(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('registerClient(): catch: error: ' + error);
        if (error.response) {
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

export const editClient = async (client: EditClientInfo): Promise<Response> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.put(`${host}/clients/update`, client);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('editClient(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('editClient(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

export const delCient = async (client: DelClientInfo): Promise<Response> => {
    try {
        const response = await axios.delete(`${host}/clients/remove/`+ client.id);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('delCient(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('delCient(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};


export const getSites = async (): Promise<SiteInfo[] | undefined> => {
    try {
        const response = await axios.get(`${host}/sites/get_sites`);
        //console.log('getSites(): response.data' + response.data);
        return response.data;
    } catch (e) {
        console.log('getSites() error: ' + e);
        return undefined;
    }
};

export const getSitesShort = async (): Promise<SiteInfoShort[] | undefined> => {
    try {
        const response = await axios.get(`${host}/sites/get_sites_short`);
        //console.log('getSitesShort(): response.data' + response.data);
        return response.data;
    } catch (e) {
        console.log('getSitesShort() error: ' + e);
        return undefined;
    }
};

export const registerSite = async (site: RegSiteInfo): Promise<Response> => {
    try {
        const response = await axios.post(`${host}/sites/register`, site);
        //console.log(response.status);
        if(response.status === 201){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('registerSite(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('registerSite(): catch: error: ' + error);
        if (error.response) {
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

export const editSite = async (site: EditSiteInfo): Promise<Response> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.put(`${host}/sites/update`, site);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('editSite(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('editSite(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

export const delSite = async (site: DelSiteInfo): Promise<Response> => {
    try {
        const response = await axios.delete(`${host}/sites/remove/`+ site.id);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('delSite(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('delSite(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};



export const getDevices = async (): Promise<DeviceInfo[] | undefined> => {
    try {
        const response = await axios.get(`${host}/devices/get_devices`);
        //console.log('getDevices(): response.data' + response.data);
        return response.data;
    } catch (e) {
        console.log('getDevices() error: ' + e);
        return undefined;
    }
};

export const registerDevice = async (device: RegDeviceInfo): Promise<Response> => {
    try {
        const response = await axios.post(`${host}/devices/register`, device);
        //console.log(response.status);
        if(response.status === 201){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('registerDevice(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('registerDevice(): catch: error: ' + error);
        if (error.response) {
            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

export const editDevice = async (device: EditDeviceInfo): Promise<Response> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.put(`${host}/devices/update`, device);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('editDevice(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('editDevice(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

export const delDevice = async (device: DelDeviceInfo): Promise<Response> => {
    try {
        const response = await axios.delete(`${host}/devices/remove/`+ device.id);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('delDevice(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('delDevice(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};

