import React from 'react'
import { styled } from '@mui/material/styles';
import {makeStyles} from "@mui/styles";
import {deviceBrief} from "../api/DevicesApi";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useWindowDimensions from "../hooks/useWindowDimensions";
import Cloud from '@mui/icons-material/Cloud';
import CloudOff from '@mui/icons-material/CloudOff';
import CloudQueue from '@mui/icons-material/CloudQueue';
import {number} from "prop-types";
import {AnimationOnIconChange} from "./AnimationOnIconChange";
import {CloudIcon} from "./CloudIcon";
import {AnimationOnTextChange} from "./AnimationOnTextChange";


export type DevicesTableProps = {
    devices: deviceBrief[] | null,
    handleClick: (etd_id: string, serial_number: string) => void;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 10,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
    [`&.${tableCellClasses.body}`]: {
        //fontSize: 14,
        fontSize: 9,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const DevicesTable = ({devices, handleClick} : DevicesTableProps ) : JSX.Element => {

    const classes = useStyles();

    const {width, height} = useWindowDimensions();

    // const cloudIcon = (online: number, status: number) => {
    //     if(online === 1){
    //         if(status === 1){
    //             return (<CloudQueue style={{ color: 'orange' }}/>);
    //         }else{
    //             return (<CloudQueue style={{ color: 'green' }}/>);
    //         }
    //     }else{
    //         return (<CloudOff style={{ color: 'red' }}/>);
    //     }
    // }

    const cloudIcon = (online: number, status: number) => {
        // let cloudComponent : JSX.Element;
        // if(online === 1){
        //     if(status === 1){
        //         cloudComponent = (<CloudQueue style={{ color: 'orange' }}/>);
        //     }else{
        //         cloudComponent = (<CloudQueue style={{ color: 'green' }}/>);
        //     }
        // }else{
        //     cloudComponent = (<CloudOff style={{ color: 'red' }}/>);
        // }
        //
        // return (<AnimationOnIconChange value={online * 10 + status}>
        //     {cloudComponent}
        // </AnimationOnIconChange>);

        return (<CloudIcon online={online} status={status}/>);
    }

    const getPercent = (value: number, total: number) : number => {
        if(total > 0){
            return Math.round(value / total * 100);
        }
        return 0;
    }

    return(
        <TableContainer sx={{ maxHeight: height / 2.75 }} component={Paper}>
            <Table sx={{ minWidth: 650 }} size={width < 1024 ? "small" : "medium"} stickyHeader aria-label="sticky table" >
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">ETD</StyledTableCell >
                        <StyledTableCell align="left">Status</StyledTableCell >
                        <StyledTableCell align="left">Site</StyledTableCell >
                        <StyledTableCell align="left">Client</StyledTableCell >
                        <StyledTableCell align="left">Description</StyledTableCell >
                        <StyledTableCell align="center">Total</StyledTableCell >
                        <StyledTableCell align="center">Clear</StyledTableCell >
                        <StyledTableCell align="center">Explosives</StyledTableCell >
                        <StyledTableCell align="center">Narcotics</StyledTableCell >
                    </TableRow>
                </TableHead>
                <TableBody >
                    {devices && devices.map((device) => {
                        if (device) {
                            const total = device.clear + device.explosives + device.narcotics;

                            return (
                                <StyledTableRow key={device.etd_id} onClick={()=> handleClick(device.etd_id, device.serial_number)} className={classes.tableRow}>
                                    <StyledTableCell align="left" component="th" scope="row">
                                        {device.serial_number}
                                    </StyledTableCell>
                                    <StyledTableCell align="left"> <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start"
                                        }}>{cloudIcon(device.online, device.Status)} </div></StyledTableCell>
                                    <StyledTableCell align="left">{device.site_name}</StyledTableCell>
                                    <StyledTableCell align="left">{device.client_name}</StyledTableCell>
                                    <StyledTableCell align="left">{device.description}</StyledTableCell>

                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={total.toString()} >
                                            {total}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={device.clear + '('+ getPercent(device.clear, total) + '%)'} >
                                            {device.clear + '('+ getPercent(device.clear, total) + '%)'}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={device.explosives + '('+ getPercent(device.explosives, total) + '%)'} >
                                            {device.explosives + '('+ getPercent(device.explosives, total) + '%)'}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={device.narcotics + '('+ getPercent(device.narcotics, total) + '%)'} >
                                            {device.narcotics + '('+ getPercent(device.narcotics, total) + '%)'}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        }else{
                            return null;
                        }
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles(theme => ({
    tableBody: {
        height: '200px',
        overflowX: 'auto',
    },
    tableRow: {
        cursor: 'pointer',
    },
}));