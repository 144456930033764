import React from "react";
import {host} from "./api";
import axios from "axios";

// export interface SWList {
//     SWList: SWListItem[],
// }

export interface SWListItem {
    swFile: string,
}

export const getSWList = async (etd_id: number, filter: string): Promise<SWListItem[]> => {

    let filt = 'all';
    if((filter === 'imx8') || (filter === 'imx6')) {
        filt = filter;
    }
    try {

        const response = await axios.get(`${host}/swupdate/get_sw_list/` + etd_id + "/" + filt);
        //console.log(response.data);
        return response.data;
    }catch (e) {
        console.log('getSWList() error: ' + e);
        return [];
    }
};