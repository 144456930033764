import React from "react";

import {
    Accordion, AccordionDetails, AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide, Typography
} from "@mui/material";

import {makeStyles, withStyles} from "@mui/styles";


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const styles = theme => ({
    root: {},
    code: {
        whiteSpace: "pre",
        overflowX: "scroll",
        color: theme.palette.error.dark,
        fontFamily: "monospace",
        userSelect: "all",
    },
});

class _ErrorBoundary extends React.Component {
    transition;



    constructor(props) {
        super(props);
        this.state = {hasError: false};

        this.transition = React.forwardRef((props, ref) => {
            return <Slide direction="up" ref={ref} {...props} />;
        });

        this.handleClose = this.handleClose.bind(this);
    }

    componentDidCatch(error, errorInfo) {
        this.setState({hasError: true, error: error, errorInfo: errorInfo});
    }

    handleClose() {
        this.setState({hasError: false});
    }

    render() {
        const {classes} = this.props;

        const dialog = (
            <Dialog
                maxWidth="lg"
                open={true}
                TransitionComponent={this.transition}
                onClose={this.handleClose}>
                <DialogTitle>Something went wrong!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Something wrong happened during last action. Try reloading page. If this problem persists
                        contact administrator.
                    </DialogContentText>
                    <Accordion elevation={6}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.code}>
                            {
                                this.state.error &&
                                this.state.error.stack
                                + "\n\nComponent Stack:"
                                + this.state.errorInfo.componentStack
                            }
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        );

        return this.state.hasError ? dialog : this.props.children;
    }
}

export const ErrorBoundary = withStyles(styles)(_ErrorBoundary);