import React, {useEffect} from "react";
import {makeStyles} from "@mui/styles";
import {renderToStaticMarkup} from "react-dom/server";
import {deviceShort} from "../api/EtdApi";

interface AuxProps {
    value: number;
    children: React.ReactNode;
}

export const AnimationOnIconChange = ( {value, children} : AuxProps ): JSX.Element => {

    const classes = useStyles();

    const [animate, setAnimate] = React.useState <boolean | undefined>(undefined);

    //console.log(animate);

    //let timer: NodeJS.Timeout;

    useEffect(() => {
        if(animate !== undefined){
            setAnimate(true);
        }
        //clearTimeout(timer);
        const timer = setTimeout(
            () => setAnimate(false), 1000);
        return () => clearTimeout(timer);

    }, [value]);

    // if(children){
    //
    //     const newChildStr: string = renderToStaticMarkup(children as React.ReactElement<any, string | React.JSXElementConstructor<any>>);
    //     console.log(childStr);
    //
    //     if(childStr !== newChildStr){
    //         setChildStr(newChildStr);
    //         animate = true;
    //     }
    // }

    //console.log(children && renderToStaticMarkup(children as React.ReactElement<any, string | React.JSXElementConstructor<any>>));

    //console.log(animate);

    if(animate) {
        return (<div className={classes.animatedItem}>
            {children}
        </div>);
    }else{
        return (<div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
            {children}
        </div>);
    }
}

const useStyles = makeStyles(theme => ({
    animatedItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        animation: `$myEffect 700ms cubic-bezier(0.4, 0, 0.2, 1)`
    },

    "@keyframes myEffect": {
        "0%": {
            transform: "scale(1.0) ",
        },
        "20%": {
            transform: "scale(1.5)",
        },
        "40%": {
            transform: "scale(1.0)",
        },
        "60%": {
            transform: "scale(1.5)",
        },
        "100%": {
            transform: "scale(1.0)",
        },
    },
}));