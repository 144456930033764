import React, {useEffect} from "react";
import { Chart } from "react-google-charts";
import {uptimeStatRec} from "../api/EtdApi";

// import {
//     Chart as ChartJS,
//     ArcElement,
//     Tooltip,
//     Legend,
// } from 'chart.js';
//
// import { Pie } from 'react-chartjs-2';

//
// ChartJS.register(ArcElement, Tooltip, Legend);


export type UptimeStatChartProps = {
    uptimeStat: uptimeStatRec[] | undefined;
};

type StateRec = {
    onState: number;
    offState: number;
}

export const UptimeStatChart = ({uptimeStat}: UptimeStatChartProps): JSX.Element => {

    const [stateRec, setStatRec] = React.useState <StateRec> ({onState: 0, offState: 0});

    useEffect(() => {
        if(uptimeStat && uptimeStat.length > 0){
            let onState = 0;
            let offState = 0;
            if(uptimeStat) {
                for (let i = 0; i < uptimeStat.length; i++) {
                    if(uptimeStat[i].state === 0){
                        offState = offState + uptimeStat[i].duration;
                    }else if(uptimeStat[i].state === 1){
                        onState = onState + uptimeStat[i].duration;
                    }
                }
            }

            // let total = onState + offState;
            // if(total > 0) {
            //     setStatRec({
            //         offState: offState / total * 100,
            //         onState: onState / total * 100,
            //     });
            // }

            setStatRec({
                offState: offState /3600,
                onState: onState / 3600,
            });

        }
    }, [uptimeStat])


    const data = React.useMemo(() => ([
        ["Task", "Hours"],
        ["On", stateRec.onState],
        ["Off", stateRec.offState],
    ]), [stateRec]);

    const options = React.useMemo(() => ({
        colors: ["#55cc55", "#cc5555",]
    }), []);

    // const data = {
    //     labels: ['On', 'Off'],
    //     datasets: [
    //         {
    //             label: '% of time',
    //             data: [onState, offState],
    //             backgroundColor: [
    //                 'rgba(75, 192, 132, 0.2)',
    //                 'rgba(255, 99, 132, 0.2)',
    //             ],
    //             borderColor: [
    //                 'rgba(75, 192, 132, 1)',
    //                 'rgba(255, 99, 132, 1)',
    //             ],
    //             borderWidth: 1,
    //         },
    //     ],
    // };

    return (
        // <Pie data={data}/>
        <Chart
            chartType="PieChart"
            data={data}
            options={options}
            // height={200}
            // width={200}
        />
    );
}