import axios from "axios";

//import "./UserGroupApi";

axios.defaults.withCredentials = true;
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";

let _host = window.location.protocol + '//' + window.location.host;

// comment next line for production, uncomment for development
//_host = "http://localhost:5000";

export const host = _host;