import React from "react";

import {CircularProgress, Grow, Alert, InputAdornment, Popper, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";


const useStyles = makeStyles(theme => ({
    inputDefault: {
        transition: "0.2s ease-in-out box-shadow",
    },
    inputChanged: {
        boxShadow: "0 0 0 3px " + theme.palette.warning.main + "88",
        "& fieldset": {
            borderColor: theme.palette.warning.main + " !important",
        },
        "& fieldset legend": {
            background: theme.palette.background.default,
            visibility: "visible",
        },
        "& fieldset legend span": {
            visibility: "hidden",
        },
    },
    inputError: {
        boxShadow: "0 0 0 3px " + theme.palette.error.main + "88",
        "& fieldset": {
            borderColor: theme.palette.error.main + " !important",
        },
        "& fieldset legend": {
            background: theme.palette.background.default,
            visibility: "visible",
        },
        "& fieldset legend span": {
            visibility: "hidden",
        },
    },
    alertContainer: {
        display: "flex",
        justifyContent: "flex-start",
        paddingTop: theme.spacing(1),
    },
    alert: {
        //marginLeft: theme.spacing(2),
    },
}));

/*
    status:
        default (or undefined): normal editable field
        pending: non editable field with loading indicator
        changed: field with yellow border
        error: field with red border
 */
export const StatusTextField = (
    {InputProps, status, alert, showAlert, ...props}
) => {
    const classes = useStyles();

    const inputRef = React.useRef();

    return (
        <>
            <TextField
                ref={inputRef}
                variant="outlined"
                InputProps={{
                    className: clsx(classes.inputDefault,
                        status === "changed" && classes.inputChanged,
                        status === "error" && classes.inputError),
                    endAdornment: status === "pending" && (
                        <InputAdornment
                            position="end">
                            <CircularProgress
                                color="inherit"
                                size={18}/>
                        </InputAdornment>
                    ),
                    ...InputProps,
                }}
                disabled={status === "pending"}
                {...props}/>
            {
                showAlert &&
                <Alert
                    className={classes.alert}
                    severity="error">
                    {alert}
                </Alert>
            }
        </>
    );
};

StatusTextField.propTypes = {
    status: PropTypes.oneOf(["default", "pending", "changed", "error"]),
};