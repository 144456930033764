import React, {useContext} from 'react'
import {makeStyles} from "@mui/styles";
import {createTheme, styled, useTheme, ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {GoogleMap} from "../components/GoogleMap";
import {deviceBrief, useDevsBrief, useDevsBriefLive, useDevsTotalLive, useSitesSumLive} from "../api/DevicesApi";
import {DevicesTable} from "../components/DevicesTable";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {number} from "prop-types";

import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import DevicesIcon from '@mui/icons-material/Devices';
//import {useNavigate} from "react-router-dom";
import {Link, useNavigate, useMatch} from "react-router-dom";
import {getDevShort} from "../api/EtdApi";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {AnimationOnTextChange} from "../components/AnimationOnTextChange";
import {SitesTable} from "../components/SitesTable";
import {ETDContext} from "../context/ETDContext";


const UPDATE_INTERVAL = 2000;

export type DashboardProps = {
    clients: any,
    titleUpdateHandler: (title: string) => void,
    siteClickHandler: (site_id: number) => void,
};

export type TotDevProps = {
    total: number,
    online: number,
};


const Item1 = styled(Paper)(({ theme } ) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item0 = styled(Paper)(({ theme } ) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export const Dashboard = ({clients, titleUpdateHandler, siteClickHandler} : DashboardProps ) : JSX.Element => {

    const classes = useStyles();

    let theme = useTheme();

    theme = createTheme(theme, {
        typography: {
            h4: {
                fontSize: 18,
                '@media (min-width:1024px)': {
                    fontSize: 25,
                },
                '@media (min-width:1920px)': {
                    fontSize: 32,
                },
            },
            h5: {
                fontSize: 12,
                '@media (min-width:1024px)': {
                    fontSize: 18,
                },
                '@media (min-width:1920px)': {
                    fontSize: 24,
                },
            },
        },
    });

    const [viewBySite, setViewBySite] = React.useState(false);

   // console.log(viewBySite);

    const devices = useDevsBriefLive(clients, UPDATE_INTERVAL);
    //console.log(devices);

    const sites = useSitesSumLive(clients, UPDATE_INTERVAL);
    //console.log(sites);

    const devsTotal = useDevsTotalLive(clients, UPDATE_INTERVAL);

    const navigate = useNavigate();

    const {width, height} = useWindowDimensions();

    const contSpacing = (width < 1024) ? 1 : 2;

    const {updateETDName} = useContext(ETDContext);



    let testTotal: number = 0;
    let clearTotal: number = 0;
    let explTotal: number = 0;
    let narcTotal: number = 0;

    if(devsTotal && devsTotal.length > 0){
        explTotal = devsTotal[0].sum_explosives;
        narcTotal = devsTotal[0].sum_narcotics;
        testTotal = explTotal * 1 + narcTotal * 1 + devsTotal[0].sum_clear * 1;
        clearTotal = devsTotal[0].sum_clear * 1;
    }

    //console.log('testTotal: ' + testTotal + ', explTotal: ' + explTotal + ', narcTotal: ' + narcTotal + ', %: ' + explTotal / testTotal * 100);

    //console.log(devices);

    React.useEffect(() => {
        titleUpdateHandler('');
    }, [titleUpdateHandler]);

    React.useEffect(() => {
        if (clients && clients.length > 0) {
            if(clients[0].site_id){
                setViewBySite(true);
            }else{
                setViewBySite(false);
            }
        }
    }, [clients]);

    const getNumSites = (/*devices: deviceBrief[] | null*/) => {
        if(!viewBySite){
            if (sites !== null) {
                return sites.length;
            } else {
                return 0;
            }
        }else {
            if (devices !== null) {
                let counts: number[] = [];
                for (let i = 0; i < devices.length; i++) {
                    let found = false;
                    for (let j = 0; j < counts.length; j++) {
                        if (counts[j] === devices[i].site_id) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        counts.push(devices[i].site_id);
                    }
                }
                //console.log(counts.length);
                return counts.length;
            } else {
                return 0;
            }
        }
    }

    const getNumDevices = (/*devices: deviceBrief[] | null*/) => {
        if(!viewBySite){
            if (sites !== null) {
                // console.log(JSON.stringify(devices));
                let online = 0;
                let totDev = 0;
                for (let i = 0; i < sites.length; i++) {
                    totDev = totDev + Number(sites[i].tot_devs);
                    online = online + Number(sites[i].tot_online_devs);
                }
                return {total: totDev, online: online};
            } else {
                return {total: 0, online: 0};
            }
        }else {
            if (devices !== null) {
                // console.log(JSON.stringify(devices));
                let online = 0;
                for (let i = 0; i < devices.length; i++) {
                    online = online + devices[i].online;
                }
                return {total: devices.length, online: online};
            } else {
                return {total: 0, online: 0};
            }
        }
    }

    const totSites = (numSites: number) => {
        return (
            <Card>
                <CardContent sx={(width < 1024) ? {p: 2, '&:last-child': {pb: 1}} : {}}>
                    <div className={classes.cardTitle}>
                        <Typography variant="h5" gutterBottom>
                            Total Sites
                        </Typography>
                        <ConnectingAirportsIcon fontSize={(width < 1024) ? "small" : "large"} color="primary" />
                    </div>
                    <Typography variant="h4" color="primary" component="div">
                        <AnimationOnTextChange value={numSites.toString()}>
                            {numSites}
                        </AnimationOnTextChange>
                    </Typography>
                </CardContent>
            </Card>
        );
    }
    const totDevices = ({total, online} : TotDevProps) => {
        return (
            <Card>
                <CardContent sx={(width < 1024) ? {p: 2, '&:last-child': {pb: 1}} : {}}>
                    <div className={classes.cardTitle}>
                        <Typography variant="h5" gutterBottom>
                            Total Devices
                        </Typography>
                        <DevicesIcon fontSize={(width < 1024) ? "small" : "large"} color="primary"/>
                    </div>
                    <Typography variant="h4" color="primary" component="div">
                        <AnimationOnTextChange value={total + '(' + online + ')'}>
                            {total + '(' + online + ')'}
                        </AnimationOnTextChange>
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    const totExplosives = (numExplosives: number, percent: number) => {
        return (
            <Card>
                <CardContent sx={(width < 1024) ? {p: 2, '&:last-child': {pb: 1}} : {}}>
                    <Typography variant="h5" gutterBottom>
                        Total Explosives
                    </Typography>

                    <Typography variant="h4" color="primary" component="div">
                        <AnimationOnTextChange value={numExplosives + '(' + Math.round(percent) + '%)'}>
                            {numExplosives + '(' + Math.round(percent) + '%)'}
                        </AnimationOnTextChange>
                    </Typography>
                </CardContent>
                {/*<CardActions>*/}
                {/*    <Button size="small">See Details</Button>*/}
                {/*</CardActions>*/}
            </Card>
        );
    }

    const totNarcotics = (numNarcotics: number, percent: number) => {
        return (
            <Card>
                <CardContent sx={(width < 1024) ? {p: 2, '&:last-child': {pb: 1}} : {}}>
                    <Typography variant="h5" gutterBottom>
                        Total Narcotics
                    </Typography>
                    <Typography variant="h4" color="primary" component="div">
                        <AnimationOnTextChange value={numNarcotics + '(' + Math.round(percent) + '%)'}>
                            {numNarcotics + '(' + Math.round(percent) + '%)'}
                        </AnimationOnTextChange>
                    </Typography>
                </CardContent>
                {/*<CardActions>*/}
                {/*    <Button size="small">See Details</Button>*/}
                {/*</CardActions>*/}
            </Card>
        );
    }

    const totClear = (numClear: number, percent: number) => {
        return (
            <Card>
                <CardContent sx={(width < 1024) ? {p: 2, '&:last-child': {pb: 1}} : {}}>
                    <Typography variant="h5" gutterBottom>
                        Total Clear
                    </Typography>
                    <Typography variant="h4" color="primary" component="div">
                        <AnimationOnTextChange value={numClear + '(' + Math.round(percent) + '%)'}>
                            {numClear + '(' + Math.round(percent) + '%)'}
                        </AnimationOnTextChange>
                    </Typography>
                </CardContent>
                {/*<CardActions>*/}
                {/*    <Button size="small">See Details</Button>*/}
                {/*</CardActions>*/}
            </Card>
        );
    }

    const totTests = (numTests: number) => {
        return (
            <Card>
                <CardContent sx={(width < 1024) ? {p: 2, '&:last-child': {pb: 1}} : {}}>
                    <Typography variant="h5" gutterBottom>
                        Total Tests
                    </Typography>
                    <Typography variant="h4" color="primary" component="div">
                        <AnimationOnTextChange value={numTests.toString()}>
                            {numTests}
                        </AnimationOnTextChange>
                    </Typography>
                </CardContent>
                {/*<CardActions>*/}
                {/*    <Button size="small">See Details</Button>*/}
                {/*</CardActions>*/}
            </Card>
        );
    }

    const openDevice = (dtu_id: string, serial_number: string) => {
        titleUpdateHandler(serial_number);

        updateETDName(serial_number);

        navigate("/device/" + dtu_id);
    };

    const openSite = (site_id: number) => {
        //console.log(site_id);
        siteClickHandler(site_id);
    };


    return(
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1 }} >
                <Grid container spacing={contSpacing}>
                    <Grid item xs={8}>
                        <Paper elevation={3}>
                            <Item1>
                                <GoogleMap devices={devices} height={(width < 1024) ? height / 2.3 : height / 2}/>
                            </Item1>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={contSpacing}>
                            <Grid item xs={6}>
                                <Item0>{totSites(getNumSites(/*devices*/))}</Item0>
                            </Grid>
                            <Grid item xs={6}>
                                <Item0>{totDevices(getNumDevices(/*devices*/))}</Item0>
                            </Grid>
                            <Grid item xs={6}>
                                <Item0>{totTests(testTotal)}</Item0>
                            </Grid>
                            <Grid item xs={6}>
                                <Item0>{totClear(clearTotal, (testTotal > 0) ? (clearTotal / testTotal * 100) : 0)}</Item0>
                            </Grid>
                            <Grid item xs={6}>
                                <Item0>{totExplosives(explTotal, (testTotal > 0) ? (explTotal / testTotal * 100) : 0)}</Item0>
                            </Grid>
                            <Grid item xs={6}>
                                <Item0>{totNarcotics(narcTotal, (testTotal > 0) ? (narcTotal / testTotal * 100) : 0)}</Item0>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        {viewBySite ?
                            <Item0>
                                <DevicesTable devices={devices} handleClick={openDevice}/>
                            </Item0>
                            :
                            <Item0>
                                <SitesTable sites={sites} handleClick={openSite}/>
                            </Item0>
                        }
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 5,
        marginRight: 5,
    },
    cardTitle: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
}));