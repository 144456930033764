import React from "react";

import {makeStyles} from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DevicesIcon from '@mui/icons-material/Devices';

import {useNavigate} from "react-router-dom";
import {StaticMenu} from "./StaticMenu";



export type AdminMenuProps = {
    width: number,
    menuOpen: boolean,
    updateItemID: (item_id: number) => void,
};



const menuItems = [
    // {
    //     item_id: 0,
    //     title: "Dashboard",
    //     icon: <DashboardIcon/>,
    //     selectedIcon: <DashboardIcon color="primary"/>,
    //     selected: true,
    // },
    {
        item_id: 1,
        title: "Users",
        icon: <PersonIcon/>,
        selectedIcon: <PersonIcon color="primary"/>,
        selected: true,
    },
    {
        item_id: 2,
        title: "Clients",
        icon: <GroupIcon/>,
        selectedIcon: <GroupIcon color="primary"/>,
        selected: false,
    },
    {
        item_id: 3,
        title: "Sites",
        icon: <LocationOnIcon/>,
        selectedIcon: <LocationOnIcon color="primary"/>,
        selected: false,
    },
    {
        item_id: 4,
        title: "Devices",
        icon: <DevicesIcon/>,
        selectedIcon: <DevicesIcon color="primary"/>,
        selected: false,
    },
];

export const AdminMenu = ({width, menuOpen, updateItemID} : AdminMenuProps) : JSX.Element => {

    return (
        <StaticMenu
            width={width}
            menuOpen={menuOpen}
            updateItemID={updateItemID}
            menuItems={menuItems}
        />
    );
}

