import axios from "axios";
import {host} from "./api";
import React from "react";
import {getAllSites} from "./DevicesApi";
import {EditUserInfo, Response} from "./AdminApi";

export interface deviceGenInfo {
    etd_id: string,
    SerialNumber: string,
    OperatorID: string,
    latitude: number,
    longitude: number,
    CurrentModeId: string,
    ExplosiveAlgorithmVersion: string,
    NarcoticAlgorithmVersion: string,
    SoftwareVersion: string,
    FirmwareVersion: string,
    TelitModemVersion: string,
    InternetIP: string,
    online: number,
    Status: number,
    UpTime: number,
}

export interface deviceShort {
    etd_id: string,
    SerialNumber: string,
    site_name: string,
    client_name: string,
    description: string,
    site_id: number,
    client_id: number,
}

export interface healthChecksInfo {
    date_time: string,
    cntr: number,
}

export interface resultsSummary {
    ClearResults: number,
    EVKResults: number,
    ExplosivesResults: number,
    NVKResults: number,
    NarcoticsResults: number,
    SampleCount: number,
    TimeCounter: number,
}

export interface ScanResult {
    DateTime: number,
    ScanNumber: number,
    Status: string,
    MaterialDetected: string,
    Type: string,
    resdata: string,
}

export interface dynamicInfo {
    BatteryPercentage: number,
    NumOfSensorTests: number,
    Sensor1: number,
    Sensor2: number,
    Sensor3: number,
    Sensor4: number,
    Sensor5: number,
    Sensor6: number,
    Sensor7: number,
    Sensor8: number,
    T1Temperature: number,
    T2Temperature: number,
    TimeUsageOfSensor: number,
    TodaySwabNum: number,
}

export interface uptimeListRec {
    state: number,
    start_time: number,
    duration: number,
}

export interface uptimeStatRec {
    state: number,
    duration: number,
}

export interface DeviceServSettings {
    etd_id: number,
    show_graph: number,
}

export const getDevShort = async (etd_id: number): Promise<deviceShort | undefined> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.get(`${host}/etd/etd_short/` + etd_id);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getDevShort() error: ' + e);
        return undefined;
    }
};

// export const useDevShort = (etd_id : number | undefined) => {
//     const [etd, setEtd] = React.useState(<deviceShort | undefined> undefined);
//
//     React.useEffect(() => {
//         if(etd_id !== undefined) {
//             getDevShort(etd_id)
//                 .then((respEtd) => {
//                     console.log(respEtd);
//                     setEtd(respEtd);
//                 })
//         }
//     }, [etd_id]);
//
//     return etd ? etd : undefined;
// };

export const getDevGenInfo = async (etd_id: number): Promise<deviceGenInfo | undefined> => {
    //console.log('getDevsBriefBySites()');
    try {
        const response = await axios.get(`${host}/etd/etd_gen_info/` + etd_id);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getDevGenInfo() error: ' + e);
        return undefined;
    }
};

export const getHealthCheckInfo = async (etd_id: number): Promise<healthChecksInfo[] | undefined> => {
    //console.log('getHealthCheckInfo()');
    try {
        const response = await axios.get(`${host}/etd/etd_health_info/` + etd_id);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getHealthCheckInfo() error: ' + e);
        return undefined;
    }
};

export const getResultsSummary = async (etd_id: number): Promise<resultsSummary | undefined> => {
    //console.log('getResultsSummary()');
    try {
        const response = await axios.get(`${host}/etd/etd_result_sum/` + etd_id);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getResultsSummary() error: ' + e);
        return undefined;
    }
};

export const getScanResult = async (etd_id: number): Promise<ScanResult[] | undefined> => {
    //console.log('getScanResult()');
    try {
        const response = await axios.get(`${host}/etd/etd_scan_result/` + etd_id);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getScanResult() error: ' + e);
        return undefined;
    }
};


export const getDynInfo = async (etd_id: number): Promise<dynamicInfo | undefined> => {
    //console.log('getDynInfo()');
    try {
        const response = await axios.get(`${host}/etd/etd_dyn_info/` + etd_id);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getDynInfo() error: ' + e);
        return undefined;
    }
};

export const getUptimeList = async (etd_id: number, time_from: number, time_to: number): Promise<uptimeListRec[] | undefined> => {
    //console.log('getUptimeList()');
    try {
        const response = await axios.get(`${host}/etd/etd_uptime_list/` + etd_id + '/period/' + time_from + '-' + time_to);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getUptimeList() error: ' + e);
        return undefined;
    }
};

export const getUptimeStat = async (etd_id: number, time_from: number, time_to: number): Promise<uptimeStatRec[] | undefined> => {
    //console.log('getUptimeStat()');
    try {
        const response = await axios.get(`${host}/etd/etd_uptime_stat/` + etd_id + '/period/' + time_from + '-' + time_to);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getUptimeStat() error: ' + e);
        return undefined;
    }
};


export const useDevGenInfoLive = (etd_id: number, interval: number): deviceGenInfo | undefined => {
    const [etdGenInfo, setEtdGenInfo] = React.useState<deviceGenInfo | undefined>(undefined);

    React.useEffect(() => {

        let shouldUpdate = true;

        function fetchEtd() {
            getDevGenInfo(etd_id)
                .then((respEtd) => {
                    if (shouldUpdate) {
                        setEtdGenInfo(respEtd);
                        //console.log(respSites);
                        setTimeout(fetchEtd, interval);
                    }
                })
                .catch(() => {
                    setTimeout(fetchEtd, interval);
                });
        }

        fetchEtd();

        return () => {
            shouldUpdate = false;
        };
    }, [etd_id, interval]);

    return etdGenInfo ? etdGenInfo : undefined;
};

export const useHealthCheckInfoLive = (etd_id: number, interval: number): healthChecksInfo[] | undefined => {

    const [etdHealthChecksInfo, setEtdHealthChecksInfo] = React.useState<healthChecksInfo[] | undefined>(undefined);

    React.useEffect(() => {

        let shouldUpdate = true;

        function fetchEtdHealthCheckInfo() {
            getHealthCheckInfo(etd_id)
                .then((respEtdHealthChecksInfo) => {
                    if (shouldUpdate) {
                        setEtdHealthChecksInfo(respEtdHealthChecksInfo);
                        //console.log(respSites);
                        setTimeout(fetchEtdHealthCheckInfo, interval);
                    }
                })
                .catch(() => {
                    setTimeout(fetchEtdHealthCheckInfo, interval);
                });
        }

        fetchEtdHealthCheckInfo();

        return () => {
            shouldUpdate = false;
        };
    }, [etd_id, interval]);

    return etdHealthChecksInfo ? etdHealthChecksInfo : undefined;
};

export const useResultsSummaryLive = (etd_id: number, interval: number): resultsSummary | undefined => {

    const [etdResultsSummary, setEtdResultsSummary] = React.useState<resultsSummary | undefined>(undefined);

    React.useEffect(() => {

        let shouldUpdate = true;

        function fetchResultsSummary() {
            getResultsSummary(etd_id)
                .then((respEtdResultsSummary) => {
                    if (shouldUpdate) {
                        setEtdResultsSummary(respEtdResultsSummary);
                        //console.log(respSites);
                        setTimeout(fetchResultsSummary, interval);
                    }
                })
                .catch(() => {
                    setTimeout(fetchResultsSummary, interval);
                });
        }

        fetchResultsSummary();

        return () => {
            shouldUpdate = false;
        };
    }, [etd_id, interval]);

    return etdResultsSummary ? etdResultsSummary : undefined;
};

export const useScanResultsLive = (etd_id: number, interval: number): ScanResult[] | undefined => {

    const [etdScanResults, setEtdScanResults] = React.useState<ScanResult[] | undefined>(undefined);

    React.useEffect(() => {

        let shouldUpdate = true;

        function fetchScanResults() {
            getScanResult(etd_id)
                .then((respEtdScanResults) => {
                    if (shouldUpdate) {
                        setEtdScanResults(respEtdScanResults);
                        //console.log(respSites);
                        setTimeout(fetchScanResults, interval);
                    }
                })
                .catch(() => {
                    setTimeout(fetchScanResults, interval);
                });
        }

        fetchScanResults();

        return () => {
            shouldUpdate = false;
        };
    }, [etd_id, interval]);

    return etdScanResults ? etdScanResults : undefined;
};




export const useDynInfoLive = (etd_id: number, interval: number): dynamicInfo | undefined => {

    const [etdDynInfo, setEtdDynInfo] = React.useState<dynamicInfo | undefined>(undefined);

    React.useEffect(() => {

        let shouldUpdate = true;

        function fetchDynInfo() {
            getDynInfo(etd_id)
                .then((respEtdDynInfo) => {
                    if (shouldUpdate) {
                        setEtdDynInfo(respEtdDynInfo);
                        //console.log(respSites);
                        setTimeout(fetchDynInfo, interval);
                    }
                })
                .catch(() => {
                    setTimeout(fetchDynInfo, interval);
                });
        }

        fetchDynInfo();

        return () => {
            shouldUpdate = false;
        };
    }, [etd_id, interval]);

    return etdDynInfo ? etdDynInfo : undefined;
};

export const useUptimeListLive = (etd_id: number, period: number, interval: number): uptimeListRec[] | undefined => {

    const [etdUptimeList, setEtdUptimeList] = React.useState<uptimeListRec[] | undefined>(undefined);

    React.useEffect(() => {

        let shouldUpdate = true;

        const time_end = Date.now();

        const time_start = time_end - period * 1000;

        function fetchUptimeList() {
            getUptimeList(etd_id, time_start, time_end)
                .then((UptimeList) => {
                    if (shouldUpdate) {
                        setEtdUptimeList(UptimeList);
                        //console.log(respSites);
                        setTimeout(fetchUptimeList, interval);
                    }
                })
                .catch(() => {
                    setTimeout(fetchUptimeList, interval);
                });
        }

        fetchUptimeList();

        return () => {
            shouldUpdate = false;
        };
    }, [etd_id, period, interval]);

    return etdUptimeList ? etdUptimeList : undefined;
};

export const useUptimeStatLive = (etd_id: number, period: number, interval: number): uptimeStatRec[] | undefined => {

    const [etdUptimeStat, setEtdUptimeStat] = React.useState<uptimeStatRec[] | undefined>(undefined);

    React.useEffect(() => {

        let shouldUpdate = true;

        const time_end = Date.now();

        const time_start = time_end - period * 1000;

        function fetchUptimeStat() {
            getUptimeStat(etd_id, time_start, time_end)
                .then((UptimeStat) => {
                    if (shouldUpdate) {
                        setEtdUptimeStat(UptimeStat);
                        //console.log(respSites);
                        setTimeout(fetchUptimeStat, interval);
                    }
                })
                .catch(() => {
                    setTimeout(fetchUptimeStat, interval);
                });
        }

        fetchUptimeStat();

        return () => {
            shouldUpdate = false;
        };
    }, [etd_id, period, interval]);

    return etdUptimeStat ? etdUptimeStat : undefined;
};

export const getDevServSettings = async (etd_id: number): Promise<DeviceServSettings | undefined> => {
    //console.log('getDevServSettings()');
    try {
        const response = await axios.get(`${host}/etd/get_etd_serv_settings/` + etd_id);
        //console.log(response.data);
        return response.data;
    } catch (e) {
        console.log('getDevServSettings() error: ' + e);
        return undefined;
    }
};

export const setDevServSettings = async (devSettings: DeviceServSettings): Promise<Response> => {
    //console.log('setDevServSettings()');
    try {
        const response = await axios.post(`${host}/etd/set_etd_serv_settings`, devSettings);
        //console.log(response.status);
        if(response.status === 200){
            return { success: true, error: "", code: response.status};
        }else{
            console.log('setDevServSettings(): error: ' + JSON.stringify(response.data));
            return { success: false, error: response.data.error, code: response.status};
        }
    } catch (error: any) {
        console.log('setDevServSettings(): catch: error: ' + error);
        if (error.response) {

            if(error.response.data && error.response.data.error && error.response.data.error.sqlMessage){
                return {success: false, error: error.response.data.error.sqlMessage, code: error.response.status};
            }else {
                return {success: false, error: error.response.data.message, code: error.response.status};
            }
        }
        return { success: false, error: "Server Connection error", code: 401};
    }
};