import {Wrapper, Status} from "@googlemaps/react-wrapper";
import {Loading} from "./Loading";
import React from "react";
import {makeStyles} from "@mui/styles";
import {createCustomEqual} from "fast-equals";
import {isLatLngLiteral} from "@googlemaps/typescript-guards";
import {deviceBrief} from "api/DevicesApi";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import GroupIcon from "@mui/icons-material/Group";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useWindowDimensions from "../hooks/useWindowDimensions";
//import LatLng = google.maps.LatLng;

const apyKey = "AIzaSyD3OtNtL_i7BQob0xhgtHH7UXnFF5COXNk";

//const apyKey = "";

//const google = window.google;

///*global google*/

export type GoogleMapProps = {
    devices: deviceBrief[] | null,
    height: number | string,
};

export type GoogleMapOneDevProps = {
    position: {latitude: number, longitude: number},
    height: number | string,
};

export type MarkerProps = {
    position: google.maps.LatLng,
    online: number,
    etd_id: string,
    description: string,
};

const render = (status: Status) => {
    console.log("render: status: " + status);
    if (status === Status.LOADING) return <Loading/>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return <Loading/>;
};

// const mapStyles =[
//     {
//         featureType: 'poi',
//         elementType: 'labels',
//         stylers: [
//             { visibility: 'off' }
//         ]
//     }
// ];

//const Map: React.FC<{}> = () => {};

interface MapProps extends google.maps.MapOptions {
    center: google.maps.LatLngLiteral;
    zoom: number;
    bounds: google.maps.LatLngBounds | null;
    style: { [key: string]: string };
    children?: React.ReactNode;
    height: number | string;
}

const Map = ({center, zoom, bounds, children, height, style, ...options}: MapProps) => {

    //const classes = useStyles();

    //let {height} = useWindowDimensions();

    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {center: center, zoom: zoom, styles: [{
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [
                        { visibility: 'off' }
                    ]
                }]}));
        }
        if(map && bounds && !bounds.isEmpty()){
            map.fitBounds(bounds);
            const currZoom = map.getZoom();
            //console.log(currZoom);
            if(currZoom > zoom){
                map.setZoom(zoom);
            }
            // The marker, positioned at Uluru
            // const marker = new google.maps.Marker({
            //     position: { lat: 32.16, lng: 34.80 },
            //     map: map,
            // });
        }
    }, [center, zoom, bounds, ref, map]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    // useDeepCompareEffectForMaps(() => {
    //     if (map) {
    //         map.setOptions(options);
    //     }
    // }, [map, options]);

    return (
        <>
            <div ref={ref} id="map" style={{height: height, width: '100%'}}/>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, {map});
                }
            })}
        </>
    );
};

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};

export const GoogleMap = ({devices, height}: GoogleMapProps): JSX.Element => {

    //console.log(devices);

    //const markers : MarkerProps[] | null = fillMarkers(devices);

    //const markers : MarkerProps[] | null = [];

    const [markers, setMarkers] = React.useState<MarkerProps[] | null>(null);

    const center = {lat: 32.16314630578828, lng: 34.808509889456495};
    const zoom = 15;

    React.useEffect(() => {
        if(window.google) {
            setMarkers(fillMarkers(devices));
        }
    }, [devices]);


    const bounds = (window.google && window.google.maps) ? new window.google.maps.LatLngBounds() : null;

    if(markers && bounds){
        for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].position);
        }
    }

    //console.log(bounds);

    return (
        <Wrapper apiKey={apyKey} render={render}>
            <Map zoom={zoom} center={center} style={{flexGrow: "1"}} bounds={bounds} height={height} >
                {makeMarkers(markers)}
            </Map>
        </Wrapper>
    );

};

export const GoogleMapOneDev = ({position, height}: GoogleMapOneDevProps): JSX.Element => {

    //console.log(devices);

    //const markers : MarkerProps[] | null = fillMarkers(devices);

    //const markers : MarkerProps[] | null = [];

    const [markers, setMarkers] = React.useState<MarkerProps[] | null>(null);

    const center = {lat: position.latitude, lng: position.longitude};
    const zoom = 15;

    const bounds = (window.google && window.google.maps) ? new window.google.maps.LatLngBounds() : null;

    if(markers && bounds){
        for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].position);
        }
    }

    //console.log(bounds);

    return (
        <Wrapper apiKey={apyKey} render={render}>
            <Map zoom={zoom} center={center} style={{flexGrow: "1"}} bounds={bounds} height={height}>
                <Marker position={new google.maps.LatLng(position.latitude, position.longitude)} title={''}/>
            </Map>
        </Wrapper>
    );

};

const makeMarkers = (markers: MarkerProps[] | null) : JSX.Element[] | null => {
    //console.log(markers);
    return markers && markers.map((marker, index) => {
        return (<Marker key={index} position={marker.position} title={marker.etd_id + '\n' + marker.description}/>)
    });
}

const fillMarkers = (devices: deviceBrief[] | null) : MarkerProps[] | null => {
    //console.log(devices);
    return devices && devices.map((device) => {
        return ({
            position: new google.maps.LatLng(device.latitude, device.longitude),
            online: device.online,
            etd_id: device.serial_number,
            description: device.description,
        })
    });
}

const getIcon = (online: number) => {

}


// const deepCompareEqualsForMaps = createCustomEqual(
//     (deepEqual) => (a: any, b: any) => {
//         if (
//             isLatLngLiteral(a) ||
//             a instanceof google.maps.LatLng ||
//             isLatLngLiteral(b) ||
//             b instanceof google.maps.LatLng
//         ) {
//             return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
//         }
//
//         // TODO extend to other types
//
//         // use fast-equals for other objects
//         return deepEqual(a, b);
//     }
// );
//
// function useDeepCompareMemoize(value: any) {
//     const ref = React.useRef();
//
//     if (!deepCompareEqualsForMaps(value, ref.current)) {
//         ref.current = value;
//     }
//
//     return ref.current;
// }
//
// function useDeepCompareEffectForMaps(
//     callback: React.EffectCallback,
//     dependencies: any[]
// ) {
//     React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
// }

// const useStyles = makeStyles(theme => ({
//     map: {
//         height: "600px",
//     },
// }));