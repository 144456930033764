import React, {useContext, useEffect} from 'react'
import {makeStyles} from "@mui/styles";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMatch} from "react-router-dom";
import {styled, useTheme, createTheme, ThemeProvider, keyframes} from "@mui/material/styles";
import {getDevShort} from "../api/EtdApi";
import {getScanHist, ScanHist} from "../api/MQTTApi";
import {Box, CircularProgress, FormGroup, TextField} from "@mui/material";
import moment from "moment";
import {DateTimeRange, DateTimeRangePicker} from "../components/DateTimeRangePicker";
import dayjs, {Dayjs} from "dayjs";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {ETDContext} from "../context/ETDContext";


//const ALARMS_COUNT_UPDATE_INTERVAL: number = 30000;      // 30 sec

//const ALARMS_UPDATE_INTERVAL: number = 5000;      // 5 sec


export type DeviceStatisticsProps = {
    titleUpdateHandler: (title: string) => void;
};

type ResultRowData = {
    time: number;
    evk: string;
    group: string;
    material: string;
    profile: string;
    sampCount: number;
    user: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 10,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
    [`&.${tableCellClasses.body}`]: {
        //fontSize: 14,
        fontSize: 9,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const DeviceStatistics = ({titleUpdateHandler} : DeviceStatisticsProps) : JSX.Element => {

    const classes = useStyles();

    let theme = useTheme();

    theme = createTheme(theme,{
        typography: {
            h5: {
                fontSize: 18,
                color: theme.palette.primary.main,
                marginBottom: 10,
            },
            h6: {
                fontSize: 16,
                color: '#222',
            },
            body1: {
                fontSize: 16,
            },
            body2: {
                fontSize: 16,
                marginBottom: 15,
            },

        },
    });

    const pageMatch = useMatch("/device/:etd_id");
    const etd_id_str = (pageMatch && pageMatch.params.etd_id) || "";

    const etd_id = Number(etd_id_str);

    const start = dayjs().startOf("day").subtract(7, 'day');
    const end = dayjs().endOf("day");
    const [range, setRange] = React.useState <DateTimeRange> ({from: start, to: end});

    const [results, setResults] = React.useState <ResultRowData[] | undefined> (undefined);

    const [waitingForGetStatistics, setWaitingForGetStatistics] = React.useState(false);

    const {etdName} = useContext(ETDContext);

    React.useEffect(() => {
        titleUpdateHandler(etdName);
    },[etdName, titleUpdateHandler]);

    const rangeChangeHandler = (newRange: DateTimeRange) => {
        setRange(newRange);
    };

    const onGetResults = () => {

        if (etd_id !== undefined) {
            setWaitingForGetStatistics(true);
            //getScanHist(etd_id, Math.round(range.from.toDate().getTime() / 1000), Math.round(range.to.toDate().getTime() / 1000))
            getScanHist(etd_id, range.from.toDate().getTime(), range.to.toDate().getTime() )
                .then((respHist) => {
                    setWaitingForGetStatistics(false);
                    if (respHist !== undefined) {

                        const resList: ResultRowData[] = [];

                        respHist.EVKAlarm.forEach((item) => {
                            resList.push({
                                time: item.Date,
                                evk: 'EVK',
                                group: item["Material Group"],
                                material: item.Material,
                                profile: item.ProfileName,
                                sampCount: item.ScanNumber,
                                user: item.UserName,
                            });
                        });
                        respHist.EVKClear.forEach((item) => {
                            resList.push({
                                time: item.Date,
                                evk: 'EVK',
                                group: item["Material Group"],
                                material: item.Material,
                                profile: item.ProfileName,
                                sampCount: item.ScanNumber,
                                user: item.UserName,
                            });
                        });
                        respHist.ExplosivesAlarm.forEach((item) => {
                            resList.push({
                                time: item.Date,
                                evk: '',
                                group: item["Material Group"],
                                material: item.Material,
                                profile: item.ProfileName,
                                sampCount: item.ScanNumber,
                                user: item.UserName,
                            });
                        });
                        respHist.ExplosivesClear.forEach((item) => {
                            resList.push({
                                time: item.Date,
                                evk: '',
                                group: item["Material Group"],
                                material: item.Material,
                                profile: item.ProfileName,
                                sampCount: item.ScanNumber,
                                user: item.UserName,
                            });
                        });

                        respHist.NVKAlarm.forEach((item) => {
                            resList.push({
                                time: item.Date,
                                evk: 'NVK',
                                group: item["Material Group"],
                                material: item.Material,
                                profile: item.ProfileName,
                                sampCount: item.ScanNumber,
                                user: item.UserName,
                            });
                        });
                        respHist.NVKClear.forEach((item) => {
                            resList.push({
                                time: item.Date,
                                evk: 'NVK',
                                group: item["Material Group"],
                                material: item.Material,
                                profile: item.ProfileName,
                                sampCount: item.ScanNumber,
                                user: item.UserName,
                            });
                        });
                        respHist.NarcoticsAlarm.forEach((item) => {
                            resList.push({
                                time: item.Date,
                                evk: '',
                                group: item["Material Group"],
                                material: item.Material,
                                profile: item.ProfileName,
                                sampCount: item.ScanNumber,
                                user: item.UserName,
                            });
                        });
                        respHist.NarcoticsClear.forEach((item) => {
                            resList.push({
                                time: item.Date,
                                evk: '',
                                group: item["Material Group"],
                                material: item.Material,
                                profile: item.ProfileName,
                                sampCount: item.ScanNumber,
                                user: item.UserName,
                            });
                        });

                        //console.log(resList);
                        resList.sort((a, b) => a.time - b.time);
                        //console.log(resList);

                        setResults(resList);
                    }
                })
                .catch((err) => {
                    setWaitingForGetStatistics(false);
                    console.log(err);
                });
        }

    };

    const dateTimeFormat = (timestamp: number): string => {
        const date = new Date(timestamp);
        return date.toLocaleString("en-GB");
    }

    return(
        <ThemeProvider theme={theme}>
            <FormGroup sx={{ width: 1000, border: '1px solid', borderColor: theme.palette.primary.main, padding: '20px', margin: '10px', borderRadius: 2 }}>
                <div className={classes.controlGroup}>
                    <DateTimeRangePicker
                        defaultRange={range}
                        onChange={rangeChangeHandler}
                        minDate={undefined}
                        maxDate={undefined}
                        disabled={false}/>

                    <div className={classes.getResultsBtn}>
                        <Button variant="outlined" size="large" onClick={onGetResults}>Get Statistics</Button>
                    </div>
                </div>

                <div className={classes.table}>
                    {waitingForGetStatistics ?
                        <>
                            {/*<Typography variant="h5" component="div" className={classes.dev_field_title2}>*/}
                            {/*    Waiting for getting statistics history*/}
                            {/*</Typography>*/}
                            <Box sx={{display: 'flex', justifyContent: "center", marginTop: "10px"}}>
                                <CircularProgress/>
                            </Box>
                        </>
                        :
                        <TableContainer component={Paper}>
                            {results ?
                                <Table sx={{minWidth: 650}} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Time</StyledTableCell>
                                            <StyledTableCell align="left">EVK / NVK</StyledTableCell>
                                            <StyledTableCell align="left">Group</StyledTableCell>
                                            <StyledTableCell align="left">Material</StyledTableCell>
                                            <StyledTableCell align="left">Profile</StyledTableCell>
                                            <StyledTableCell align="left">Sample Count</StyledTableCell>
                                            <StyledTableCell align="left">User</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {results && results.map((item, index) => {
                                            if (item) {
                                                return (
                                                    <StyledTableRow key={index} className={classes.tableRow}>
                                                        <StyledTableCell align="left" component="th" scope="row">
                                                            {dateTimeFormat(item.time * 1000)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{item.evk}</StyledTableCell>
                                                        <StyledTableCell align="left">{item.group}</StyledTableCell>
                                                        <StyledTableCell align="left">{item.material}</StyledTableCell>
                                                        <StyledTableCell align="left">{item.profile}</StyledTableCell>
                                                        <StyledTableCell align="left">{item.sampCount}</StyledTableCell>
                                                        <StyledTableCell align="left">{item.user}</StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                                :
                                <></>
                            }
                        </TableContainer>
                    }
                </div>
            </FormGroup>
        </ThemeProvider>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 5,
        marginRight: 5,
    },
    controls: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    controlGroup: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
    },
    getResultsBtn: {
        marginLeft: 20,
    },
    table: {
        marginTop: 20,
    },
    tableBody: {
        height: '200px',
        overflowX: 'auto',
    },
    tableRow: {

    },

}));

