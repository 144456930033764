import React from 'react';

import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog, DialogActions,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";

import {makeStyles} from "@mui/styles";

import {FullscreenExitRounded, FullscreenRounded} from "@mui/icons-material";
import {ChartType, TooltipItem, TooltipModel} from "chart.js";


export type CardChartProps = {
    title :string,
    materialDetected: string,
    children: React.ReactNode,
};

export const CardChart = ({title, materialDetected, children} : CardChartProps) : JSX.Element => {

    const classes = useStyles();

    const [fullscreen, setFullscreen] = React.useState(false);

    return (
        <>
            {/*<Card variant="elevation" elevation={8} style={{height: '100%'}}>*/}
            {/*    <div className={classes.controls}>*/}
            { (title === "Clear") ?
                <CardHeader title={title} className={classes.cardHeader}/>
                :
                <CardHeader title={title + " " + materialDetected} className={classes.cardHeaderRed}/>
            }
                    {/*<CardActions className={classes.cardActions}>*/}
                    {/*    <IconButton*/}
                    {/*        color="primary"*/}
                    {/*        onClick={() => {*/}
                    {/*            setFullscreen(true);*/}
                    {/*        }}>*/}
                    {/*        <FullscreenRounded/>*/}
                    {/*    </IconButton>*/}
                    {/*</CardActions>*/}
                {/*</div>*/}

                <CardContent className={classes.cardContent}>
                    <div className={classes.chartContainer}>
                        {children}
                    </div>
                </CardContent>

            {/*</Card>*/}
            {/*<Dialog*/}
            {/*    open={fullscreen}*/}
            {/*    fullScreen={true}>*/}
            {/*    <div className={classes.dialogRoot}>*/}
            {/*        <div className={classes.controls}>*/}
            {/*            <DialogTitle className={classes.cardHeader}>*/}
            {/*                <Typography variant="h3">*/}
            {/*                    {title}*/}
            {/*                </Typography>*/}
            {/*                <Typography variant="subtitle1">*/}
            {/*                    {materialDetected}*/}
            {/*                </Typography>*/}
            {/*            </DialogTitle>*/}
            {/*            <DialogActions className={classes.cardActions}>*/}
            {/*                <IconButton*/}
            {/*                    color="primary"*/}
            {/*                    onClick={() => {*/}
            {/*                        setFullscreen(false);*/}
            {/*                    }}>*/}
            {/*                    <FullscreenExitRounded/>*/}
            {/*                </IconButton>*/}
            {/*            </DialogActions>*/}
            {/*        </div>*/}
            {/*        <div className={classes.dialogChart}>*/}
            {/*            {children}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Dialog>*/}
        </>
    );
};

export const CHART_COLORS = {
    warning: "rgba(230, 220, 10, 0.9)",
    error: "rgba(255, 160, 30, 0.8)",
    critical: "rgba(255, 30, 30, 0.9)",
    red: "rgba(255, 99, 132, 0.9)",
    orange: "rgba(255, 159, 64, 0.8)",
    yellow: "rgba(255, 205, 86, 0.7)",
    green: "rgba(96,192,75,0.7)",
    blue: "rgba(54, 162, 235, 0.7)",
    purple: "rgba(153, 102, 255, 0.7)",
    grey: "rgba(201, 203, 207, 0.7)",

    f3: "rgba(196, 0, 0, 1)",
    f4: "rgba(0, 196, 0, 1)",
    f5: "rgba(0, 0, 196, 1)",
    f6: "rgba(255, 255, 0, 1)",
    t1: "rgba(220, 140, 4, 1)",
    bg: "rgba(210, 210, 180, 1)",
};

export const createUnitTitleCallback = (name: string, unit: string) => (
    (context: TooltipItem<ChartType>) => {
        if (Array.isArray(context)) {
            context = context[0];
        }

        let title = name + ": " + context.label + " " + unit;

        return title;

    }
);

export const unitLabelCallback = (context: TooltipItem<ChartType>) => {
    let label = context.dataset.label || '';
    //console.log(context);

    // if((label === 'Warnings') || (label === 'Errors') || (label === 'Critical')) {
    //     if(context && context.dataset) {
    //         label = context.dataset.data[context.dataIndex].faultStatus.status;
    //     }
    // }else{
    //     if (label) {
    //         label += ': ';
    //     }
    //
    //     if (context.parsed.y !== null) {
    //         label += context.parsed.y + context.dataset.unit;
    //     }
    // }
    return label;
}

const useStyles = makeStyles(theme => ({
    cardHeader: {
        textAlign: 'center',
        //flexGrow: 999,
        marginTop: -20,
        color: CHART_COLORS.green,
    },
    cardHeaderRed: {
        textAlign: 'center',
        //flexGrow: 999,
        marginTop: -20,
        '& .MuiTypography-root': {
            color: CHART_COLORS.critical,
        },
        fontSize: 25,
    },
    cardContent: {
        width: "100%",
        //padding: "50% 0 0 0",
        position: "relative",
        marginTop: -20,
        height: "95%",
    },
    chartContainer: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: CHART_COLORS.bg,
    },
    // cardActions: {
    //     display: "flex",
    //     flexDirection: "row",
    //     justifyContent: "flex-end",
    //     flexGrow: 1,
    // },

    dialogRoot: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
    },
    dialogChart: {
        flexGrow: 1,
        position: "relative",
    },
    controls: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));