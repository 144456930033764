import React from 'react'
import { styled } from '@mui/material/styles';
import {makeStyles} from "@mui/styles";
import {SiteSummary} from "../api/DevicesApi";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useWindowDimensions from "../hooks/useWindowDimensions";
import Cloud from '@mui/icons-material/Cloud';
import CloudOff from '@mui/icons-material/CloudOff';
import CloudQueue from '@mui/icons-material/CloudQueue';
import {number} from "prop-types";
import {AnimationOnIconChange} from "./AnimationOnIconChange";
import {CloudIcon} from "./CloudIcon";
import {AnimationOnTextChange} from "./AnimationOnTextChange";


export type SitesTableProps = {
    sites: SiteSummary[] | null,
    handleClick: (site_id: number) => void;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 10,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
    [`&.${tableCellClasses.body}`]: {
        //fontSize: 14,
        fontSize: 9,
        '@media (min-width:1024px)': {
            fontSize: 12,
        },
        '@media (min-width:1920px)': {
            fontSize: 14,
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const SitesTable = ({sites, handleClick} : SitesTableProps ) : JSX.Element => {

    const classes = useStyles();

    const {width, height} = useWindowDimensions();

    const totPercentString = (value: number, total: number): string => {
        if(total > 0){
            const percent = Math.round(value / total * 100);
            return value.toString() + '(' + percent.toString() + '%)';

        }
        return '0(0%)';
    }

    return(
        <TableContainer sx={{ maxHeight: height / 2.75 }} component={Paper}>
            <Table sx={{ minWidth: 650 }} size={width < 1024 ? "small" : "medium"} stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">Site Name</StyledTableCell >
                        <StyledTableCell align="center">Total ETDs</StyledTableCell >
                        <StyledTableCell align="center">Active ETDs</StyledTableCell >
                        <StyledTableCell align="center">Total Tests</StyledTableCell >
                        <StyledTableCell align="center">Clear</StyledTableCell >
                        <StyledTableCell align="center">Explosives</StyledTableCell >
                        <StyledTableCell align="center">Narcotics</StyledTableCell >
                    </TableRow>
                </TableHead>
                <TableBody >
                    {sites && sites.map((site) => {
                        if (site) {
                            const totTests: number = Number(site.tot_clear) + Number(site.tot_explosives) + Number(site.tot_narcotics);
                            return (
                                <StyledTableRow key={site.site_id} onClick={()=> handleClick(site.site_id)} className={classes.tableRow}>
                                    <StyledTableCell align="left" component="th" scope="row">
                                        {site.site_name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={site.tot_devs.toString()} >
                                            {site.tot_devs}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={site.tot_online_devs.toString()} >
                                            {site.tot_online_devs}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={totTests.toString()} >
                                            {totTests}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={totPercentString(site.tot_clear, totTests)} >
                                            {totPercentString(site.tot_clear, totTests)}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={totPercentString(site.tot_explosives, totTests)} >
                                            {totPercentString(site.tot_explosives, totTests)}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <AnimationOnTextChange value={totPercentString(site.tot_narcotics, totTests)} >
                                            {totPercentString(site.tot_narcotics, totTests)}
                                        </AnimationOnTextChange>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        }else{
                            return null;
                        }
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles(theme => ({
    tableBody: {
        height: '200px',
        overflowX: 'auto',
    },
    tableRow: {
        cursor: 'pointer',
    },
}));