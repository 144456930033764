import React from 'react'
import {makeStyles} from "@mui/styles";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {getDevShort} from "../api/EtdApi";


export type AdminDashboardProps = {
    titleUpdateHandler: (title: string) => void;
};

const Item = styled(Paper)(({ theme } ) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export const AdminDashboard = ( {titleUpdateHandler}: AdminDashboardProps ) : JSX.Element => {

    const classes = useStyles();

    React.useEffect(() => {
        titleUpdateHandler("Administrator Dashboard");
    }, [titleUpdateHandler]);

    return(
        <Box sx={{ flexGrow: 1 }} >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Paper elevation={3}>
                        <Item>

                        </Item>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={3}>
                        <Item>

                        </Item>
                    </Paper>
                </Grid>

            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 5,
        marginRight: 5,
    },

}));